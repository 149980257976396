import React, { useEffect } from 'react';

import { useIntegrations } from "../integrationsSlice";

import SubscriptionEvent from "./SubscriptionEvent";
import SubscriptionEventConditions from "./SubscriptionEventConditions";
import SubscriptionFieldMap from "./SubscriptionFieldMap";
import SubscriptionQuery from "./SubscriptionQuery";

import '../integrations.scss';

const Subscription = ({ onChange, query = false, subProps = {} }) => {
  const { subscription } = useIntegrations();

  useEffect(() => { // TODO how to prevent initial onChange call?
    onChange && onChange(subscription);
  }, [JSON.stringify(subscription)]);


  return (
    <>
      <div>
        <SubscriptionEvent query={query} {...(subProps.event || {})} />
        <SubscriptionEventConditions query={query} {...(subProps.conditions || {})} />
        <SubscriptionFieldMap query={query} {...(subProps.fieldMap || {})} />
        <SubscriptionQuery query={query} {...(subProps.viewData || {})} />
      </div>
    </>
  );
};

export default Subscription;
