import React, { useState, useEffect } from "react";
import pick from "lodash/pick";
import get from "lodash/get";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import defaults from 'lodash/defaults';
import { humanize } from "../utils/railsNames";

import { Button, Table, Checkbox, Whisper, Input, InputGroup } from "rsuite";
import { Icon } from '@rsuite/icons';
import { MdOutlineClear } from "react-icons/md";
import { MdOutlineSearch } from "react-icons/md";
import { MdMoreVert } from "react-icons/md";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";

import DopeDropdown from "./DopeDropdown";
import DopePill from "./DopePill";
import { Link } from "react-router-dom";
import { formatDate, formatToLocaleDateString, timestampToDateTimeStr } from "../utils/date";
import DopeFavoriteIcon from "./DopeFavoriteIcon";
import IconNameDisplay from "./dopeTable/IconNameDisplay";
import ListPills from "./dopeTable/ListPills";
import DopeButton from "./DopeButton";
import Cents from "./Cents";
import DopeFilterDelegator from "./dopeFilters/DopeFilterDelegator";

const { Column, HeaderCell, Cell } = Table;

const dataKeyConverter = (dataKey, rowData) => { // TODO this is just _.get ... ? should replace with that if so
  let value
  let dataKeyArray = dataKey.split(".")
  if (dataKeyArray.length > 1) {
    value = dataKeyArray.reduce((obj, key) => {
      if (!obj) { return null }
      return obj[key]
    }, rowData);
  } else if (!rowData.hasOwnProperty(dataKey)) {
    value = dataKey
  } else {
    value = rowData[dataKey]
  }
  return value;
}

function findObjectWithMatchingKey(object, keysArray) {
  for (const key of keysArray) {
    if (object.hasOwnProperty(key)) {
      return object[key];
    }
  }
  return null;
}

const columnCreator = (columnConfig, props) => {
  let column = null;
  const { type, key, dataKey, label, navigation, dropDownType, ...rest } = columnConfig;

  const columnId = camelCase(key || dataKey);
  const columnProps = defaults({
    ...pick(rest, ['width', 'minWidth', 'flexGrow', 'sortable', 'fullText', 'style', 'resizable']),
    key: columnId,
  }, {
    flexGrow: rest.width ? undefined : 1,
  });

  const clickHandlerKey = `onClick${upperFirst(columnId)}`;
  const clickHandler = props[clickHandlerKey];

  switch (type) {
    case 'text':
      const formatter = rest.humanize ? humanize : (value) => value;
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{ color: "#292B2E", ...rest.customStyles, display: "flex", alignItems: "center" }} className={rest.className}>
            {rowData => {
              const value = get(rowData, dataKey);
              if (value === null) {
                return "";
              } else if (Array.isArray(value)) {
                return <div style={{ display: "flex", flexDirection: "column" }}>
                  {value.map((item, index) => {
                    return <div key={index} style={{marginBottom: "4px"}}>{formatter(item)}</div>
                  })}
                </div>;
              } else {
                return formatter(value);
              }
            }
          }
          </Cell>
        </Column>
      );
      break
    case 'clickable_text':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{ display: "flex", alignItems: "center", cursor: "pointer", ...rest.customStyles }}>
            {rowData => (
              <div onClick={() => clickHandler(rowData)}>
                {get(rowData, dataKey)}
              </div>
            )}
          </Cell>
        </Column>
      );
      break;
      case 'address_text':
        column = (
          <Column {...columnProps}>
            <HeaderCell>{label}</HeaderCell>
            <Cell dataKey={dataKey}>
              {rowData => {
                let address_1, address_2, city_state_zip;

                const addressableClasses = ['contact', 'campaign', 'list_generation', 'list_generation_setting', 'account']
                const associatedAddressable = findObjectWithMatchingKey(rowData, addressableClasses);

                if (rowData.address_1 || rowData.city_state_zip) {
                  address_1 = rowData.address_1;
                  address_2 = rowData.address_2 || "";
                  city_state_zip = rowData.city_state_zip || `${rowData.city || ''}, ${rowData.state || ''} ${rowData.zip || ''}`;
                } else if (associatedAddressable) {
                  address_1 = associatedAddressable.address_1;
                  address_2 = associatedAddressable.address_2 || "";
                  city_state_zip = associatedAddressable.city_state_zip || `${associatedAddressable.city}, ${associatedAddressable.state} ${associatedAddressable.zip}`;
                } else {
                  address_1 = ""
                  address_2 = "";
                  city_state_zip = "";
                }

                return (
                  <div style={{display: "flex", flexDirection: "column", marginTop: "-4px"}}>
                    <div style={{fontSize: "14px", fontWeight: 400, color: "#292B2E"}}>{address_1} {address_2}</div>
                    <div style={{fontSize: "11px", fontWeight: 400, color: "#8793A6"}}>{city_state_zip}</div>
                  </div>
                )
              }}
          </Cell>
        </Column>
      );
      break;
    case 'stacked_text':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey[0]} style={{...rest.customStyles, display: "flex", alignItems: "center"}}>
            {rowData => {
              const disabled = rest.getDisabled ? rest.getDisabled(rowData) : false;
              return (
                <div style={{display: "flex", flexDirection: "column", marginTop: "-4px"}}>
                  {rest.getUrl ? (
                    <Link to={rest.getUrl(rowData)} style={{fontWeight: 600, color: "#292B2E", ...rest.customStyles}} className={disabled ? 'disabled' : ''}>{dataKeyConverter(dataKey[0], rowData)}</Link>) : (
                    <div style={{fontSize: "14px", fontWeight: 400, color: "#292B2E"}}>{dataKeyConverter(dataKey[0], rowData)}</div>
                  )}
                  <div className="label large blue-grey truncate">{dataKeyConverter(dataKey[1], rowData) ? rest.secondaryText : ''} {dataKeyConverter(dataKey[1], rowData)}</div>
                </div>
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'design_preview':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey[0]} style={{...rest.customStyles, display: "flex", alignItems: "flex-start", padding: 0 }}>
            {rowData => {
              const front = dataKeyConverter(dataKey[0], rowData);
              const back = dataKey[1] ? dataKeyConverter(dataKey[1], rowData) ?? null : null;

              return (
                <div style={{ display: "flex", flexDirection: "row", width: '100%'  }}>
                  {front && <img src={front} alt="Front" height="50px" width={back ? "100%" : "50%"} style={{ marginRight: '4px'  }} />}
                  {back && <img src={back} alt="Back" height="50px" width= "100%"  />}
                </div>
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'text_list':
      column = (
        <Column {...columnProps} fullText>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{}}>
            {rowData => {
              const textList = rowData[dataKey];
              const list = textList.map((text, index) => {
                const isFirst = index === 0;
                const className = isFirst ? 'bold' : 'help-text'
                return (
                  <div key={index} className={className}>{text}</div>
                )
              });

              return (
                <div style={{display: "flex", flexDirection: "column", marginTop: "-4px"}}>
                  {list}
                </div>
              )
            }}
          </Cell>
        </Column>
      );
      break;
    case 'icon_name':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{...rest.customStyles, display: "flex", alignItems: "center"}}>
            {rowData => (
              <IconNameDisplay item={rowData} rest={rest} />
            )}
          </Cell>
        </Column>
      );
      break;
    case 'button':
      column = (
        <Column {...columnProps} style={{ padding: '6px' }}>
          <HeaderCell></HeaderCell>
          <Cell>
            {rowData => (
              <Button appearance="link" onClick={() => clickHandler(rowData)} style={{ fontWeight: 600, color: "#255FDF", fontSize: "14px"}}>
                {label}
              </Button>
            )}
          </Cell>
        </Column>
      );
      break;
    case 'image_url':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell style={{ padding: 0 }}>
            {rowData => {
              const url = dataKeyConverter(dataKey, rowData);
              return url ? (<img src={url} alt={url} height="50px" />) : '---';
            }}
          </Cell>
        </Column>
      );
      break;
    case 'date':
      column = (
        <Column {...columnProps} >
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{...rest.customStyles, fontSize: '14px', fontWeight: 400, display: "flex", alignItems: "center"}}>
            {rowData => {
              let date = dataKeyConverter(dataKey, rowData);
              const dateConverter = rest.dateConverter || formatDate;
              return date ? dateConverter(date) : "---";
            }}
          </Cell>
        </Column>
      );
      break;
    case 'timestamp':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{...rest.customStyles, display: "flex", alignItems: "center"}}>
            {rowData => {
              let value = dataKeyConverter(dataKey, rowData);
              return formatToLocaleDateString(value);
            }}
          </Cell>
        </Column>
      );
      break;
    case 'checkbox':
      column = (
        <Column width={36} align="center" key={columnProps.key}>
          <HeaderCell style={{ padding: 0 }}>
            <div>
              <Checkbox
                inline
                checked={false}
                onChange={props.handleCheckAll}
                style={{ marginRight: 0}}
              />
            </div>
          </HeaderCell>
          <Cell style={{ padding: 0 }}>
            {rowData => {
              return <div style={{ lineHeight: '46px' }}>
                <Checkbox
                  value={rowData[dataKey]}
                  inline
                  onChange={props.handleCheck}
                  disabled={rest.disableCheck ? rest.disableCheck(rowData) : false}
                  checked={props.checkedKeys.some(item => item === rowData[dataKey])}
                  style={{ marginRight: 0}}
                />
              </div>
            }}
          </Cell>
        </Column>
      );
      break;
    case 'expand':
      column = (
        <Column width={30} align="center" key={columnProps.key} style={{ alignItems: 'start'}}>
          <HeaderCell style={{ padding: 0 }}>
            <div>
            </div>
          </HeaderCell>
          <Cell style={{ padding: 0 }}>
            {rowData => {
              const expanded = props.expandedKeys.some(item => item === rowData[dataKey]);
              return <span className="clickable">
                <Icon as={expanded ? IoMdArrowDropdown : IoMdArrowDropright} onClick={() => props.handleExpand(rowData[dataKey], !expanded)} />
              </span>
            }}
          </Cell>
        </Column>
      );
      break;
    case 'dropdown':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey} style={{display: 'flex'}}>
            {rowData => (
              <Whisper
                trigger="click"
                placement="leftStart"
                speaker={(props, ref) => {
                  const { className, left, bottom, onClose } = props;
                  return <DopeDropdown
                    dropDownType={dropDownType}
                    style={{ left, bottom }}
                    onClose={onClose}
                    className={className}
                    ref={ref}
                    rowData={rowData}
                    reloadTable={rest.reloadTable}
                  />;
                }}
              >
                <Button style={{backgroundColor: "transparent"}}><Icon as={MdMoreVert}/></Button>
              </Whisper>
            )}
          </Cell>
        </Column>
      );
      break;
    case 'name_link':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey}>
            {rowData => {
              const url = rest.getUrl(rowData);
              const disabled = rest.getDisabled ? rest.getDisabled(rowData) : false;
              let name = dataKeyConverter(dataKey, rowData);
              return (
                <Link className={disabled ? 'disabled' : ''} to={url} style={rest.customStyles}>{name}</Link>
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'pill':
      column = (
        <Column minWidth={140} {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell>
            {rowData => {
              const value = get(rowData, dataKey);
              if (!value) return null;

              return (
                <DopePill
                  text={value}
                  dataKey={dataKey} // TODO Why?
                  pillType={rest.pillType ? rest.pillType : get(rowData, rest.pillTypeKey)}
                  size={rowData[rest.size]}
                  pillLabelMap={rest.pillLabelMap}
                  message={rowData[rest.messageKey]}
                />
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'favorite':
      column = (
        <Column width={40} style={{display: "flex", alignItems: "center"}} key={columnProps.key}>
          <HeaderCell>{label}</HeaderCell>
          <Cell>
            {rowData => {
              const isFavorite = dataKeyConverter(dataKey, rowData)
              return (
                <DopeFavoriteIcon rowData={rowData} isFavorite={isFavorite} setFavorite={rest.setFavorite} />
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'icon':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell>
            {rowData => (
              <div style={{ cursor: "pointer" }} onClick={() => clickHandler(rowData)}>
                {rest.icon}
              </div>
            )}
          </Cell>
        </Column>
      );
      break;
    case 'currency':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell>
            {rowData => (
              <div>${(rowData[dataKey] / 100).toFixed(2)}</div>
            )}
          </Cell>
        </Column>
      );
      break;
    case 'event_time':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey}>
            {
              rowData => {
                const datetime = rowData[dataKey];
                const helpText = rowData[rest.helpTextKey];
                return (
                  <div>
                    <div className="label large">{timestampToDateTimeStr(datetime)}</div>
                    {helpText && <div key={rest.helpTextKey} className="help-text small">{helpText}</div>}
                  </div>
                );
              }
            }
          </Cell>
        </Column>
      );
      break;
    case 'list_pills':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell>
            {rowData => {
              return <ListPills lists={rowData[dataKey]} />
            }}
          </Cell>
        </Column>
      );
      break;
    case 'cents':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey}>
            {rowData => {
              return <Cents>{rowData[dataKey]}</Cents>
            }}
          </Cell>
        </Column>
      );
      break;
    case 'download_files':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey}>
            {rowData => {
              const filesArray = get(rowData, dataKey);
              const fileLinks = filesArray.map((file, index) => {
                const disabled = !file.url;
                const className = disabled ? 'disabled' : '';
                return (
                  <a key={index} href={file.url} target="_blank" rel="noreferrer" className={className}>{file.name}</a>
                );
              });

              return (
                <div className="flex column">
                  {fileLinks}
                </div>
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'custom_component':
      column = (
        <Column {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell>
            {rowData => {
              return (
                <rest.Component {...props} rowData={rowData} />
              );
            }}
          </Cell>
        </Column>
      );
      break;
    case 'custom':
      column = (
        <Column style={{display: "flex", alignItems: "center"}} key={columnProps.key} {...columnProps}>
          <HeaderCell>{label}</HeaderCell>
          <Cell dataKey={dataKey}>
            {rowData => {
              return rest.render(rowData);
            }}
          </Cell>
        </Column>
      );
      break;
    default:
      throw new Error(`Invalid cell type: ${type}`);
  }

  return column;
}

const DopeTable = ({ selectedActions, data, columns, onSortColumn, sortColumn, sortDirection, onSearchChange, onSearchClear, filterInputs, setFilters, filters = [], loading, onRowClick, tableHeader, scopeButtons = [], rowHeight = 50, wordWrap = false, height = 400, filterWrapperStyles = {}, ...rest }) => {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectableFilters, setSelectableFilters] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    const selectedFilters = filters.filter(filter => filter.hasOwnProperty('filter_category'))
    setSelectableFilters(selectedFilters);
  }, [JSON.stringify(filters)]);

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data.map(item => item.id) : [];
    if (!allChecked) {
      setCheckedKeys(keys);
      setAllChecked(true)
    } else {
      setCheckedKeys([]);
      setAllChecked(false);
    }
  };

  const selectedActionHandler = (selectedAction) => {
    selectedAction(checkedKeys);
    setCheckedKeys([]);
  };

  const handleCheck = (value, checked) => {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(keys);
  };

  const handleExpand = (value, expanded) => {
    const keys = expanded ? [...expandedKeys, value] : expandedKeys.filter(item => item !== value);
    setExpandedKeys(keys);
  };

  const columnCreatorProps = {
    ...rest,
    handleCheckAll,
    handleCheck,
    checkedKeys,
    handleExpand,
    expandedKeys,
  };

  const handleSearchInput = (value) => {
    setSearchText(value);
    onSearchChange(value);
  };

  const handleSearchClear = () => {
    setSearchText('');
    onSearchClear();
  };

  const handleFilterReset = () => {
    if (selectableFilters.length > 0) {
      const initialFilters = filters.filter(filter => !filter.hasOwnProperty('filter_category'))
      setFilters(initialFilters);
    }
  };

  const getRowHeight = (rowData) => {
    if (typeof rowHeight === 'function') {
      return rowHeight({ rowData, expandedKeys });
    }

    return rowHeight;
  };

  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", marginBottom: "5px", alignItems: "end", ...filterWrapperStyles }}>

        <div>
          {tableHeader && (
            <h5 style={{ fontWeight: 700, fontSize: '16px', lineHeight: '210x', width: "fit-content", borderBottom: "2px solid #EA0029", marginBottom: "15px"}}>
              {tableHeader}
            </h5>
          )}
          <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {filterInputs && filterInputs.map(filterInput => (
                <DopeFilterDelegator filterInput={filterInput} filters={filters} key={filterInput.title} />
              ))}

              {scopeButtons.map((ScopeButton, index) => (
                <ScopeButton key={index} />
              ))}

              {selectableFilters.length > 0 && (
                <DopeButton
                  props={{
                    onClick: handleFilterReset,
                    styles: {textDecoration: "none", padding: "4px", fontSize: "12px" },
                    buttonClass: "text-link",
                    label: "Clear All"
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {onSearchChange && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

            <InputGroup style={{width: "300px"}}>
              <Input onChange={value => handleSearchInput(value)} value={searchText} placeholder="Search"/>
              {searchText.length > 0 ? (<InputGroup.Addon onClick={handleSearchClear} style={{cursor: 'pointer'}}>
                <Icon as={MdOutlineClear} />
              </InputGroup.Addon>) : (<InputGroup.Addon>
                <Icon as={MdOutlineSearch} />
              </InputGroup.Addon>)}
            </InputGroup>

          </div>
        )}
      </div>

      {selectedActions && checkedKeys.length > 0 && selectedActions.map(action => (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px"}}>
          <div style={{color: "#255FDF", fontSize: "11px", fontWeight: 700 }}>
            {action.icon && (action.icon)}
            <button onClick={() => selectedActionHandler(action.action)} style={{background: "transparent", marginRight: "5px"}}>{action.label}</button>
          </div>
        </div>
      ))}

      <Table
        height={height}
        rowHeight={getRowHeight}
        style={{ width: '100%' }}
        data={data}
        onSortColumn={onSortColumn}
        sortColumn={sortColumn}
        sortType={sortDirection}
        loading={loading}
        onRowClick={onRowClick}
        rowClassName={onRowClick ? 'clickable-row' : null}
        bordered
        wordWrap={wordWrap}
        expandedRowKeys={expandedKeys}
        renderRowExpanded={rest.renderRowExpanded}
        rowKey={rest.rowKey || 'id'}
        shouldUpdateScroll={false}
      >
        {columns.filter(column => !column.hidden).map(column => columnCreator(column, columnCreatorProps))}
      </Table>
    </>
  );
}

export default DopeTable;
