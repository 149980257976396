import dopeClient from "../services/dopeClient";
import authApi from "../auth/authApi";

class StripeApi {

  constructor() {
    this.baseUrl = `/accounts/${authApi.getCurrentAccountId()}`;
  }

  pluralModelName = () => {
    return 'stripe'
  }

  get = async (stripe_route) => {
    const response = await dopeClient.get(`${this.baseUrl}/${stripe_route}`);
    return response.data;
  }

  query = async (params, signal) => {
    let route;
    if (params && params.route) {
      route =  params.route;
      delete params.route;
    } else {
      route = `${this.baseUrl}/index_stripe_invoices`;
    }
    const response = await dopeClient.get(route, { params, signal });
    return response.data;
  };

  post = async (action, params) => {
    const response = await dopeClient.post(`${this.baseUrl}/${action}`, params);
    return response.data;
  }

  getSubscriptionPlans = async () => {
    const response = await dopeClient.get('stripe/main_subscription_plans');
    return response.data.stripe_subscription_options;
  }
}

export default StripeApi;
