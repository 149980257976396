import React from "react";

const AttributeDisplay = ({ label, value }) => {
  return (
    <div className="pad-4 margin-bottom border-bottom">
      <div className="label large bold margin-4-b">{label}</div>
      <div className="label large">{value}</div>
    </div>
  );
};

export default AttributeDisplay;
