import React, { useState } from "react";

import { useCampaignListGenerationSettings } from "./campaignSlice";

import { Divider } from "rsuite";
import DopeTextInput from "../ui/DopeTextInput";
import DopeRadio from "../ui/DopeRadio";

const metersPerMile = 1609;

const maxDistanceOptions = [
  { label: "3 miles", value: 3 * metersPerMile },
  { label: "5 miles", value: 5 * metersPerMile },
  { label: "10 miles", value: 10 * metersPerMile },
  { label: "25 miles", value: 25 * metersPerMile },
  { label: "50 miles", value: 50 * metersPerMile },
  { label: "100 miles", value: 100 * metersPerMile },
];

const CampaignListBlitzSettings = () => {
  const [sampleAddressesOpen, setSampleAddressesOpen] = useState(false); // TODO get summary
  const [settings, actions] = useCampaignListGenerationSettings();

  return (
    <>
      <div className="geo-shapes-summary">
        <div className="geo-shapes-header">Contacts per address</div>

        <DopeTextInput
          name="max_contacts_per_generation"
          value={settings.max_contacts_per_generation}
          onChange={actions.update}
          style={{ margin: '20px 0' }}
        />

        <div className='geo-shapes-show-addresses' onClick={() => setSampleAddressesOpen(true)}>See Sample Addresses</div>
      </div>

      <div className="list-blitz-count-estimate">
        Based on your list size, we would be sending to <b>{settings.max_contacts_per_generation * settings.starting_list.contacts_count}</b> addresses.
      </div>

      <Divider />

      <div className="max-distance-radio-wrapper">
        <div className="header-text"><b>Max distance for each search</b></div>
        <div className="summary-text">We'll do our best to get <b>{settings.max_contacts_per_generation}</b> contacts around <i>each</i> address, but at what distance should we cap our search?</div>

        <DopeRadio
          name="max_distance"
          value={settings.max_distance}
          options={maxDistanceOptions}
          onChange={actions.update}
        />
      </div>
    </>
  );
};

export default CampaignListBlitzSettings;
