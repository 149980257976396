import React from "react";
import { useOutletContext } from "react-router-dom"

import CampaignDispatches from "./CampaignDispatches";

const CampaignDispatchesPage = () => {
  const { pageTabs } = useOutletContext()
  return (
    <div className="page">
      <div className="page-header">
        {pageTabs}
        <p className="header-text">Now it's time to build your campaign</p>
        <p className="help-text">Select your mailers to build a campaign based on our DOPE Marketing strategies. Start by selecting the date you want something to go out and then select the piece you want sent.</p>
      </div>

      <CampaignDispatches />
    </div>
  );
};

export default CampaignDispatchesPage;
