import React from "react";
import DopeCheckboxFilterDropdown from "./DopeCheckboxFilterDropdown";
import DopeDateRangeFilter from "./DopeDateRangeFilter";

const DopeFilterDelegator = ({ filterInput, filters }) => {
  switch (filterInput.type) {
    case "dateRange":
      return <DopeDateRangeFilter filterInput={filterInput} filters={filters} />;
    case "checkbox":
      return <DopeCheckboxFilterDropdown filterInput={filterInput} filters={filters} />;
    default:
      return <DopeCheckboxFilterDropdown filterInput={filterInput} filters={filters} />;
  }
}

export default DopeFilterDelegator;
