import React from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import {handleFilterChange} from "../utils/fn";
import authApi from "../auth/authApi";

const getUrl = (rowData) => {
  const page = rowData.active || rowData.status === 'archived' ? 'show' : 'details';
  return `/automations/${rowData.id}/${page}`;
};

const columns = [
  { type: 'stacked_text', dataKey: ['name', 'description'], flexGrow: 2, label: 'Name', sortable: true, filterable: true, getUrl },
  { type: 'date', dataKey: 'created_at', flexGrow: 1, label: 'Created Date', sortable: true },
  { type: 'date', dataKey: 'last_run_at', flexGrow: 1, label: 'Last Trigger', sortable: true, filterable: false },
  { type: 'text', dataKey: 'automation_runs_count', flexGrow: 1, label: '# of Triggers', sortable: true, filterable: false },
  { type: 'pill', pillTypeKey: 'effective_status', flexGrow: 2, dataKey: 'effective_status', label: 'Status', sortable: false, filterable: true },
];

const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Draft', value: 'draft' },
  { label: 'Archived', value: 'archived' }
];

const api = new DopeApi('automation');
const taggingsApi = new DopeApi('taggings');

const AutomationTable = () => {
  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setFilters,
    filters,
  } = useQuery2({ api, initialSearchScope: 'name' });

  const applyStatusFilter = (status) => {
    const filterObject = {
      filterField: 'status',
      operator: "in",
      filterCategory: "Status",
    };
    handleFilterChange(filters, setFilters, status, filterObject);
  };

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    };
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const filterInputs = [
    { title: 'Status', getOptions: () => statusOptions, applyFilter: applyStatusFilter },
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "Automation" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    },
  ];

  return (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      error={error}
      filterInputs={filterInputs}
      onSearchChange={onSearchInput}
      onSearchClear={onSearchClear}
      setFilters={setFilters}
      filters={filters}
      rowHeight={84}
      wordWrap={true}
    />
  );
};

export default AutomationTable;
