import React from 'react';
import Select from 'react-select';
import { useIntegrations } from "../integrationsSlice";

const eventOptions = [
  { value: 'create', label: 'Is Created' },
  { value: 'update', label: 'Is Updated' },
];

const SubscriptionEvent = ({ header, query = false }) => {
  const { subscription, updateSubscription, setSubscriptionDataType, dataTypes } = useIntegrations();

  const eventSelect = query ? null : (
    <div style={{ flexBasis: '50%' }}>
      <label>
        &nbsp;
      </label>
      <Select
        id="event-type"
        value={eventOptions.find(opt => opt.value === subscription.event) || ''}
        onChange={selection => updateSubscription({ event: selection.value })}
        options={eventOptions}
        isDisabled={!subscription.data_source}
      />
    </div>
  );

  return (
    <>
      {header || <div className="subscription-header">Choose Your Event</div>}
      <div style={{ display: 'flex', margin: '10px 0', maxWidth: 500, gap: 10, alignItems: 'end' }}>
        <div style={{ flexBasis: '50%' }}>
          <div className="header-5 margin-20-b">
            {query ? 'Select your data type' : 'When'}
          </div>
          <Select
            id="data-type"
            value={dataTypes.find(opt => opt.value === subscription.data_type) || ''}
            onChange={selection => setSubscriptionDataType(selection.value)}
            options={dataTypes}
            isDisabled={!subscription.data_source}
          />
        </div>

        {eventSelect}
      </div>
    </>
  )
};

export default SubscriptionEvent;
