import React from "react";
import { Loader } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import UserBadgeIcon from '@rsuite/icons/UserBadge';


import { useContactUI } from "./contactUISlice";
import { useContact } from "./contactSlice";

import { Link } from "react-router-dom";
import DopePill from "../ui/DopePill";

const iconStyles = {
  marginRight: "7px",
  color: "#292B2E",
  fontSize: "18px",
};

const ContactDropdown = ({ rowData: currentContact, onClose }) => {
  let { id } = currentContact;
  const [contact, contactActions, errors, loading] = useContact(id);
  const [contactUI, contactUIActions] = useContactUI();

  const handleClickEdit = () => {
    contactUIActions.setDrawerContactId(id);
  };

  return (
    <>
      <div>
        <div onClick={handleClickEdit} className="vertical-align row clickable margin-8-b label large bold" >
          <EditIcon style={iconStyles} /> Edit Contact
        </div>
        <Link to={`/contacts/${id}/details`} className="vertical-align row clickable margin-8-b label large bold"><UserBadgeIcon style={iconStyles} />View Contact Details</Link>

        <hr className="margin-16-b margin-16-t"/>

        <div className="vertical-align row clickable margin-8-b label large bold">Lists</div>

        {!loading && contact && contact.contact_lists.length > 0 && (
          <div className="flex row flex-wrap">
            {contact && contact.contact_lists.map((contact_list) => (
              <div className="margin-4-r margin-4-b" key={contact_list.id}>
                <DopePill text={contact_list.name} url={`/lists/${contact_list.list_id}/details`} pillType="tag" trim={30} />
              </div>
            ))}
          </div>
        )}

        {!loading && contact && (contact.is_lead || contact.is_client) && (
          <>
            <hr className="margin-16-b margin-16-t"/>
            <div className="vertical-align row clickable margin-8-b label large bold">Contact Status</div>
            <div className="flex row">
              {contact.is_lead && (
                <div className="margin-4-r margin-4-b">
                  <DopePill text="Lead" pillType="tag" trim={30}/>
                </div>
              )}

              {contact.is_client && (
                <div className="margin-4-r margin-4-b">
                  <DopePill text="Client" pillType="tag" trim={30}/>
                </div>
              )}
            </div>
          </>
        )}

        {loading && (<div className="label optional color-text-secondary vertical-align row"><Loader style={{marginRight: "5px"}} />Loading Lists...</div>)}

        {!loading && contact && contact.contact_lists.length === 0 && (<div className="label optional color-text-secondary">Contact does not belong to any lists</div>)}

      </div>
    </>
  )
}

export default ContactDropdown;
