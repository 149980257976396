import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeContentPage from "../ui/DopeContentPage";
import {handleFilterChange} from "../utils/fn";
import {unixTimestampToDateStr} from "../utils/date";
import DopeButton from "../ui/DopeButton";
import AddAccountModal from "./AddAccountModal";

const columns = [
  { type: 'stacked_text', dataKey: ['name', 'account_email'], label: 'Account', sortable: true },
  { type: 'text', dataKey: 'subscription_key', label: 'Subscription', sortable: false },
  { type: 'name_link', dataKey: 'stripe_customer_id', label: 'Stripe', sortable: false, getUrl: (row) => `https://dashboard.stripe.com/customers/${row.stripe_customer_id}` },
  { type: 'pill', dataKey: 'latest_subscription_invoice.status', pillTypeKey: 'latest_subscription_invoice.status', pillLabelMap: { open: "Lapsed Payment" }, label: 'Payment Status', sortable: false },
  { type: 'date', label: 'Next Bill Date', dataKey: 'stripe_subscription.current_period_end', dateConverter: unixTimestampToDateStr },
  { type: 'timestamp', dataKey: 'created_at', label: 'Created', sortable: true },
];

const api = new DopeApi('account');
const filterInputs = [];

const PulseAccountTable = () => {
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);

  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setFilters,
    filters,
    reload,
  } = useQuery2({
    api,
    initialSearchScope: 'name'
  });

  const navigate = useNavigate();

  const onRowClick = (row) => {
    navigate(`/pulse/accounts/${row.id}/details`);
  }

  const applyStatusFilter = (status) => {
    const filterObject = {
      filterField: 'mode',
      operator: 'in',
      filterCategory: 'Mode'
    }
    handleFilterChange(filters, setFilters, status, filterObject)
  }

  const statusOptions = [{ value: "manual", label: "Validation Mode" }, { value: "automatic", label: "Automatic" }];

  const addAccountButton = <DopeButton
    props={{
      buttonClass: "filled",
      label: "Add new account",
      onClick: () => setShowAddAccountModal(true),
    }}
  />

  return (
    <div>
      <AddAccountModal open={showAddAccountModal} handleClose={() => setShowAddAccountModal(false)} reload={reload} />

      <DopeContentPage
        pageTitle="All Accounts"
        pageContent={<DopeListPage
          columns={columns}
          tableProps={tableProps}
          paginationProps={paginationProps}
          error={error}
          filterInputs={filterInputs}
          filters={filters}
          setFilters={setFilters}
          onSearchClear={onSearchClear}
          onSearchChange={onSearchInput}
          onRowClick={onRowClick}
        />}
        actionButtons={addAccountButton}
      />
    </div>
  );
}

export default PulseAccountTable;
