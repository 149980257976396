import React from "react";

import './DopeToolTip.scss';
import { Tooltip, Whisper } from "rsuite";

// TODO spelling tooltip
const DopeToolTip = ({ content, triggerAction = "hover", placement = "top", arrow = false, children }) => {
  const tooltip = (
    <Tooltip className="tooltip wrapper" arrow={arrow}>
      {content}
    </Tooltip>
  );

  return (
    <Whisper
      placement={placement}
      trigger={triggerAction}
      speaker={tooltip}
    >
      <span>
        {children}
      </span>
    </Whisper>
  );
}

const DopeListToolTip = ({ header, list, ...rest }) => {
  const toolTipListContent = (
    <div>
      {header && <div className="tooltip header">{header}</div>}
      {list.map((item, index) => (
        <div className="flex row space-between gap-10" key={index}>
          <div className="tooltip item-label">{item.label}</div>
          <div className="tooltip item-value">{item.value}</div>
        </div>
      ))}
    </div>
  );

  return (
    <DopeToolTip
      content={toolTipListContent}
      {...rest}
    />
  );
}

DopeToolTip.FromList = DopeListToolTip;

export {
  DopeToolTip,
  DopeListToolTip
};
