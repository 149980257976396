import React from "react";
import {Col, Row} from "rsuite";
import DopeTextInput from "../ui/DopeTextInput";
import DopeStateSelector from "../ui/DopeStateSelector";

import { useContact } from "./contactSlice";

const ContactFormInputs = ({ errors }) => {
  const [contact, actions] = useContact();
  const updateContact = actions.update;

  return (
    <>
      <Row gutter={10} className="margin-bottom">
        <Col xs={12}>
          <label className="label">First Name *</label>
          <DopeTextInput
            name="first_name"
            value={contact.first_name}
            onChange={updateContact}
            error={errors?.first_name}
          />
        </Col>
        <Col xs={12}>
          <label className="label">Last Name *</label>
          <DopeTextInput
            name="last_name"
            value={contact.last_name}
            onChange={updateContact}
            error={errors?.last_name}
          />
        </Col>
      </Row>

      <Row gutter={10} className="margin-bottom">
        <Col xs={24}>
          <label className="label">Company Name</label>
          <DopeTextInput
            name="company_name"
            value={contact.company_name}
            onChange={updateContact}
            error={errors?.company_name}
          />
        </Col>
      </Row>

      <Row gutter={10} className="margin-bottom">
        <Col xs={24}>
          <label className="label">Address 1 *</label>
          <DopeTextInput
            name="address_1"
            value={contact.address_1}
            onChange={updateContact}
            error={errors?.address_1}
          />
        </Col>
      </Row>
      <Row gutter={10} className="margin-bottom">
        <Col xs={12}>
          <label className="label">Address 2</label>
          <DopeTextInput
            name="address_2"
            value={contact.address_2}
            onChange={updateContact}
            error={errors?.address_2}
          />
        </Col>
        <Col xs={12}>
          <label className="label">City</label>
          <DopeTextInput
            name="city"
            value={contact.city}
            onChange={updateContact}
            error={errors?.city}
          />
        </Col>
      </Row>
      <Row gutter={10} className="margin-bottom">
        <Col xs={12}>
          <label className="label">State</label>
          <DopeStateSelector
            name="state"
            value={contact.state}
            onChange={value => updateContact({ state: value })}
            error={errors?.state}
          />
        </Col>
        <Col xs={12}>
          <label className="label">Zip *</label>
          <DopeTextInput
            name="zip"
            value={contact.zip}
            onChange={updateContact}
            error={errors?.zip}
          />
        </Col>
      </Row>
    </>
  )
}

export default ContactFormInputs;
