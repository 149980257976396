import React from 'react';
import CharacterLockIcon from '@rsuite/icons/CharacterLock';

function DopeDataPromise() {
  return (
    <div style={{ borderRadius: "4px", padding: "20px", backgroundColor: "#F0F4F7"}}>
      <h5 style={{fontSize: "14px", fontWeight: 600 }}><CharacterLockIcon style={{ marginRight: "5px" }}/>Our promise to you:</h5>
      <p style={{ fontWeight: 500, fontSize: "11px", color: "#6F6F6F"}}>
        Your data is stored securely and separately from other customers.
        We will never use, scrape, or access your data unless we have your express consent.
        Your data belongs to you and you alone.
      </p>
    </div>
  );
}

export default DopeDataPromise;