import { useState, useEffect, useCallback, useRef } from "react";
import debounce from "lodash/debounce";

const useFetch = ({ fetch, params, debounceTime = 0, leading = false, onSuccess, skip = false }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);

  const abortControllerRef = useRef(null);

  const debouncedFetch = useCallback(debounce((fetchParams) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    fetch(fetchParams, abortControllerRef.current.signal)
      .then(resp => {
        setError(null);
        setResp(resp);
        setLoading(false);
        onSuccess && onSuccess(resp);
      })
      .catch((errors) => {
        console.error(errors);
        setError(errors);
        setLoading(false);
      });
  }, debounceTime, { leading }), []);

  const fetchResp = (fetchParams) => {
    setLoading(true);
    debouncedFetch(fetchParams);
  };

  useEffect(() => {
    if (!skip) {
      fetchResp(params);
    }
  }, [JSON.stringify(params)]);

  return {
    loading,
    error,
    resp,
  };
};

export default useFetch;


