import React, { useEffect, useState} from "react";
import {Col, Form, Grid, Row, SelectPicker} from "rsuite";

import { PolotnoContainer, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';

import { useDesign } from "./designSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeApi from "../services/DopeApi";
import DopeTextInput from "../ui/DopeTextInput";

import DopeButton from "../ui/DopeButton";
import createStore from "polotno/model/store";
import { useMediaUpload } from "../editor/mediaUploadSlice";
import { objectURLToFile } from "../utils/design";
import BelongsToSelect from "../ui/BelongsToSelect";
import { sizeToPixels } from "../utils/design";
import DopeDesignInput from "./DopeDesignInput";

// TODO: delegate this to backend to keep it consistent?
const SIZES = [

  { label: "6x9 Postcard", value: 'PostcardTemplate:6x9' },
  { label: "6.75x9.25 EDDM Postcard", value: 'PostcardTemplate:6.75x9.25' },
  { label: "6.5x11 EDDM Postcard", value: 'PostcardTemplate:6.5x11' },
  { label: "HWC - Front", value: 'DesignTemplate:5x7:HandwrittenCardTemplate' },
];

const MAILER_TYPES = [
  { label: 'Standard Postcard', value: 'standard' },
  { label: 'Hot Leads Postcard', value: 'hot_lead' },
];

const labelStyles = {
  fontWeight: 600,
  fontSize: "11px",
  color: "#8793A6",
  margin: '1rem 0',
  display: 'block'
}

const detailsStyles = {
  fontWeight: 'normal',
  fontSize: '11px',
  color: "#8793A6",
  margin: '1rem 0',
  display: 'block'
}

const rowStyles = {
  marginBottom: "16px"
}

const actionStyle = {
  textAlign: 'left',
  marginTop: '0.5rem'
};

const selectStyles = {
  width: "100%",
  minWidth: "200px"
};

const api = new DopeApi('designs');


const store = createStore({ key: process.env.REACT_APP_POLOTNO_API_KEY });

const AddDesignForm = ({ handleClose, reload, editable }) => {

  const { design, actions } = useDesign();
  const { mediaUpload, actions: mediaUploadActions } = useMediaUpload();

  const [dopeUI, dopeUIActions] = useDopeUI();
  const [loading, setLoading] = useState(false);

  const [frontFile, setFrontFile] = useState(null);
  const [frontImage, setFrontImage] = useState(null);
  const [frontJson, setFrontJson] = useState(null);

  const [backFile, setBackFile] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [backJson, setBackJson] = useState(null);

  const [currentSize, setCurrentSize] = useState(null);

  const [account, setAccount] = useState(null);
  const [mailerType, setMailerType] = useState(null);

  useEffect(() => {
    if (design) {
      let size = `${design.mail_template_type}:${design.mail_template.size}`;

      if (design.mail_template_type === 'DesignTemplate') {
        size += `:${design.mail_template.template_type}`;
      }

      setCurrentSize(size);

      if (design.id !== 'new') {
        setAccount(design.account_id);
        if (design.mail_template_type === 'PostcardTemplate') {
          setMailerType(design.mail_template.mailer_type);
        }
      }
    }
  }, [design?.id]);


  const handleSubmit = async () => {
    setLoading(true);

    try {
      const { width, height } = sizeToPixels(design.mail_template.size);

      store.clear();
      store.setSize(width, height);

      const now = Date.now();

      const designData = {
        mail_template_format: 'image',
        status: 'needs_review',
        files: {}
      };

      designData.files.front_image = await objectURLToFile(frontImage, `front_${now}.png`);

      if (hasBack()) {
        designData.files.back_image = await objectURLToFile(backImage, `back_${now}.png`);
      }

      if (frontJson && backJson) {
        store.loadJSON({ pages: [frontJson, backJson] });
        await store.waitLoading();

        const json = store.toJSON();

        designData.json_template = JSON.stringify(json);
        designData.mail_template_format = 'json';
      }

      await actions.save(designData)

      if (typeof reload === 'function') {
        reload();
      }

      handleClose();
      dopeUIActions.addFlashMessage({header: design.name, body: 'was successfully added!', type: 'success' });
    } catch (error) {
      console.error(error);
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const isNewDesign = design?.id === 'new';

  const hasBack = () => {
    return design?.mail_template_type === 'PostcardTemplate';
  };


  // TODO: As soon as polotno supports headless image rendering, we can remove the
  // workspace element from here.

  return (
    <Form fluid>
      <Grid fluid>

        <Col>
          <Row gutter={10} style={rowStyles}>

            <Col xs={24}>

              <label style={labelStyles}>Design Name</label>
              <DopeTextInput
                name="name"
                value={design?.name}
                onChange={actions.update}
                disabled={!editable}
              />
            </Col>

            <Col xs={24}>
              <label style={labelStyles}>Design Type</label>
              <SelectPicker
                data={SIZES}
                onChange={value => {
                  const [mail_template_type, size, template_type] = value.split(':');

                  actions.update({ mail_template_type });
                  actions.updateMailTemplate({ size });

                  if (mail_template_type === 'DesignTemplate') {
                    actions.updateMailTemplate({ template_type, size });
                  }

                  if (mail_template_type !== 'PostcardTemplate') {
                    setMailerType(null);
                  }

                  setCurrentSize(value);
                }}
                value={currentSize}
                cleanable={false}
                style={selectStyles}
                menuStyle={{ zIndex: 9999, marginTop: "4px" }}
                disabled={!editable}
              />
            </Col>

            {design?.mail_template_type === 'PostcardTemplate' && (
              <Col xs={24}>
                <label style={labelStyles}>Mailer Type</label>
                <SelectPicker
                  data={MAILER_TYPES}
                  onChange={value => {
                    setMailerType(value);
                    actions.updateMailTemplate({ mailer_type: value });
                  }}
                  value={mailerType}
                  cleanable={false}
                  style={selectStyles}
                  menuStyle={{ zIndex: 9999, marginTop: "4px" }}
                  disabled={!editable}
                />
              </Col>
            )}


            <Col xs={24}>
              <label style={labelStyles}>Account</label>
              <BelongsToSelect
                belongsToModelName="account"
                labelKey="name"
                name="account_id"
                value={account}
                onChange={({ account_id }, customer) => {
                  setAccount(account_id);
                  actions.update({ account_id });
                }}
                disabled={!editable}
              />

              {editable && account && (
                <p style={actionStyle}>
                  <small>
                    <a onClick={() => {
                      setAccount(null);
                      actions.update({ account_id: null });
                    }}
                    >Remove</a>
                  </small>
                </p>
              )}
            </Col>

            {currentSize && (
              <Col xs={24}>
                <label style={labelStyles}>Front Image</label>
                <DopeDesignInput
                  size={design?.mail_template?.size ?? '6x9'}
                  onChange={(srcImage, previewImage, file, json) => {
                    setFrontFile(file);
                    setFrontImage(srcImage);
                    setFrontJson(json);
                  }}
                  allowCrop={false}
                />
              </Col>
            )}
            {hasBack() && (
              <Col xs={24}>
                <label style={labelStyles}>Back Image</label>
                <DopeDesignInput
                  size={design?.mail_template?.size ?? '6x9'}
                  onChange={(srcImage, previewImage, file, json) => {
                    setBackFile(file);
                    setBackImage(srcImage);
                    setBackJson(json);
                  }}
                  allowCrop={false}
                />
              </Col>
            )}
          </Row>

        </Col>
      </Grid>

      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "16px"}}>
        <DopeButton
          props={{
            buttonClass: "filled-black",
            label: isNewDesign ? 'Add Design' : 'Update Design',
            onClick: handleSubmit,
          }}
          disabled={!design?.name || !frontImage || (hasBack() && !backImage) || !design?.mail_template_type || !account || (design?.mail_template_type === 'PostcardTemplate' && !mailerType)}
          loading={loading}
        />
      </div>
      <div style={{ width: '1000px', height: '1000px', display: 'none' }}>
        <Workspace store={store} />
        </div>
    </Form>
  );
}

export default AddDesignForm;
