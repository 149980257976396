import React from "react";
import { useOutletContext } from "react-router-dom";

import { Divider } from "rsuite";
import DopeTextInput from "../ui/DopeTextInput";
import DopeSelect from "../ui/DopeSelect";
import AttributeDisplay from "../ui/AttributeDisplay";
import { TagList } from "../ui/DopeTags";
import { FiltersSummary } from "../campaigns/CampaignFiltersSummary";
import { AudienceItem } from "../campaigns/CampaignAudienceSummary";
import AutomationPurchaseSummary from "./AutomationPurchaseSummary";
import AutomationDispatchTemplates from "./AutomationDispatchTemplates";
import SubscriptionSummary from "../integrations/Subscriptions/SubscriptionSummary";
import { useAutomation } from "./automationSlice";

const timePeriodOptions = [
  { value: 1, label: 'Day' },
  { value: 7, label: 'Week' },
  { value: 30, label: 'Month' },
  { value: 365, label: 'Year' },
];

const AutomationReview = () => {
  const { automation, derivedAutomationAttrs, actions } = useAutomation();
  const { pageTabs } = useOutletContext();

  const settings = automation.campaign_template.list_generation_setting;

  const activateHeader = (
    <div className="page-header">
      <p className="header-text">Review & Activate</p>
      <p className="help-text">Review your automation and submit to start testing!</p>
    </div>
  );

  const throttleInput = (
    <>
      <div className="header-4">Set your maximum trigger setting</div>
      <div className="page-panel">
        <div className="header-6">Automation Throttle</div>
        <div className="help-text">
          Our automation throttle allows you to better manage your automations and stay within your marketing budget.  When your automation reaches the max amount of triggers in the specified time frame your automation will be put into a validation mode status.  (You can always put your automation back into an active status). Define your limit below.
        </div>
        <Divider />
        <div className="vertical-align gap-10">
          <span>Maximum</span>
          <DopeTextInput
            type="number"
            name="throttle_count"
            value={automation.throttle_count}
            onChange={actions.update}
            style={{ width: 100 }}
            min={1}
          />
          <span>times per</span>
          <DopeSelect
            name="throttle_days"
            value={automation.throttle_days}
            onChange={actions.update}
            options={timePeriodOptions}
            style={{ width: 200 }}
          />
        </div>
      </div>
    </>
  );

  const setupDisplay = (
    <div className="split-cols">
      <div>
        <AttributeDisplay label="Automation Type" value="Marketing Automation" />
        <AttributeDisplay label="Description" value={automation.description} />
      </div>

      <div>
        <AttributeDisplay label="Tags" value={<TagList tags={derivedAutomationAttrs.tags} />} />
      </div>
    </div>
  );

  const excludeList = derivedAutomationAttrs.excludeItems.map(item => <AudienceItem isExclusion={true} item={item} key={item.key} />);

  const audienceDisplay = (
    <div className="split-cols">
      <div>
        <AttributeDisplay label="Sending Type" value={derivedAutomationAttrs.sendingTypeDisplay} />
        <AttributeDisplay label="# Recipients" value={derivedAutomationAttrs.audienceSizeDisplay} />
      </div>

      <div>
        <AttributeDisplay label="Filters" value={<FiltersSummary filters={settings.data_axle_filters} onRemove={actions.removeDataAxleFilter} />} />
        <AttributeDisplay label="Excluding" value={excludeList} />
      </div>
    </div>
  );

  return (
    <div>
      <div className="page">
        {pageTabs}
        {activateHeader}

        <div className="header-4 margin-bottom">{automation.name}</div>
        {setupDisplay}
      </div>

      <div className="page">
        {derivedAutomationAttrs.isNativeTrigger && (<SubscriptionSummary subscriptionId={automation.subscription_id} />)}

        <div className="header-4 margin-tb">What's being sent</div>
        <AutomationDispatchTemplates
          withContactCol={true}
          withHeader={true}
          editable={false}
        />

        <div className="header-4 margin-tb">Who it's being sent to</div>
        {audienceDisplay}

        {throttleInput}
      </div>

      <div className="page">
        <AutomationPurchaseSummary />
      </div>
    </div>
  );
}

export default AutomationReview;
