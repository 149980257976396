import React, {useEffect} from "react";
import {Drawer} from "rsuite";

import {useAccount} from "../accounts/accountSlice";
import DopeButton from "../ui/DopeButton";
import ProductAccountPricing from "../purchasing/ProductAccountPricing";

const EditAccountPricingDrawer = ({ accountId, onClose, open, reload }) => {
  const [account, accountActions] = useAccount();

  useEffect(() => {
    if (!account && accountId) {
      accountActions.get(accountId)
    }
  }, [accountId]);


  return (
    <div style={{fontFamily: "IBM Plex Sans"}}>
      {account &&
        <Drawer open={open} onClose={onClose} size="md" closeButton={true}>
          <Drawer.Body>
            <div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <h3 className="header-3">{account.name} Pricing</h3>
              </div>

              <div style={{marginTop: "40px"}}>
                <ProductAccountPricing providedAccountId={accountId} reload={reload} />
              </div>
            </div>
          </Drawer.Body>
        </Drawer>
      }
    </div>
  );
};

export default EditAccountPricingDrawer;
