import React from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeDrawer from "../ui/DopeDrawer";
import MailPieceDrawer from "../mailPieces/MailPieceDrawer";
import { handleFilterChange } from "../utils/fn";
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { useMailPieceUI } from "../mailPieces/mailPieceUISlice";

const api = new DopeApi('mail_pieces');
const campaignApi = new DopeApi("campaign");

const columns = [
  { type: "stacked_text", dataKey: ["contact.full_name", "contact.full_address"], label: "Name", flexGrow: 2, sortable: true },
  { type: "event_time", dataKey: "last_scanned_at", label: "Latest Scan", sortable: true, flexGrow: 2 },
  { type: "stacked_text", dataKey: ["campaign_name", "mail_template_name"], label: "Campaign", flexGrow: 3, sortable: true },
  { type: "icon", dataKey: "id", icon: <ArrowRightLineIcon />, flexGrow: 1 },
];

const HotLeadsTable = () => {
  const [mailPieceUI, mailPieceUIActions] = useMailPieceUI();

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, setScopes, scopes, reload }
  = useQuery2({
    api,
    initialFilters: [],
    initialScopes: [{ name: "scanned"}],
    initialSearchScope: "search_by_contact_name",
  });


  const applyCampaignIdFilter = (campaigns) => {
    const filterObject = {
      filterField: "dispatches.campaign_id",
      operator: "in",
      filterCategory: "Campaign",
    }
    handleFilterChange(filters, setFilters, campaigns, filterObject)
  }

  const filterInputs = [
    { title: "Campaign",
      getOptions: (name = "") => campaignApi.getOptions({
        valueKey: "id",
        labelKey: "name",
        scopes: name.length > 0 ? [{ name: "search_by_name", args: name }] : []
      }),
      applyFilter: applyCampaignIdFilter,
      searchable: true
    },
  ]

  return (
    <>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        onClickId={(mailPiece) => mailPieceUIActions.setDrawerMailPieceId(mailPiece.id)}
      />

      <DopeDrawer
         open={!!mailPieceUI.drawerMailPieceId}
         onClose={() => mailPieceUIActions.setDrawerMailPieceId(null)}
         bodyContent={<MailPieceDrawer id={mailPieceUI.drawerMailPieceId} />}
      />
    </>
  );
};

export default HotLeadsTable;
