import React from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useAutomation } from "./automationSlice";
import { handleFilterChange, handleDateFilter } from "../utils/fn";

import DopeListPage from "../ui/DopeListPage";
import { DopeLinkButton } from "../ui/DopeButton";

import AutomationSyncButton from "./AutomationSyncButton";
import AutomationRunActionCell from "./AutomationRunActionCell";

const automationRunApi = new DopeApi('automation_run');

const columns = [
  { type: 'event_time', dataKey: 'created_at', helpTextKey: 'automation_name', label: 'Trigger Date', sortable: true },
  { type: 'text', dataKey: 'full_name', label: 'Trigger Contact' },
  { type: 'address_text', dataKey: 'address_1', label: 'Address' },
  { type: 'pill', dataKey: 'status', label: 'Status' },
  { type: 'custom_component', dataKey: 'actions', label: 'Actions', Component: AutomationRunActionCell },
];

const statusOptions = [
  { value: 'needs_approval', label: 'Needs Approval' },
  { value: 'pending', label: 'Pending' },
  { value: 'complete', label: 'Complete' },
  { value: 'throttled', label: 'Throttled' },
  { value: 'duplicate', label: 'Duplicate' },
  { value: 'error', label: 'Error' },
  { value: 'invalid', label: 'Invalid' },
  { value: 'declined', label: 'Declined' },
  { value: 'test', label: 'Test' },
];

const AutomationRunTable = () => {
  const { automation } = useAutomation();
  const automationFilter = { field: 'automation_id', operator: '=', value: automation.id };
  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, reload, loading }
    = useQuery2({
    api: automationRunApi,
    initialFilters: [automationFilter],
    initialSortColumn: 'created_at',
    initialSearchScope: 'action_params',
  });

  const applyStatusFilter = (statuses) => {
    const statusFilter = {
      filterField: 'automation_runs.status',
      operator: 'in',
      filterCategory: 'Status',
    };

    handleFilterChange(filters, setFilters, statuses, statusFilter);
  }

  const applyDateRangeFilter = (startDate, endDate) => {
    handleDateFilter(filters, setFilters, 'automation_runs.created_at', startDate, endDate);
  }

  const filterInputs = [
    { title: "Status", getOptions: () => { return statusOptions }, applyFilter: applyStatusFilter },
    { title: "Date Range", type: "dateRange", applyFilter: applyDateRangeFilter}
  ];

  let reloadButton;
  if (automation.trigger_source === "native") {
    reloadButton = <AutomationSyncButton reload={reload} />;
  } else {
    reloadButton = (
      <DopeLinkButton
        onClick={() => reload()}
        loading={loading}
        disabled={loading}
        text={loading ? 'Refreshing...' : 'Refresh'}
      />
    );
  }
  return (
    <div>
      <div className="vertical-align">
        <span className="margin-left-auto">{reloadButton}</span>
      </div>

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filters={filters}
      />
    </div>
  );
};

export default AutomationRunTable;
