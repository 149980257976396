import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import DopeListPage from "../ui/DopeListPage";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { handleFilterChange } from "../utils/fn";
import DopeQuickScopeButton from "../ui/DopeQuickScopeButton";
import authApi from "../auth/authApi";

const contactApi = new DopeApi('contact');
const listApi = new DopeApi('list');
const taggingsApi = new DopeApi('taggings');


const getUrl = (rowData) => `/contacts/${rowData.id}/details`;
const setFavorite = async (rowData) => {
  await contactApi.update({id: rowData.id, favorite: !rowData.favorite});
}

const columns = [
  { type: 'stacked_text', dataKey: ['full_name', 'company_name'], label: 'Name', flexGrow: 3, customStyles: { fontSize: '14px', fontWeight: 400, color: '#292B2E' }, getUrl, sortable: true },
  { type: 'address_text', dataKey: 'full_address', label: 'Full Address', flexGrow: 3, sortable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Date Created', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'shipped_mail_pieces_count', label: 'Mail Sent', flexGrow: 2 },
  { type: 'list_pills', dataKey: 'contact_lists', label: 'List(s)', flexGrow: 4},
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'contact', flexGrow: 1 },
];

const ContactListPage = ({ setContactCount }) => {
  const [invalidScopeApplied, setInvalidScopeApplied] = useState(false);
  const [invalidContactsCount, setInvalidContactsCount] = useState(0);
  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, scopes, setScopes, reload }
    = useQuery2({
      api: contactApi,
      initialSearchScope: "full_name_and_address",
    });


  const location = useLocation();

  useEffect(() => {
    const getInvalidContacts = async () => {
      const invalidContacts = await contactApi.query({scopes: [{name: "with_special_chars_or_blank"}]});
      setInvalidContactsCount(invalidContacts.total);
    }

    getInvalidContacts();
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.applyInvalidScope) {
        applyInvalidScope();
        location.state.applyInvalidScope = false;
      }

      if (location.state.refresh) {
        reload();
        location.state.refresh = false;
      }
    }
  }, [location.state]);

  useEffect(() => {
    setContactCount(paginationProps.total)
  }, [paginationProps.total]);

  const applyListFilters = (lists) => {
    const filterObject = {
      filterField: "contact_lists.list_id",
      operator: "in",
      filterCategory: "Lists"
    }
    handleFilterChange(filters, setFilters, lists, filterObject)
  }

  const contactDesignations = [{label: "Clients", value: "is_client"}, {label: "Leads", value: "is_lead"}, {label: "Prospects", value: "is_prospect"}]
  const applyContactTypeFilters = (contactTypes, optionValue, checked) => {
    if (optionValue === "is_prospect") {
      applyProspectFilters(checked);
    } else {
      const filterObject = {
        filterField: optionValue ,
        operator: "=",
        filterCategory: optionValue
      }

      handleFilterChange(filters, setFilters, checked, filterObject);
    }
  }

  const applyProspectFilters = (checked) => {
    let filterObjects = [];
    ["is_client", "is_lead"].forEach((designation) => {
      const filterObject = {
        filterField: designation,
        operator: "!=",
        filterCategory: "is_prospect",
        allowMultiples: true
      }

      filterObjects.push(handleFilterChange(filters, setFilters, checked, filterObject));
    });

    if (filterObjects.filter(object => object !== undefined).length > 0) {
      setFilters([...filters, ...filterObjects])
    }
  }

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const applyInvalidScope = () => {
    const scopeApplied = !invalidScopeApplied;
    if (scopeApplied) {
        setScopes([...scopes, {name: "with_special_chars_or_blank"}]);
    } else {
      setScopes(scopes.filter(scope => scope.name !== "with_special_chars_or_blank"));
    }
    setInvalidScopeApplied(scopeApplied);
  }

  const applyInvalidScopeButton = () => (<DopeQuickScopeButton onClick={() => applyInvalidScope()} label="Invalid Contacts" selected={invalidScopeApplied}/>);

  const filterInputs = [
    { title: "Lists",
      getOptions: (name = "") => listApi.getOptions({
        valueKey: "id",
        labelKey: "name",
        search: name.length > 0 ? {text: name, column: "name"} : {},
      }),
      applyFilter: applyListFilters,
      searchable: true
    },
    { title: "Contact Type", getOptions: () => contactDesignations, applyFilter: applyContactTypeFilters },
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "Contact" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    }
  ]

  return (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      error={error}
      onSearchChange={onSearchInput}
      onSearchClear={onSearchClear}
      filterInputs={filterInputs}
      filters={filters}
      setFilters={setFilters}
      scopeButtons={invalidContactsCount > 0 ? [applyInvalidScopeButton] : []}
    />
  )
}


export default ContactListPage;
