import React, { useState } from "react";

import { useIntegrations } from "../integrationsSlice";

import DataSourceItem from "../DataSources/DataSourceItem";
import ConnectDrawer from "../Connections/ConnectDrawer";

const SubscriptionConnectionSelect = ({ header }) => {
  const {
    subscription,
    setSubscriptionDataSource,
    connections,
  } = useIntegrations();

  const [showAddConnection, setShowAddConnection] = useState(false);

  return (
    <>
      {header || <div className="subscription-header">Choose Your Connection</div>}

      <div className="data-source-list">
        {connections.map((connection, index) => (
          <DataSourceItem
            key={index}
            dataSource={connection.data_source}
            onClick={() => setSubscriptionDataSource(connection)}
            isSelected={subscription.data_source === connection.data_source.name}
          />
        ))}

          <DataSourceItem
            key="ADD"
            dataSource={{ label: "Add Connection" }}
            onClick={() => setShowAddConnection(true)}
          />
      </div>

      <ConnectDrawer open={showAddConnection} onClose={() => setShowAddConnection(false)} />
    </>
  );
};

export default SubscriptionConnectionSelect;
