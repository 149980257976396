import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useIntegrations } from "../integrationsSlice";

import { QueryBuilder } from 'react-querybuilder';

import TinySelect from '../ui/TinySelect';
import DatePicker from '../ui/DatePicker';

import 'react-querybuilder/dist/query-builder.css';

const dataTypeOptions = [
  { value: 'string', label: 'string' },
  { value: 'number', label: 'number' },
  { value: 'boolean', label: 'boolean' },
  { value: 'date', label: 'date' },
  { value: 'array', label: 'array' },
];

const booleanOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

const SubscriptionEventConditions = ({ header, query = false }) => {

  const { API, subscription, updateSubscription, dataFields, operators } = useIntegrations();

  const onChange = updateSubscription; // todo, just use updateSUbscription

  const { data_source, data_type, conditions = [], field_to_type_map = {} } = subscription;

  const [fieldValues, setFieldValues] = useState({ isLoading: {} });

  const disabled = !data_source || !data_type;

  const loadFieldValues = (field) => {
    if (!!field && !fieldValues[field] && !!data_source && !!data_type && !fieldValues.isLoading[field]) {
      setFieldValues({
        ...fieldValues,
        isLoading: {
          ...fieldValues.isLoading,
          [field]: true
        }
      });

      API.dataSources.queryDataFieldValues({ data_source, data_type, data_field: field })
        .then(dataFieldValues => {
          setFieldValues({
            ...fieldValues,
            [field]: dataFieldValues.map(value => ({ value, label: value.toString() })),
            isLoading: {
              ...fieldValues.isLoading,
              [field]: false
            }
          });
        });
    }
  };


  const onFieldSelect = (field, value_type) => {
    if (!field_to_type_map[field]) {
      onChange({ field_to_type_map: { ...field_to_type_map, [field]: value_type }});
    }
    loadFieldValues(field);
  };


  const controlElements = {
    fieldSelector: ({ value, handleOnChange }) => {
      const dataType = field_to_type_map[value] || 'string';
      return (
        <div style={{ position: 'relative' }}>

          <Select
            value={dataFields.find(field => field.value === value)}
            onChange={selection => {
              handleOnChange(selection.value);
              onFieldSelect(selection.value, selection.value_type);
            }}
            options={dataFields}
            placeholder="Select Field"
          />
          <span style={{ position: 'absolute', top: '100%', right: 2, height: '24px', paddingTop: 2 }} className={`squiggle ${fieldValues.isLoading[value] ? 'loading' : ''}`}>
            <TinySelect
              options={dataTypeOptions}
              value={dataTypeOptions.find(opt => opt.value === dataType)}
              onChange={selection => {
                onChange({ field_to_type_map: {
                  ...field_to_type_map,
                  [value]: selection.value
                }});
              }}
            />
          </span>

        </div>
      )
    },
    operatorSelector: ({ value, field, handleOnChange }) => {
      const dataType = field_to_type_map[field] || 'string';
      const filteredOperators = operators.filter(operator => {
        const dataTypeSupported = operator.supportedTypes.includes(dataType);
        const subTypeSupported = query ? !operator.triggerOnly : true;

        return dataTypeSupported && subTypeSupported;
      });
      const disabled = !field || field === '~';
      const options = filteredOperators.map(operator => ({ value: operator.value, label: operator.typeLabels[dataType] || operator.label }));
      return (
        <Select
          value={options.find(operator => operator.value === value) }
          onChange={selection => handleOnChange(selection.value)}
          options={options}
          placeholder="Select Operator"
          isDisabled={disabled}
        />
      )
    },
    valueEditor: ({ field, operator, value, values, handleOnChange }) => {
      const dataType = field_to_type_map[field] || 'string';
      const operatorOption = operators.find(op => op.value === operator) || {};
      const isUnary = operatorOption.isUnary || false;
      const disabled = !operator || operator === '=' || isUnary;

      if (dataType === 'date' && !isUnary) {
        return (
          <DatePicker
            onChange={date => {
              handleOnChange(date.toISOString());
            }}
            value={value}
            disabled={disabled}
            placeholderText={isUnary ? 'No Value Needed' : undefined}
          />
        )
      }

      let options = values;
      if (dataType === 'boolean') {
        options = booleanOptions;
      }

      const selectedValue = isUnary ? null : options.find(val => val.value === value) || (value && { value, label: value });

      return (
        <CreatableSelect
          value={selectedValue}
          onChange={selection => handleOnChange(selection.value)}
          options={options}
          isLoading={fieldValues.isLoading[field]}
          createOptionPosition="first"
          placeholder={isUnary ? 'No Value Needed' : 'Select Or Enter Value'}
          isDisabled={disabled}
          onMenuOpen={() => loadFieldValues(field)}
        />
      )
    },
    removeRuleAction: ({ handleOnClick }) => {
      return (
        <span className="condition-item-remove" onClick={handleOnClick}>
          <span className="condition-item-remove__icon">✕</span>
        </span>
      );
    },
    removeGroupAction: ({ handleOnClick }) => {
      return (
        <span className="condition-item-remove__icon" onClick={handleOnClick}>✕</span>
      );
    },

  };

  const controlClassnames = {
    queryBuilder: 'conditions-wrapper',
    ruleGroup: 'condition-group',
    rule: 'condition-item',
    removeRule: 'condition-item-remove',
    addRule: 'condition-item-add',
    addGroup: 'condition-item-add',
    combinators: 'and-or-select',
  };

  return (
    <>
      {header || <div className="subscription-header">Add Additional Rules</div>}

      <div className={`query-builder__container ${disabled ? 'disabled' : ''}`}>

        <QueryBuilder
          onQueryChange={query => onChange({ conditions: query })}
          query={conditions}
          showCombinatorsBetweenRules
          controlElements={controlElements}
          controlClassnames={controlClassnames}
          getValueEditorType={({ field, operator }) => {
            return "select";
          }}
          getValues={(field, operator) => {
            return fieldValues[field] || [];
          }}
          addRuleToNewGroups
        />

      </div>
    </>
  )

}

export default SubscriptionEventConditions;
