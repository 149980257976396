import React from 'react';

import ContactDropdown from "../contacts/ContactDropdown";
import ContactListDropdown from "../contacts/ContactListDropdown";
import ListDropdown from "../lists/ListDropdown";
import QRCodeDropdown from "../analysis/QRCodeDropdown";
import TrackingNumberDropdown from '../analysis/TrackingNumberDropdown';

let defaultStyles = {
  color: '#000',
  background: '#fff',
  width: 350,
  padding: '20px 20px 40px 20px',
  borderRadius: '8px',
  position: 'absolute',
  border: '1px solid #F3F3F3',
  boxShadow: '0px 6px 6px 0px #BABABA40',
  zIndex: 1000
};

const DropdownContent = ({ dropDownType, onClose, rowData, list, reloadTable }) => {
  switch (dropDownType) {
    case 'contact':
      return <ContactDropdown rowData={rowData} onClose={onClose} />;
    case 'list':
      return <ListDropdown list={list} />;
    case 'contact_list':
      return <ContactListDropdown rowData={rowData} onClose={onClose} reloadTable={reloadTable} />;
    case 'qr_code':
      return <QRCodeDropdown rowData={rowData} onClose={onClose} />;
    case 'tracking_number':
      return <TrackingNumberDropdown rowData={rowData} onClose={onClose} />
    default:
      return null;
  }
};

const DopeDropdown = React.forwardRef(({ dropDownType, rowData, toggleWhisper, style, onClose, reloadTable, ...rest }, ref) => {
  const finalStyles = { ...defaultStyles, ...style };

  return (
    <div {...rest} style={finalStyles} ref={ref}>
      <DropdownContent
        rowData={rowData}
        onClose={onClose}
        reloadTable={reloadTable}
        dropDownType={dropDownType}
      />
    </div>
  );
});

export default DopeDropdown;
