import React from "react";
import { Container } from "rsuite";

const DopeContentPage = ({ pageTitle, subHeader, pageContent, actionButtons, navLink }) => {

  const contentBodyStyles = {
    backgroundColor: '#FFFFFF',
    padding: '40px',
    margin: '20px',
    boxShadow: '0px 6px 6px 0px #BABABA40',
    borderRadius: '4px',
    height: 'fit-content',
  }

  const contentPageHeaderStyles = {
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '28px',
  }

  const subHeaderStyles = {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#8793A6',
  }

  return (
    <Container className="main-content-page" >
      {navLink && navLink}
      <Container style={contentBodyStyles}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "20px" }}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <h3 style={contentPageHeaderStyles}>{pageTitle}</h3>
            <h5 style={subHeaderStyles}>{subHeader}</h5>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            {actionButtons}
          </div>
        </div>
        {pageContent}
      </Container>
    </Container>
  );
}

export default DopeContentPage;
