import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import authApi from "../auth/authApi";
import { Icon } from '@rsuite/icons';
import { FaPlus } from "react-icons/fa";

import DopeContentPage from "../ui/DopeContentPage";
import ContactListPage from "./ContactListPage";
import ListListPage from "../lists/ListListPage";
import ContactDrawerForm from "./ContactDrawerForm";
import DopeTabs from "../ui/DopeTabs";
import { useObjectState } from "../hooks/useObjectState";
import DopeDrawer from "../ui/DopeDrawer";
import {useNavigate} from "react-router-dom";
import { useContactUI } from "./contactUISlice";
import DopeButton from "../ui/DopeButton";
import DopeSelectDrawer from "../ui/DopeSelectDrawer";

const CustomerListPageContent = () => {
  const [listCount, setListCount] = useState(0);
  const [contactCount, setContactCount] = useState(0);

  const location = useLocation();
  const defaultTab = location.state && location.state.tabName ? location.state.tabName : 'lists';

  return (
    <DopeTabs defaultTab={defaultTab}>
      <DopeTabs.Tab
        key={'lists'}
        name='lists'
        label={listCount ? `My Lists (${listCount})`: 'My Lists'}
        disabled={false}
      >
        <ListListPage setListCount={setListCount} />
      </DopeTabs.Tab>

      <DopeTabs.Tab
        key={'contacts'}
        name='contacts'
        label={contactCount ? `All Contacts (${contactCount})` : 'All Contacts'}
        disabled={false}
      >
        <ContactListPage setContactCount={setContactCount} />
      </DopeTabs.Tab>
    </DopeTabs>
  );
}

const canListPull = authApi.currentUserHasAdminPrivileges();

const listTypeOptions = [
  { label: "Upload a list", value: "upload", icon: "upload", description: "Upload a list that you have downloaded or purchased elsewhere." },
  { label: "Pull it from my CRM", value: "pull", icon: "pull", description: canListPull ? "Download contact data from your CRM" : "Coming Soon!", disabled: !canListPull },
];

const ListsAndContactsPage = () => {
  const [state, setState] = useObjectState({ addList: false });
  const [contactUI, contactUIActions] = useContactUI();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.uploadList) {
      handleActionClick("addList");
      location.state.uploadList = false;
    }
  }, []);

  const handleActionClick = (objectName) => {
    setState({...state, [objectName]: !state[objectName]});
  };

  const handleClickCreateList = (listType) => {
    if (listType === "upload") {
      navigate('/lists/new/upload');
    } else if (listType === "pull") {
      navigate('/list_pulls/new/setup');
    }
  };

  const actionButtons = (
    <>
      <DopeButton
        icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} />}
        props={{
          onClick: () => contactUIActions.setDrawerContactId('new'),
          label: "Add Contact",
          buttonClass: "outlined-red",
          styles: { marginRight: "10px" },
        }}
      />

      <DopeButton
        icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 6px 0 0"}} />}
        props={{
          onClick: () => handleActionClick("addList"),
          label: "Add List",
          buttonClass: "filled",
        }}
      />
    </>
  );

  return (
    <>
      <DopeContentPage
        pageTitle="Lists & Contacts"
        actionButtons={actionButtons}
        pageContent={<CustomerListPageContent/>}
      />

      <DopeSelectDrawer
        header="How would you like to add a list?"
        open={state.addList}
        onCancel={() => handleActionClick("addList")}
        onAction={handleClickCreateList}
        options={listTypeOptions}
      />

      <DopeDrawer
        open={!!contactUI.drawerContactId}
        onClose={() => contactUIActions.setDrawerContactId(null)}
        header={contactUI.drawerContactId === "new" ? "Add Contact" : "Edit Contact"}
        bodyContent={
          <ContactDrawerForm id={contactUI.drawerContactId} />
        }
      />
    </>
  );
}

export default ListsAndContactsPage;
