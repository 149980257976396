import React, { useState, useEffect } from "react";
import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";
import { useAccount } from "./accountSlice";
import AddUserModal from "./AddUserModal";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";
import {Icon} from "@rsuite/icons";
import {FaPlus} from "react-icons/fa";

const accountAssignmentApi = new DopeApi("account_assignment");

const fetchAccountAssignments = async (setAccountAssignments) => {
  const accountAssignments = await accountAssignmentApi.getMany({
    filters: [{ field: "account_id", value: authApi.getCurrentAccountId(), operator: "=" }],
    scopes: [{ name: "client_assignments" }]
  })
  setAccountAssignments(accountAssignments);
}


const userIconStyles = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: 900,
  color: "#FFFFFF",
  marginRight: "10px"
}

const accountRoles = {
  "admin": "Account Admin",
  "member": "Account Member",
}

const UserAccess = () => {
  const [account, actions] = useAccount()
  const [accountAssignments, setAccountAssignments] = useState([])
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [dopeUI, dopeUIActions] = useDopeUI();

  useEffect(() => {
    if (!account) {
      actions.get();
    }

    fetchAccountAssignments(setAccountAssignments);
  }, [account]);

  const refetchAssignments = () => {
    fetchAccountAssignments(setAccountAssignments);
  }

  const handleAddUserClick = async () => {
    setShowAddUserModal(!showAddUserModal)
  }

  const handleRemoveUser = async (accountAssignmentId) => {
    try {
      await accountAssignmentApi.destroy(accountAssignmentId);
      fetchAccountAssignments(setAccountAssignments);
      dopeUIActions.addFlashMessage({ header: "User removed", message: "User has been removed from this account", type: "success"})
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", message: "There was an error removing this user", type: "error"})
    }
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
        <h1 style={{ fontWeight: 600, fontSize: "25px"}}>User Access</h1>
        <DopeButton
          icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 6px 0 0"}} />}
          props={{
            onClick: () => handleAddUserClick(),
            label: "Add a User",
            buttonClass: "filled",
            styles: { marginLeft: "24px" },
          }}
        />

      </div>

      { accountAssignments && accountAssignments.map((accountAssignment) => (
        <div key={accountAssignment.id} style={{border: "1px solid #F3F3F3", padding: "15px 20px", borderRadius: "8px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "10px"}}>
          <div style={{ display: "flex", flexDirection: "row", flex: 1}}>

            <div className="account_assignment_icon" style={{...userIconStyles, backgroundColor: accountAssignment.user.profile_color}}>
              {accountAssignment.user.first_name[0]}{accountAssignment.user.last_name[0]}
            </div>

            <div style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ fontWeight: 400, fontSize: "16px", color: "#292B2E"}}>{accountAssignment.user.full_name}</div>
              <p style={{ fontWeight: 400, fontSize: "14px", color: "#6F6F6F"}}>{accountAssignment.user.email}</p>
            </div>

          </div>

          <div style={{display: "flex", flex: 1, justifyContent: "center"}}>
            <div style={{color: "#292B2E", fontSize: "14px", fontWeight: 400}}>{accountRoles[accountAssignment.role]}</div>
          </div>

          <div style={{display: "flex", flex: 1, justifyContent: "center"}}>
            <div style={{color: "#292B2E", fontSize: "14px", fontWeight: 400}}>{accountAssignment.user.phone_number ? accountAssignment.user.phone_number : "(999) 999-9999"}</div>
          </div>

          <div style={{display: "flex", flex: 1, justifyContent: "flex-end"}}>
            {accountAssignment.role === "admin" ? (
            <div></div>) : (

              <DopeButton
                props={{
                  onClick: () => handleRemoveUser(accountAssignment.id),
                  label: "Remove User",
                  buttonClass: "outlined-black",
                  styles: { padding: "4px 10px", fontWeight: 700, fontSize: "11px" }
                }}
              />

            )}
          </div>
        </div>
      ))}

      <AddUserModal show={showAddUserModal} showHideModal={handleAddUserClick} refetchAssignments={refetchAssignments} />
    </div>
  )
}

export default UserAccess;
