import React, { useState } from "react";

import { Drawer, Divider } from "rsuite";
import DopeCardSelect from "./DopeCardSelect";
import DopeButton from "./DopeButton";
import {Icon} from "@rsuite/icons";
import {FaPlus} from "react-icons/fa";

const DopeSelectDrawer = ({ open, onCancel, onAction, header, options }) => {
  const [selection, setSelection] = useState(options[0].value);

  return (
    <Drawer
      size="sm"
      placement="right"
      open={open}
      onClose={onCancel}
    >
      <Drawer.Header style={{borderBottom: 'none'}}>
        <h3 className="header-3 margin-4-t">{header}</h3>
      </Drawer.Header>

      <Drawer.Body style={{ padding: 0 }}>
        <div className="pad">
          <DopeCardSelect
            value={selection}
            options={options}
            onSelect={setSelection}
          />
        </div>

        <div className="space-between pad">
          <DopeButton
            props={{
              label: "Cancel",
              onClick: onCancel,
              buttonClass: "text-button create-cancel",
            }}
          />

          <DopeButton
            icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} />}
            props={{
              label: "Create",
              onClick: () => onAction(selection),
              buttonClass: "filled create-cancel",
            }}
          />

        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export default DopeSelectDrawer;
