import React from "react";
import { useLocation } from "react-router-dom";
import { Form, Grid, Row } from "rsuite";

import { useContact } from "./contactSlice";
import { useDopeUI } from "../ui/dopeUISlice";

import DopeErrorMessages from "../ui/DopeErrorMessages";
import DopeDataPromise from "../ui/DopeDataPromise";
import ContactFormInputs from "./ContactFormInputs";
import DopePlaceholder from "../ui/DopePlaceholder";
import DopeButton from "../ui/DopeButton";

const rowStyles = {
  marginBottom: "20px"
}

const formStyles = {
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "90%"
}

const ContactDrawerForm = ({ id, onClose }) => {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [contact, actions, errors, loading] = useContact(id);

  const location = useLocation();

  if (!contact) {
    return <DopePlaceholder type={'image'} rows={5} />
  } else if (loading && contact) {
    return <DopePlaceholder type={'image'} rows={5} title={"Creating Contact..."}/>
  }

  // TODO: add edit message
  const successMessage = () => {
    dopeUIActions.addFlashMessage({header: `${contact.first_name} ${contact.last_name}`, message: 'was successfully added to your contacts!', type: 'success' });
  }

  const handleSubmit = async () => {
    if ((!contact.first_name || !contact.last_name) && (!contact.company_name) || !contact.address_1 || !contact.zip ) {
      return dopeUIActions.addFlashMessage({
        header: 'Please fill out all required fields',
        body: 'Contacts must have a first name, last name, address 1, and zip' ,
        type: 'error'
      });
    }

    try {
      await actions.save();
      successMessage();
      onClose();
      location.state = { refresh: true };
    } catch (error) {
      console.log(error);
    }
  };

  // TODO: update to dopeforminput to include errors / warnings

  return (
    <Form onSubmit={handleSubmit} fluid disabled={loading} style={formStyles}>
      <div>
        <Grid fluid>
          <ContactFormInputs errors={errors} />
          <Row gutter={10} style={rowStyles}>
            <DopeDataPromise />
          </Row>
        </Grid>
      </div>

      <hr style={{ marginTop: "20px", marginBottom: "20px" }} />

      <div style={{ display: "flex", justifyContent: "space-between" }} >
        <DopeButton
          props={{
            onClick: onClose,
            buttonClass: "outlined-red create-cancel",
            label: "Cancel",
          }}
        />

        <DopeButton props={{ label: "Save Contact", type: "submit", buttonClass: "filled create-cancel" }} />
      </div>

      <DopeErrorMessages errorMessages={errors?.base} />
    </Form>
  );
}

export default ContactDrawerForm;
