import React, { useEffect, useState } from "react";

import { useMailPiece } from "./mailPieceSlice";
import DopePlaceholder from "../ui/DopePlaceholder";
import "./MailPieceDrawer.scss";
import MailTemplateDetails from "../designs/MailTemplateDetails";
import {Accordion} from "rsuite";
import {timestampToDateTimeStr} from "../utils/date";
import {capitalize} from "lodash";
import HandwrittenCardBodyPreview from "../designs/HandwrittenCardBodyPreview";


const isSelectedImage = (imageUrl, selectedImageUrl) => {
  return imageUrl === selectedImageUrl;
}

const MailPieceDetails = ({ mailPiece }) => {
  return (
    <>
      <div className="mailer-data-wrapper">

        <div className="space-between row margin-4-b">
          <div className="mailer-data-header">Sending ID</div>
          <div>{mailPiece.dispatch_id}</div>
        </div>

        <div className="space-between row margin-4-b">
          <div className="mailer-data-header">Batch ID</div>
          <div className="color-text-blue">{mailPiece.batch_id ? mailPiece.batch_id : "N/A"}</div>
        </div>

        <div className="space-between row margin-4-b">
          <div className="mailer-data-header">Scheduled Send Date</div>
          <div>{mailPiece.dispatch_date}</div>
        </div>

        <div className="space-between row margin-4-b">
          <div className="mailer-data-header">Current Status</div>
          <div className="vertical-align">
            <div className={`status-circle ${mailPiece.status}`}></div>
            <div>{capitalize(mailPiece.status)}</div>
          </div>
        </div>

      </div>
    </>
  )
}

const MailPieceStatusProgress = ({ mailPiece }) => {
  return (
    <Accordion>
      <Accordion.Panel header="Status Updates">
        {mailPiece.status_progress.map((statusObject, index) => (
          <div key={statusObject.status + '_' + index} className="vertical-align row label large pad-8">
            <div className={`status-circle ${statusObject.status}`}></div>
            <div className="margin-4-r">{capitalize(statusObject.status)}</div>
            <div className="color-text-secondary">{timestampToDateTimeStr(statusObject.time)}</div>
          </div>
        ))}
      </Accordion.Panel>
    </Accordion>
  )
}

const MailPieceDrawer = ({id}) => {
  const [mailPiece, mailPieceActions] = useMailPiece(id);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  useEffect(() => {
    if (!mailPiece) {
      mailPieceActions.get(id);
    }

    return mailPieceActions.resetToInitial
  }, []);

  useEffect(() => {
    if (mailPiece) {
      setSelectedImageUrl(mailPiece.front_image_url);
    }
  }, [mailPiece]);

  const onImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  }

  const isHandwritten = mailPiece?.dispatch?.mail_template_type === "HandwrittenCardTemplate";
  const frontImageSelected = isSelectedImage(mailPiece?.front_image_url, selectedImageUrl);
  const backImageSelected = isSelectedImage(mailPiece?.back_image_url, selectedImageUrl);

  return (
    <div>
      {mailPiece ? (
        <>
          <div className="margin-16-b">
            <h3 className="header-3">{mailPiece.contact_full_name}</h3>
            <h6 className="header-6 non-bold">{mailPiece.contact.address_1}</h6>
            <h6 className="header-6 non-bold">{mailPiece.contact.city_state_zip}</h6>
          </div>

          <div className="flex row margin-4-b">
            <div onClick={() => onImageClick(mailPiece.front_image_url)} className="image-container">
              <img
                alt="front-image"
                src={mailPiece.front_image_url}
                className={frontImageSelected ? "image-preview selected" : "image-preview"}
              />
              <div className="overlay-text">Front</div>
            </div>

            {isHandwritten ? (
              <div className="image-preview-container" onClick={() => onImageClick(mailPiece.back_image_url)} style={{ color: "black"}}>
                <div className={backImageSelected ? 'image-preview selected' : 'image-preview'} style={{overflow: "hidden"}}>
                  <HandwrittenCardBodyPreview
                    font={mailPiece.mail_template.font}
                    body={mailPiece.mail_template.body}
                    signature={mailPiece.mail_template.signature}
                    limit={mailPiece.mail_template?.card?.characters || 500}
                  />
                </div>
                <div className="image-preview-overlay-text">Body</div>
              </div>
            ) : (
              <div onClick={() => onImageClick(mailPiece.back_image_url)} className="image-container">
                <img
                  alt={"back-image"}
                  src={mailPiece.back_image_url}
                  className={backImageSelected ? "image-preview selected" : "image-preview"}
                />
                <div className="overlay-text">Back</div>
              </div>
            )}
          </div>

          <div>
            {(!isHandwritten || frontImageSelected) && <img alt="selected-image" src={selectedImageUrl} className="selected-image"/>}

            {isHandwritten && backImageSelected && (
              <div className="selected-image" style={{overflow: "hidden"}}>
                <HandwrittenCardBodyPreview
                  mailPiece={true}
                  font={mailPiece.mail_template.font}
                  body={mailPiece.mail_template.body}
                  signature={mailPiece.mail_template.signature}
                  limit={mailPiece.mail_template?.card?.characters || 500}
                />
              </div>
            )}
          </div>

          <div className="margin-16-t">
            <h4 className="header-4 margin-4-b">"{mailPiece.mail_template_name}"</h4>
            <div className="text-upper badge">
              {mailPiece.product_name}
            </div>
          </div>

          <MailPieceDetails mailPiece={mailPiece} />

          <MailTemplateDetails design={mailPiece} templateType={mailPiece.dispatch.mail_template_type} headerText="Mailer Details" />

          {mailPiece.status_progress.length > 0 && (<MailPieceStatusProgress mailPiece={mailPiece} />)}
        </>
      ) : (
        <DopePlaceholder type="image" rows={5} title="Loading Mailer..." />
      )}
    </div>
  )
}

export default MailPieceDrawer;
