import React from "react";

import { useListPull } from "./listPullSlice";
import { IntegrationsProvider } from "../integrations/integrationsSlice";
import DopePageStepHeader from "../ui/DopePageStepHeader";
import Subscription from "../integrations/Subscriptions/Subscription";
import SubscriptionConnectionSelect from "../integrations/Subscriptions/SubscriptionConnectionSelect";

const ListPullQuery = () => {
  const { listPull, actions } = useListPull();

  const subProps = {
    connection: {
      header: (
        <DopePageStepHeader
          step="1"
          headerText="Choose your connection"
          helpText="Select the connection you want to pull data from."
        />
      ),
    },
    event: {
      header: (
        <div className="margin-header">
          <DopePageStepHeader
            step="2"
            headerText="Setup your query"
            helpText="Select your data type, add additional filters to only pull in certain data, and map your fields."
          />
        </div>
      ),
    },
    conditions: {
      header: <div className="header-5 margin-header">Add additional filters</div>,
    },
    fieldMap: {
      header: <div className="header-5 margin-header">Map your fields</div>,
    },
    viewData: {
      header: [
        <div key="header" className="header-5 margin-header margin-10-b">Test your query</div>,
        <p key="help" className="help-text margin-bottom">Note: Records without address 1, city, state, or zip will be excluded from final results</p>,
      ],
    },
  };

  const initialQueryData = listPull.id && listPull.id !== "new" ? listPull.query_data : null;

  // TODO update automation to remove the double call of IntegrationsProvider / IntegrationsLoader, also double call from connection select?
  return (
    <IntegrationsProvider subscriptionId={null} initialSubscription={initialQueryData} query={true}>

      <div className="page bottom" key="connection">
        <SubscriptionConnectionSelect header={subProps.connection.header} />
      </div>

      <div className="page" key="subscription">
        <Subscription
          query={true}
          onChange={query_data => actions.update({ query_data })}
          subProps={subProps}
        />
      </div>

    </IntegrationsProvider>
  );
};

export default ListPullQuery;
