import differenceBy from 'lodash/differenceBy';

const markRemovedForDestruction = (prev, current, attributeName) => {
  const removed = differenceBy(prev[attributeName], current[attributeName], 'id');
  const marked = removed.map(item => ({ ...item, _destroy: true }));
  return [...current[attributeName], ...marked];
};

const asNestedDestroyable = (prev, current, attributeName) => {
  const marked = markRemovedForDestruction(prev, current, attributeName);
  return { [`${attributeName}_attributes`]: marked };
}

export {
  markRemovedForDestruction,
  asNestedDestroyable,
}
