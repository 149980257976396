import React, { useState } from 'react';

import omit from 'lodash/omit';
import classnames from 'classnames';

import { IconButton, Divider, Drawer, List } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';

import useFetch from '../hooks/useFetch';
import { useCampaignListGenerationSettings } from "./campaignSlice";
import { useCampaignUI } from "./campaignUISlice";

import addressSearchApi from '../services/addressSearchApi';

import './GeoShapes.scss';

const GeoShapesSummary = () => {
  const [sampleAddressesOpen, setSampleAddressesOpen] = useState(false);
  const [settings] = useCampaignListGenerationSettings();
  const { latitude, longitude, geo_shapes, data_axle_filters } = settings;
  const noShapes = geo_shapes.length === 0;
  const noStartingPoint = !latitude;

  const searchParams = {
    starting_point: { latitude, longitude },
    geo_shapes: geo_shapes.map((shape) => omit(shape, 'addresses', 'estimated_contacts', 'id')),
    filters: data_axle_filters,
    audience_type: settings.audience_type
  };

  const {
    loading,
    resp: searchResults,
  } = useFetch({
    fetch: addressSearchApi.search,
    params: searchParams,
    debounceTime: 500,
    skip: noShapes || noStartingPoint,
  });

  return (
    <>
      <div className={`geo-shapes-summary ${noShapes ? 'empty' : ''}`}>
        <div className='geo-shapes-header'>Estimated Contacts</div>
        <div className='geo-shapes-count'>{loading ? '...' : searchResults?.total}</div>
        <div className='geo-shapes-show-addresses' onClick={() => setSampleAddressesOpen(true)}>See Sample Addresses</div>
      </div>

      <Drawer
        size="sm"
        placement="right"
        open={sampleAddressesOpen}
        onClose={() => setSampleAddressesOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Sample Addresses</Drawer.Title>
        </Drawer.Header>
        <List>
          {
            (searchResults?.addresses || []).map((address, index) => (
              <List.Item key={index} index={index} style={{ paddingLeft: 20 }}>
                {address.full_address}
              </List.Item>
            ))
          }
        </List>
      </Drawer>
    </>
  );
};

const GeoShapeItem = ({ shape, index }) => {
  const [settings, actions] = useCampaignListGenerationSettings();
  const [campaignUI, campaignUIActions] = useCampaignUI();
  const { hoveringGeoShapeId } = campaignUI;
  const { latitude, longitude, data_axle_filters } = settings;

  const searchParams = {
    starting_point: { latitude, longitude },
    geo_shapes: [omit(shape, 'addresses', 'estimated_contacts', 'id')],
    filters: data_axle_filters,
    audience_type: settings.audience_type
  };

  const {
    loading,
    resp,
  } = useFetch({
    fetch: addressSearchApi.search,
    params: searchParams,
    debounceTime: 500,
  });

  const hovering = hoveringGeoShapeId === shape.id;
  const classes = classnames('geo-shape-item', { hovering });

  return (
    <div
      className={classes}
      onMouseEnter={() => campaignUIActions.setHoveringGeoShapeId(shape.id)}
      onMouseLeave={() => campaignUIActions.setHoveringGeoShapeId(null)}
    >
      <span>
        <span className='geo-shape-index'>{index + 1}</span>
        <span className='geo-shape-type'>{shape.type === 'circle' ? 'Radius' : 'Custom' } </span>
        <span className='geo-shape-count'>{loading ? '...' : resp?.total}</span>
        <span className='contacts-label'>Contacts</span>
      </span>
      <IconButton icon={<TrashIcon />} onClick={() => actions.removeGeoShape(index)} />
    </div>
  );
};

const GeoShapesList = () => {
  const [settings] = useCampaignListGenerationSettings();
  const { geo_shapes, latitude } = settings;

  const noStartingAddress = !latitude;

  return (
    <div>

      <GeoShapesSummary />

      <Divider />

      {noStartingAddress && (<div className='starting-address-prompt'>Enter a starting address to start building your mailing list</div>)}

      <div style={{ maxHeight: 250, overflow: 'auto', scrollbarWidth: 'thin' }}>
        {geo_shapes.map((shape, index) => (<GeoShapeItem key={index} shape={shape} index={index} />))}
      </div>

    </div>
  );
};

export default GeoShapesList;
