import React, {useState} from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeListPage from "../ui/DopeListPage";
import EditAccountPricingDrawer from "./EditAccountPricingDrawer";

const columns = [
  { type: 'text', dataKey: 'name', label: 'Client', sortable: true },
  { type: 'text', dataKey: 'subscription_key', label: 'Pricing Tier', className: 'text-upper' },
  { type: 'text', dataKey: 'account_discount_descriptions',label: 'Discounts' },
  { type: 'button', dataKey: 'id', key: 'edit', label: 'Edit', sortable: false }
];

const accountApi = new DopeApi("account");

const AccountsPricingTable = () => {
  const [selectedAccountId, setSelectedAccountId] = useState(null)
  const { tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, reload }
    = useQuery2({
    api: accountApi,
    initialSearchScope: "name",
  });

  const selectAccount = (account) => {
    setSelectedAccountId(account)
  }

  const deselectAccount = () => {
    setSelectedAccountId(null)
  }

  const closeDrawer = () => {
    deselectAccount()
  }

  return (
    <div>
      <DopeListPage
        title="Pricing"
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filters={filters}
        columns={columns}
        reload={reload}
        rowHeight={120}
        onClickEdit={account => selectAccount(account.id)}
      />

      <EditAccountPricingDrawer
        accountId={selectedAccountId}
        onClose={closeDrawer}
        open={selectedAccountId !== null}
        reload={reload}
      />
    </div>
  );
}

export default AccountsPricingTable;
