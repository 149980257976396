import { useDispatch, useSelector } from "react-redux";


import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

const [accountSlice, asyncActions] = createModelSlice('account', {
  cancelAccountUpdate: (state) => {
    state.current = state.prev;
  },
});

export default accountSlice.reducer;

export const {
  updateAccount,
  cancelAccountUpdate,
  resetToInitial,
} = accountSlice.actions;

export const selectAccount = (state) => state.account.current

export function useAccount() {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);

  const actions = wrapAll({
    update: updateAccount,
    cancelAccountUpdate: cancelAccountUpdate,
    resetToInitial: resetToInitial,
    get: asyncActions.get,
    destroy: asyncActions.destroy,
  }, dispatch);

  actions.save = (update = {}) => {
    const params = {
      ...account,
      ...update
    };

    return dispatch(asyncActions.save(params));
  };

  actions.purchaseSubscription = async (stripe_subscription_id, payment_method_id, signature) => {
    const subscriptionParams = { ...account, actions: [{name: 'add_stripe_subscription', args: [stripe_subscription_id, payment_method_id, signature] }]}
    return await actions.save(subscriptionParams)
  };

  actions.importStripeSubscription = async (stripe_subscription_id) => {
    const subscriptionParams = { ...account, actions: [{name: 'import_stripe_subscription', args: [stripe_subscription_id] }]}
    return await actions.save(subscriptionParams)
  };

  actions.addPaymentMethod = async (payment_method_id) => {
    const params = { ...account, actions: [{name: 'add_payment_method', args: [payment_method_id] }]}
    return await actions.save(params)
  }

  return [account, actions];
}
