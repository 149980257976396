import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from "react-redux";
import omit from 'lodash/omit';

import { asNestedDestroyable } from "../services/createModelSelectors";
import DopeApi from '../services/DopeApi';
const api = new DopeApi('campaign');

export const saveCampaign = createAsyncThunk(
  'campaign/saveCampaign',
  async (campaign, { rejectWithValue }) => {
    try {
      return await api.save(campaign);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getCampaign = createAsyncThunk(
  'campaign/getCampaign',
  async (id, { rejectWithValue }) => {
    try {
      return await api.get(id);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const initialState = {
  prev: null,
  current: null,
  loading: false,
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    updateCampaign: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign, update);
    },
    updateListGenerationSettings: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign.list_generation_setting, update);
    },
    updateDispatch: ({ current: campaign }, { payload }) => {
      const { dispatches } = campaign;
      const { index, update } = payload;
      Object.assign(dispatches[index], update);
    },
    addDispatch: ({ current: campaign }, { payload: dispatch }) => {
      campaign.dispatches.push(dispatch);
    },
    removeDispatch: ({ current: campaign }, { payload: dispatch }) => {
      campaign.dispatches = campaign.dispatches.filter((d) => d.date !== dispatch.date);
    },
    updateGeoShape: ({ current: campaign }, { payload }) => {
      const { geo_shapes } = campaign.list_generation_setting;
      const { id, update } = payload;
      const index = geo_shapes.findIndex((geo_shape) => geo_shape.id === id);
      Object.assign(geo_shapes[index], update);
    },
    addGeoShape: ({ current: campaign }, { payload: geo_shape }) => {
      campaign.list_generation_setting.geo_shapes.push(geo_shape);
    },
    removeGeoShape: ({ current: campaign }, { payload: index }) => {
      campaign.list_generation_setting.geo_shapes.splice(index, 1);
    },
    updateDataAxleFilters: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign.list_generation_setting.data_axle_filters, update);
    },
    removeDataAxleFilter: ({ current: campaign }, { payload: filterKey }) => {
      delete campaign.list_generation_setting.data_axle_filters[filterKey];
    },
    resetToInitial: (state) => {
      state.current = null;
      state.prev = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCampaign.fulfilled, (state, action) => {
        state.prev = action.payload;
        state.current = action.payload;
      })
      .addCase(saveCampaign.rejected, (state, action) => {
        Object.assign(state.current, { errors: action.payload });
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.prev = action.payload;
        state.current = action.payload;
      })
  }
});

export default campaignSlice.reducer;

export const {
  updateCampaign,
  updateListGenerationSettings,
  updateDispatch,
  addDispatch,
  removeDispatch,
  updateGeoShape,
  addGeoShape,
  removeGeoShape,
  updateDataAxleFilters,
  removeDataAxleFilter,
  resetToInitial,
} = campaignSlice.actions;

export const selectCampaign = (state) => state.campaign.current;
export const selectPrevCampaign = (state) => state.campaign.prev;
export const selectListGenerationSettings = (state) => state.campaign.current.list_generation_setting;

const campaignToParams = (campaign, prevCampaign) => { // TODO Flush out
  const campaignParams = {
    ...campaign,
    list_generation_setting_attributes: {
      ...campaign.list_generation_setting,
      geo_shapes: campaign.list_generation_setting.geo_shapes.map(geo_shape => omit(geo_shape, 'addresses', 'estimated_contacts')),
      id: campaign.list_generation_setting.id === 'new' ? null : campaign.list_generation_setting.id,
      ...asNestedDestroyable(prevCampaign.list_generation_setting, campaign.list_generation_setting, 'list_generation_setting_seed_lists'),
      ...asNestedDestroyable(prevCampaign.list_generation_setting, campaign.list_generation_setting, 'list_generation_setting_suppression_lists'),
    },
    ...asNestedDestroyable(prevCampaign, campaign, 'taggings'),
    ...asNestedDestroyable(prevCampaign, campaign, 'dispatches'),
  };

  return campaignParams;
};

export function useCampaign() {
  const dispatch = useDispatch();
  const campaign = useSelector(selectCampaign);
  const prevCampaign = useSelector(selectPrevCampaign);

  const actions = {
    update: (payload) => dispatch(updateCampaign(payload)),
    updateListGenerationSettings: (payload) => dispatch(updateListGenerationSettings(payload)),
    updateDispatch: (payload) => dispatch(updateDispatch(payload)),
    addDispatch: (payload) => dispatch(addDispatch(payload)),
    removeDispatch: (payload) => dispatch(removeDispatch(payload)),
    get: (id) => dispatch(getCampaign(id)),
    resetToInitial: () => dispatch(resetToInitial()),
    save: (additionalParams = {}) => { // TODO - figure out the best way to parse params for the api - white list? Black lists? Sending too much to server
      if (additionalParams.preventDefault instanceof Function) {
        console.error("You're passing an event to saveCampaign! Campaigns cannot be updated with an event!!!");
        additionalParams = {};
      }

      const campaignParams = { ...campaignToParams(campaign, prevCampaign), ...additionalParams };
      return dispatch(saveCampaign(campaignParams));
    },
    schedule: () => {
      const campaignParams = { ...campaignToParams(campaign, prevCampaign), actions: [{ name: 'schedule' }] };
      return dispatch(saveCampaign(campaignParams));
    },
    cancelDispatch: (dispatchId) => {
      const campaignParams = { ...campaignToParams(campaign, prevCampaign), actions: [{ name: 'cancel_dispatch', args: [dispatchId] }] };
      return dispatch(saveCampaign(campaignParams));
    },
  };

  return [campaign, actions];
}

export function useCampaignListGenerationSettings() {
  const dispatch = useDispatch();
  const listGenerationSettings = useSelector(selectListGenerationSettings);
  const actions = {
    update: (payload) => dispatch(updateListGenerationSettings(payload)),
    updateGeoShape: (payload) => dispatch(updateGeoShape(payload)),
    addGeoShape: (payload) => dispatch(addGeoShape(payload)),
    removeGeoShape: (payload) => dispatch(removeGeoShape(payload)),
    updateDataAxleFilters: (payload) => dispatch(updateDataAxleFilters(payload)),
    removeDataAxleFilter: (payload) => dispatch(removeDataAxleFilter(payload)),
  };

  return [listGenerationSettings, actions];
}


