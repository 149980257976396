import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useCampaignListGenerationSettings } from "./campaignSlice";
import { useCampaignUI } from "./campaignUISlice";

import GoogleMapReact from 'google-map-react';
import Autocomplete from "react-google-autocomplete";
import { Container, Row, Col, SelectPicker } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { MdOutlineDraw, MdLocationSearching } from "react-icons/md";

import GeoShapesList from './GeoShapesList';
import AudienceFiltersInput from './AudienceFiltersInput';
import CampaignFiltersSummary from './CampaignFiltersSummary';
import CampaignListBlitzSettings from './CampaignListBlitzSettings';
import DopeButton from "../ui/DopeButton";

const extractGeoFilter = (shape) => {
  const { type, id } = shape;

  if (type === 'circle') {
    return {
      type,
      id,
      center: {
        lat: shape.getCenter().lat(),
        lng: shape.getCenter().lng(),
      },
      radius: shape.getRadius(),
    };
  } else if (type === 'polygon') {
    return {
      type,
      id,
      path: shape.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      })),
    };
  }
};

const isPlaceValid = (place) => {
  const hasLat = !!place.geometry?.location?.lat();
  const hasLng = !!place.geometry?.location?.lng();
  const hasZip = !!place.address_components?.some(component => component.types.includes('postal_code'));
  const hasAddress = !!place.address_components?.some(component => component.types.includes('street_number') || component.types.includes('route'));

  return hasLat && hasLng && hasZip && hasAddress;
};

const parseAddressComponents = (place) => {
  let result = {
    city: null,
    address_1: null,
    address_2: null,
    zip: null,
    zip_4: null,
    state: null,
    country: null,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  };

  place.address_components.forEach(component => {
    if (component.types.includes('locality')) {
      result.city = component.long_name;
    }
    if (component.types.includes('street_number')) {
      result.address_1 = (result.address_1 ? result.address_1 + ' ' : '') + component.long_name;
    }
    if (component.types.includes('route')) {
      result.address_1 = (result.address_1 ? result.address_1 + ' ' : '') + component.long_name;
    }
    if (component.types.includes('sublocality_level_1')) {
      result.address_2 = component.long_name;
    }
    if (component.types.includes('postal_code')) {
      result.zip = component.long_name;
    }
    if (component.types.includes('postal_code_suffix')) {
      result.zip_4 = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      result.state = component.long_name;
    }
    if (component.types.includes('country')) {
      result.country = component.long_name;
    }
  });

  result.full_address = `${result.address_1}${result.address_2 ? ', ' + result.address_2 : ''}, ${result.city}, ${result.state} ${result.zip}${result.zip_4 ? '-' + result.zip_4 : ''}`;

  return result;
};

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: true,
  fullscreenControl: false,
};

const autoCompleteOptions = {
  componentRestrictions: { country: "us" },
  types: ["address"],
  fields: ['geometry.location', 'address_components']
};

const addShapesWrapperStyles = { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "8px" };
const addShapesWrapperDisabledStyles = { ...addShapesWrapperStyles, pointerEvents: "none", opacity: 0.5 };

const GeoFilterInput = () => {
  const [settings, settingsActions] = useCampaignListGenerationSettings();
  const [campaignUI, campaignUIActions] = useCampaignUI();
  const noStartingAddress = !settings.latitude || !settings.longitude || !settings.address_1 || !settings.zip;

  const [map, setMap] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [overlays, setOverlays] = useState([]);
  const [placeError, setPlaceError] = useState(false);

  const setDrawingMode = (mode) => {
    if (drawingManager) {
      drawingManager.setDrawingMode(mode);
    }
  };

  const updateShape = (shape) => {
    const geoFilter = extractGeoFilter(shape);
    settingsActions.updateGeoShape({ id: shape.id, update: geoFilter });
  };

  const addShapeListeners = (shape) => {
    const { type } = shape;

    shape.setEditable(true);
    shape.setDraggable(true);

    shape.addListener('mouseover', () => {
      campaignUIActions.setHoveringGeoShapeId(shape.id);
    });
    shape.addListener('mouseout', () => {
      campaignUIActions.setHoveringGeoShapeId(null);
    });

    if (type === 'circle') {
      shape.addListener('radius_changed', updateShape.bind(null, shape));
      shape.addListener('dragend',  updateShape.bind(null, shape));
    } else if (type === 'polygon') {
      shape.addListener('mouseup',  updateShape.bind(null, shape));
    }
  };

  const handlePlaceSelection = (place) => {
    const isValid = isPlaceValid(place);
    if (isValid) {
      setPlaceError(false);
      const address = parseAddressComponents(place);
      settingsActions.update(address);
    } else {
      setPlaceError(true)
    }
  };

  const handlePlaceChange = (e) => {
    settingsActions.update({ full_address: e.target.value });
  };

  useEffect(() => { // add and update shapes
    if (map && mapsApi) {
      const manager = new window.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
      });

      manager.setMap(map);
      setDrawingManager(manager);

      const listener = mapsApi.event.addListener(manager, 'overlaycomplete', (event) => {
        manager.setDrawingMode(null);

        const shape = event.overlay;
        shape.type = event.type;
        shape.id = uuidv4();

        const geoFilter = extractGeoFilter(shape);
        settingsActions.addGeoShape(geoFilter);

        setOverlays((prev) => [...prev, shape]);
        addShapeListeners(shape);
      });

      // draw saved geo shapes
      const shapes = settings.geo_shapes.map(shape => {
        if (shape.type === 'circle') {
          const circle = new mapsApi.Circle({
            id: shape.id,
            type: shape.type,
            center: shape.center,
            radius: shape.radius,
            strokeColor: '#000000',
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: '#000000',
            fillOpacity: 0.3,
          });
          circle.setMap(map);
          addShapeListeners(circle);
          return circle;
        } else if (shape.type === 'polygon') {
          const polygon = new mapsApi.Polygon({
            id: shape.id,
            type: shape.type,
            path: shape.path,
            strokeColor: '#000000',
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: '#000000',
            fillOpacity: 0.3,
          });
          polygon.setMap(map);
          addShapeListeners(polygon);
          return polygon;
        }
      });

      setOverlays(shapes);

      return () => {
        mapsApi.event.removeListener(listener);
        manager.setMap(null);
      };
    }
  }, [map, mapsApi]);

  useEffect(() => { // keep hover states in sync
    if (campaignUI.hoveringGeoShapeId) {
      const hoveringOverlay = overlays.find(overlay => overlay.id === campaignUI.hoveringGeoShapeId);
      if (hoveringOverlay) {
        hoveringOverlay.setOptions({ strokeColor: '#FF0000' });
      }
    } else {
      overlays.forEach(overlay => {
        overlay.setOptions({ strokeColor: '#000000' });
      });
    }
  }, [campaignUI.hoveringGeoShapeId]);

  useEffect(() => { // remove deleted shapes from the map
    overlays.forEach(overlay => {
      if (!settings.geo_shapes.find(shape => shape.id === overlay.id)) {
        overlay.setMap(null);
      }
    });
  }, [settings.geo_shapes.map(shape => shape.id).join(',')]);

  const inputStyles = {
    width: '100%',
    padding: '7px 11px',
    border: '1px solid #EAECEF',
    borderRadius: 4,
  };

  const shapesDisplay = settings.generation_type === 'list_blitz' ? <CampaignListBlitzSettings /> : (
    <>
      <div style={noStartingAddress ? addShapesWrapperDisabledStyles : addShapesWrapperStyles}>
        <DopeButton
          className="icon-clickable"
          icon={<Icon as={MdOutlineDraw} />}
          props={{
            buttonClass: "text-button",
            label: "Add a shape",
            onClick: () => setDrawingMode('polygon'),
          }}
        />

        <DopeButton
          className="icon-clickable"
          icon={<Icon as={MdLocationSearching} />}
          props={{
            buttonClass: "text-button",
            label: "Add a radius",
            onClick: () => setDrawingMode('circle'),
          }}
        />
      </div>

      <GeoShapesList />
    </>
  );

  return (
      <Container>

        <Row gutter={10} style={{ marginBottom: 10 }}>
          <Col xs={24} sm={24} md={18}>
            {mapsApi &&
              <div>
                <label className="rs-form-control-label">Starting Address</label>
                {placeError && <span style={{ color: 'red', fontSize: 12 }}> is invalid</span>}
                <Autocomplete
                  onPlaceSelected={handlePlaceSelection}
                  options={autoCompleteOptions}
                  style={inputStyles}
                  placeholder="123 Main St, New York, NY 10128"
                  value={settings.full_address}
                  onChange={handlePlaceChange}
                />
              </div>
            }
            <div>
              <CampaignFiltersSummary />
            </div>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <label className="rs-form-control-label">Audience Type</label>
            <SelectPicker
              searchable={false}
              cleanable={false}
              style={{ width: '100%' }}
              value={settings.audience_type}
              onChange={(value) => settingsActions.update({ audience_type: value, data_axle_filters: {} })}
              data={[
                { label: 'Single Family Homeowners', value: 'residential' },
                { label: 'All Residents', value: 'all_residential' },
                { label: 'Businesses', value: 'commercial' },
              ]}
            />

            <AudienceFiltersInput
              onUpdate={settingsActions.updateDataAxleFilters}
              onRemove={settingsActions.removeDataAxleFilter}
              values={settings}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={18}>
            <div style={{ height: 550 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  libraries: ['drawing', 'places'],
                }}
                center={settings.latitude ? { lat: parseFloat(settings.latitude), lng: parseFloat(settings.longitude) } : { lat: 39.8283, lng: -98.5795 }}
                zoom={settings.latitude ? 18 : 4}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  setMap(map);
                  setMapsApi(maps);
                }}
                options={mapOptions}
              />
            </div>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <div style={{ width: '100%', height: 550, padding: "0 16px 16px 16px", backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', }}>

              {shapesDisplay}

            </div>
          </Col>
        </Row>

      </Container>
  );
};

export default GeoFilterInput;
