import omit from "lodash/omit";
import _ from "lodash";

import dopeClient from "./dopeClient";
import { toPluralName } from "../utils/railsNames";

function objectToFormData(obj, rootName, ignoreList) {
  var formData = new FormData();

  function appendFormData(data, root) { // TODO MAJOR get away from form data! Figure out images/files some other way
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[]');
        }
      } else if (data instanceof Date) {
        formData.append(root, data.toISOString());
      } else if (typeof data === 'object' && data) {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + '[' + key + ']');
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  }

  function ignore(root){
    return Array.isArray(ignoreList) && ignoreList.some(x => x === root);
  }

  appendFormData(obj, rootName);

  return formData;
}

class DopeApi {

  constructor(modelName) {
    this.modelName = modelName;
  }

  pluralModelName = () => {
    return toPluralName(this.modelName);
  };

  query = async (params, signal) => {
    const response = await dopeClient.get(`/${this.pluralModelName()}`, { params, signal });
    return response.data;
  };

  getMany = async (params, signal) => {
    const data = await this.query(params, signal);
    return data[this.pluralModelName()];
  };

  getOptions = async ({labelKey = 'name', valueKey = 'id', filters = [], scopes = [], search = {} }) => { // TODO this only gets us the first page of options, we need a different endpoint for this
    const data = await this.query({ filters, scopes, search, with_total: false });
    let filterOptions = data[this.pluralModelName()].map((resource) => {
      return {
        value: _.get(resource, valueKey),
        label: _.get(resource, labelKey)
      };
    });
    return _.uniqBy(filterOptions, 'value')
  }

  get = async (idOrResource, params) => {
    const id = typeof idOrResource === 'object' ? idOrResource.id : idOrResource;
    const response = await dopeClient.get(`/${this.pluralModelName()}/${id}`, { params });
    return response.data[this.modelName];
  };

  fetchMember = async (idOrResource, memberRoute) => {
    const id = typeof idOrResource === 'object' ? idOrResource.id : idOrResource;
    const response = await dopeClient.get(`/${this.pluralModelName()}/${id}/${memberRoute}`);
    return response.data[memberRoute];
  };

  fetchCollection = async (collectionRoute, params) => {
    const response = await dopeClient.get(`/${this.pluralModelName()}/${collectionRoute}`, { params });
    return response.data[collectionRoute];
  }

  fetchChartData = async (params) => {
    return await this.fetchCollection('chart_data', params);
  }

  postMember = async (idOrResource, memberRoute, params) => {
    const id = typeof idOrResource === 'object' ? idOrResource.id : idOrResource;
    const response = await dopeClient.post(`/${this.pluralModelName()}/${id}/${memberRoute}`, params);
    return response.data[memberRoute];
  }

  uploadMember = async (idOrResource, memberRoute, params) => {
    const formData = objectToFormData(omit(params, 'id'));
    const id = typeof idOrResource === 'object' ? idOrResource.id : idOrResource;
    const response = await dopeClient.post(`/${this.pluralModelName()}/${id}/${memberRoute}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data[memberRoute];
  }

  deleteMember = async (idOrResource, memberRoute, params) => {
    const id = typeof idOrResource === 'object' ? idOrResource.id : idOrResource;
    const response = await dopeClient.delete(`/${this.pluralModelName()}/${id}/${memberRoute}`, { params });
    return response.data[memberRoute];
  }

  create = async (resource) => {
    const formData = objectToFormData(omit(resource, 'id'), this.modelName);
    const response = await dopeClient.post(`/${this.pluralModelName()}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data[this.modelName];
  };

  post = async (resource, action) => {
    const formData = objectToFormData(resource, this.modelName);
    const response = await dopeClient.post(`/${this.pluralModelName()}/${action}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data[action];
  };

  update = async (resource) => {
    const formData = objectToFormData(resource, this.modelName);
    const response = await dopeClient.put(`/${this.pluralModelName()}/${resource.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data[this.modelName];
  };

  updateMany = async (resources) => {
    const params = { updates: resources };
    const response = await dopeClient.put(`/${this.pluralModelName()}/update_many`, params);
    return response.data[this.pluralModelName()];
  };


  save = async (resource) => {
    if (resource.id && resource.id !== 'new') {
      return await this.update(resource);
    } else {
      return await this.create(resource);
    }
  };

  destroy = async (idOrResource) => {
    const id = typeof idOrResource === 'object' ? idOrResource.id : idOrResource;
    const response = await dopeClient.delete(`/${this.pluralModelName()}/${id}`);
    return response.data[this.modelName];
  };

  exportCSV = async (params, fileName) => {
    const response = await dopeClient.get(`/${this.pluralModelName()}`, { params: { ...params, export: true } });
    const blob = new Blob([response.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

}

export default DopeApi;
