import React from "react";
import DopeTabs from "../ui/DopeTabs";
import AccountsPricingTable from "./AccountsPricingTable";
import ProductStandardPricing from "../purchasing/ProductStandardPricing";

const PulsePricing = () => {

  return (
    <div className="page">
      <div className="page-header">
        <p className="header-text">Pricing</p>
      </div>

      <DopeTabs>
        <DopeTabs.Tab name="standard" label="Standard Pricing">
          <ProductStandardPricing/>
        </DopeTabs.Tab>

        <DopeTabs.Tab name="per_account" label="Per Account">
          <AccountsPricingTable/>
        </DopeTabs.Tab>
      </DopeTabs>
    </div>
  )
}

export default PulsePricing
