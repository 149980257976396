import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {Placeholder, Loader, Dropdown} from "rsuite";
import ArrowBackIcon from "@rsuite/icons/ArowBack";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";

import DopeContentPage from "../ui/DopeContentPage";
import ContactDrawerForm from "../contacts/ContactDrawerForm";
import DopeDrawer from "../ui/DopeDrawer";
import ListDrawerForm from "./ListDrawerForm";
import ListDetailsDropdown from "./ListDetailsDropdown";
import ContactListTable from "../contacts/ContactListTable";
import { TagDisplayList } from "../ui/DopeTags";

import { timestampToDateTimeStr } from "../utils/date";
import { useList } from "./listSlice";
import { useListUI } from "./listUISlice";
import { useContact } from "../contacts/contactSlice";
import { useContactUI } from "../contacts/contactUISlice";
import DopeApi from "../services/DopeApi";
import { humanize } from "../utils/railsNames";

const navLinkStyles = {
  fontWeight: 700,
  fontSize: '13px',
  paddingLeft: '20px',
  paddingTop: '20px',
  marginBottom: '-10px',
  cursor: 'pointer',
  color: '#255FDF',
  width: "fit-content",
};

const actionButtons = ({ filters, loading, setLoading }) => {
  return (
    <>
      {!loading && <Dropdown
        title='More Actions'
        placement="bottomEnd"
        noCaret={true}
        style={{ border: '1px solid #EAECEF', borderRadius: '8px', background: '#FFFFFF' }}
        menuStyle={{ marginTop: "4px", width: "350px" }}
        icon={<ArrowDownIcon />}
        className='white-dropdown-button header-6 non-bold'
      >
        <ListDetailsDropdown filters={filters} setLoading={setLoading}/>
      </Dropdown>}
    </>
  )
}

const contactListApi = new DopeApi('contact_lists');

const ListDetailsPage = () => {
  const [list, listActions] = useList();
  const [listUI, listUIActions] = useListUI();
  const [contact, contactActions] = useContact();
  const [contactUI, contactUIActions] = useContactUI();
  const [filters, setListFilters] = useState();
  const [loading, setLoading] = useState(false);

  const listFilter = {
    field: 'list_id',
    operator: '=',
    value: list.id,
  };

  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate('/lists_and_contacts');
  };

  const handleDrawerClose = () => {
    listActions.cancelListUpdate();
    listUIActions.setDrawerListId(null);
  }

  const navLink = (
    <div onClick={handlePrevious} style={navLinkStyles}>
      <ArrowBackIcon/> Back to all Contact Lists
    </div>
  );

  const subHeader = `Created ${timestampToDateTimeStr(list.created_at)}`

  const tabComponents = [];

  let listPullHeader = null;
  const isListPull = !!list.list_pull_id;
  if (isListPull) {
    const queryData = list.list_pull.query_data;
    const { data_source_icon, data_source_label, data_type } = queryData;
    const iconImg = <img src={data_source_icon} alt="icon" style={{ width: "15px", height: "15px", marginRight: 2 }}/>;
    listPullHeader = (
      <div style={{ fontSize: "12px" }}>{iconImg} {data_source_label} {humanize(data_type)} Import</div>
    );
  }

  const title = (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
      <div>{list.name}</div>
      {listPullHeader}
      <TagDisplayList
        tags={list.taggings.map(tagging => tagging.tag)}
      />
    </div>
  );

  const loadingContent = (
    <div>
      <Placeholder.Paragraph rows={8}/>
      <Loader backdrop content="Downloading List..." vertical size='lg'/>
    </div>
  );

  return (
    <>
      <DopeContentPage
        navLink={navLink}
        tabComponents={tabComponents}
        actionButtons={actionButtons({filters, loading, setLoading})}
        pageTitle={title}
        subHeader={subHeader}
        pageContent={loading ? loadingContent : <ContactListTable listFilter={listFilter} setListFilters={setListFilters}/>}
      />

      <DopeDrawer
        open={!!contactUI.drawerContactId}
        onClose={() => contactUIActions.setDrawerContactId(null)}
        header={`Edit ${contact?.full_name}`}
        bodyContent={
          <ContactDrawerForm id={contactUI.drawerContactId}/>
        }
      />

      <DopeDrawer
        open={!!listUI.drawerListId}
        onClose={handleDrawerClose}
        header={`Edit ${list?.name}`}
        bodyContent={
          <ListDrawerForm id={listUI.drawerListId}/>
        }
      />
    </>
  );
}

export default ListDetailsPage;
