import React from "react";

import Fix from "./AutomationRunActions/Fix";
import ActionResultLink from "./AutomationRunActions/ActionResultLink";
import {Approve, SendAnyways, UndoDecline} from "./AutomationRunActions/Approve";
import DopePill from "../ui/DopePill";


const statusToActionText = {
  pending: 'processing...',
};

const statusToActionComponent = {
  complete: ActionResultLink,
  error: Fix,
  invalid: Fix,
  duplicate: SendAnyways,
  needs_approval: Approve,
  declined: UndoDecline,
  throttled: SendAnyways,
  pending: null,
};

const AutomationRunActionCell = ({ rowData, reload }) => {
  if (rowData.historical_id) { // HISTORICAL PATCH
    return <DopePill text="Historical Trigger" pillType="tag" />;
  }

  const action = statusToActionText[rowData.status];
  const ActionComponent = statusToActionComponent[rowData.status];

  const content = ActionComponent ? <ActionComponent id={rowData.id} rowData={rowData} reload={reload} /> : action;

  return (
    <div>
      {content}
    </div>
  );
}

export default AutomationRunActionCell;
