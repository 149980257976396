import React from "react";
import { Form } from "rsuite";

import { useList } from "./listSlice";
import { useListUI } from "./listUISlice";
import { useDopeUI } from "../ui/dopeUISlice";

import { DopeFormGroup } from "../ui/DopeForm";
import DopeActionButton from "../ui/DopeActionButton";
import DopeErrorMessages from "../ui/DopeErrorMessages";

const formStyles = {
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "90%"
}

const ListDrawerForm = () => {
  const [list, listActions] = useList();
  const [listUI, listUIActions] = useListUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const onClose = () => {
    listActions.cancelListUpdate();
    listUIActions.setDrawerListId(null);
  }

  const handleSubmit = async () => {
    try {
      await listActions.save();
      dopeUIActions.addFlashMessage({header: `${list.name}`, body: 'was successfully updated!', type: 'success' });
      listUIActions.setDrawerListId(null);
    } catch (error) {
      dopeUIActions.addFlashMessage({header: `${list.name}`, body: `was not updated! ${error}`, type: 'error' });
    }
  }

  return (
    <Form onSubmit={handleSubmit} fluid style={formStyles}>
      <div>
        <DopeFormGroup
          input={{
            inputType: "text",
            name: "name",
            props: {
              modelName: "list",
              label: "List Name"
            }
          }}
          values={list}
          onChange={listActions.update}
        />

        <DopeFormGroup
          input={{
            inputType: "text",
            name: "description",
            props: {
              modelName: "list",
              label: "List Description",
              as: "textarea",
              rows: 3
            }
          }}
          values={list}
          onChange={listActions.update}
        />

        <DopeFormGroup
          input={{
            inputType: "tags",
            name: "taggings",
            props: { modelName: "list", label: "Tags" }
          }}
          values={list}
          onChange={listActions.update}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }} >
        <DopeActionButton
          type="button"
          primary={false}
          label="Cancel"
          onClick={() => onClose()}
          props={{ primary: false }}
        />

        <DopeActionButton
          type="submit"
          label="Save Changes"
          props={{ primary: true }}
        />
      </div>


    </Form>
  );
}

export default ListDrawerForm;