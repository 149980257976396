import React from "react";

import classNames from "classnames";

import DopeIconCard from "./DopeIconCard";

import "./DopeCardSelect.scss";

// TODO this should probs take name and send update instead of value for consistency
const DopeCardSelect = ({ value, options, onSelect, layout = 'vertical' }) => {
  const classes = classNames({ 'dope-card-select': true, [layout]: true });

  return (
    <div className={classes}>
      {options.map((option) => {
        const selected = option.value === value;
        return (
          <DopeIconCard
            key={option.value}
            icon={option.icon}
            header={option.label}
            subheader={option.description}
            selected={selected}
            disabled={option.disabled}
            onClick={() => onSelect(option.value)}
          />
        );
      })}
    </div>
  );
};

export default DopeCardSelect;
