import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useCampaign } from "./campaignSlice";
import { useCampaignUI } from "./campaignUISlice";
import authApi from "../auth/authApi";

import PurchaseSummary from "../purchasing/PurchaseSummary";

const CampaignPurchaseSummary = () => {
  const navigate = useNavigate();

  const [campaign, campaignActions] = useCampaign();
  const [campaignUI, campaignUIActions] = useCampaignUI();
  const { purchase_summary, confirmedPurchase } = campaignUI;

  useEffect(() => { // force save so purchase summary is up to date
    campaignActions.save({ actions: [{ name: 'purchase_summary' }] })
  }, []);

  const isDraft = campaign.status === "draft";
  const handleClickUpdateCard = isDraft ? () => {
    campaignActions.save()
      .then(() => {
        navigate(`/accounts/${authApi.getCurrentAccountId()}/billing`);
      });
  } : null;

  return (
    <PurchaseSummary
      purchase_summary={purchase_summary}
      onClickUpdateCard={handleClickUpdateCard}
      resourceType="campaign"
      showConfirmation={isDraft}
      confirmed={confirmedPurchase}
      onChangeConfirmed={campaignUIActions.update}
      priceHeader="Max Campaign Cost"
    />
  );
};

export default CampaignPurchaseSummary;
