import React, { useEffect } from 'react';
import { createSlice, createAsyncThunk, createSelector, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, Provider } from "react-redux";

import { API } from './IntegrationsAPI';
import wrapAll from './util/wrapAll';

const initialSubscriptionConditions = { // TODO remove default rule for queries
  id: "g1",
  rules: [
    {
      id: "r1",
      field: "~",
      operator: "=",
      valueSource: "value",
      value: ""
    }
  ],
  combinator: "and",
  not: false,
};

const initialState = {
  subscription: {
    field_to_type_map: {},
    cdm_map: {},
    conditions: initialSubscriptionConditions,
  },
  fetchingSubscription: false,
  savingSubscription: false,
  subscriptionError: null,
  dataSources: [],
  dataTypes: [],
  dataFields: [],
  dataFieldToValuesMap: {},
  operators: [],
  connections: [],
  connectionError: null,
};

const rejectWithValueWrapper = (apiFn) => async (params, { rejectWithValue }) => {
  try {
    return await apiFn(params);
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
};

const queryDataSources = createAsyncThunk('integrations/queryDataSources', API.dataSources.query);
const queryDataTypes = createAsyncThunk('integrations/queryDataTypes', API.dataSources.queryDataTypes);
const queryDataFields = createAsyncThunk('integrations/queryDataFields', API.dataSources.queryDataFields);
const fetchCDMMap = createAsyncThunk('integrations/fetchCDMMap', API.dataSources.fetchCDMMap);

const queryConnections = createAsyncThunk('integrations/queryConnections', API.connections.query);
const destroyConnection = createAsyncThunk('integrations/destroyConnection', API.connections.destroy);
const syncConnection = createAsyncThunk('integrations/syncConnection', API.connections.sync);
const createConnection = createAsyncThunk('integrations/createConnection', rejectWithValueWrapper(API.connections.create));
const queryConnectionData = createAsyncThunk('integrations/queryConnection', rejectWithValueWrapper(API.connections.queryData));

const queryOperators = createAsyncThunk('integrations/queryOperators', API.operators.query);

const fetchSubscription = createAsyncThunk('integrations/fetchSubscription', API.subscriptions.fetch);
const saveSubscription = createAsyncThunk('integrations/saveSubscription', API.subscriptions.save);

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setSubscription: (state, { payload: subscription }) => {
      state.subscription = subscription;
    },
    updateSubscription: (state, { payload: update }) => { // todo this should be named changeSubscription
      Object.assign(state.subscription, update);
    },
    setSubscriptionDataSource: (state, { payload: connection }) => {
      const dataSource = connection.data_source;
      state.subscription = {
        ...state.subscription,
        connection_id: connection.id,
        data_source: dataSource.name,
        data_source_label: dataSource.label,
        data_source_icon: dataSource.icon,
        data_type: null,
        cdm_map: {},
        field_to_type_map: {},
        conditions: initialSubscriptionConditions,
      };
    },
    setSubscriptionDataType: (state, { payload: data_type }) => {
      state.subscription = {
        ...state.subscription,
        data_type,
        cdm_map: {},
        field_to_type_map: {},
        conditions: initialSubscriptionConditions,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryDataSources.pending, (state, action) => {
        state.loadingDataSources = true;
      })
      .addCase(queryDataSources.fulfilled, (state, action) => {
        state.loadingDataSources = false;
        state.dataSources = action.payload;
      })


      .addCase(queryDataTypes.pending, (state, action) => {
        state.dataTypes = [];
        state.loadingDataTypes = true;
      })
      .addCase(queryDataTypes.fulfilled, (state, action) => {
        state.loadingDataTypes = false;
        state.dataTypes = action.payload;
      })

      .addCase(queryDataFields.pending, (state, action) => {
        state.dataFields = [];
        state.loadingDataFields = true;
      })
      .addCase(queryDataFields.fulfilled, (state, action) => {
        state.loadingDataFields = false;
        state.dataFields = action.payload;
      })


      .addCase(queryConnections.pending, (state, action) => {
        state.loadingConnections = true; // TODO should be queryingConnections
      })
      .addCase(queryConnections.fulfilled, (state, action) => {
        state.loadingConnections = false;
        state.connections = action.payload;
      })
      .addCase(queryConnections.rejected, (state, action) => {
        state.loadingConnections = false; // TODO error handling
      })

      .addCase(destroyConnection.pending, (state, action) => {
        state.destroyingConnection = true;
      })
      .addCase(destroyConnection.fulfilled, (state, action) => {
        state.destroyingConnection = false;
        state.connections = state.connections.filter((c) => c.id !== action.payload.id);
      })

      .addCase(syncConnection.pending, (state, action) => {
        state.syncingConnection = true;
      })
      .addCase(syncConnection.fulfilled, (state, action) => {
        state.syncingConnection = false;
      })

      .addCase(createConnection.pending, (state, action) => {
        state.creatingConnection = true;
        state.connectionError = null;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.creatingConnection = false;
        state.connections.push(action.payload);
      })
      .addCase(createConnection.rejected, (state, action) => {
        state.creatingConnection = false;
        state.connectionError = action.payload.error; // TODO test this...
      })
      .addCase(queryConnectionData.pending, (state, action) => {
        state.loadingConnectionData = true;
      })
      .addCase(queryConnectionData.fulfilled, (state, action) => {
        state.loadingConnectionData = false;
        state.connectionData = action.payload;
      })
      .addCase(queryConnectionData.rejected, (state, action) => {
        console.log(action.payload)
        state.loadingConnectionData = false;
        state.connectionDataError = action.payload.error; // TODO test this...
      })


      .addCase(queryOperators.pending, (state, action) => {
        state.loadingOperators = true;
      })
      .addCase(queryOperators.fulfilled, (state, action) => {
        state.loadingOperators = false;
        state.operators = action.payload;
      })

      .addCase(fetchCDMMap.pending, (state, action) => {
        state.fetchingCDMMap = true;
      })
      .addCase(fetchCDMMap.fulfilled, (state, action) => {
        state.fetchingCDMMap = false;
        state.subscription.cdm_map = { ...action.payload, ...state.subscription.cdm_map };
      })


      .addCase(fetchSubscription.pending, (state, action) => {
        state.fetchingSubscription = true;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.fetchingSubscription = false;
        state.subscription = action.payload;
      })

      .addCase(saveSubscription.pending, (state, action) => {
        state.savingSubscription = true;
      })
      .addCase(saveSubscription.fulfilled, (state, action) => {
        state.savingSubscription = false;
        state.subscription = action.payload;
      })
      .addCase(saveSubscription.rejected, (state, action) => {
        state.savingSubscription = false;
        state.subscriptionError = action.error.message; // TODO test this...
      });
  }
});

const integrationsReducer = integrationsSlice.reducer;
const integrationsActions = integrationsSlice.actions;

const integrationsStore = configureStore({
  reducer: {
    integrations: integrationsReducer,
  },
});

const IntegrationsLoader = ({ id, initialSubscription, query = false, children }) => {
  const dispatch = useDispatch();
  const fetchingSubscription = useSelector(state => state.integrations.fetchingSubscription);
  const loadedSubscription = useSelector(state => state.integrations.subscription); // TODO naming should be fetched
  const loadedSubscriptionId = loadedSubscription.id;

  const { data_source, data_type } = loadedSubscription;


  useEffect(() => {
    const shouldFetchInitial = !!id && !loadedSubscriptionId && !fetchingSubscription;
    const shouldFetchNext = !!id && !!loadedSubscriptionId && id !== loadedSubscriptionId && !fetchingSubscription;
    const shouldFetch = shouldFetchInitial || shouldFetchNext;
    const shouldReset = !id || !loadedSubscriptionId;

    if (shouldFetch) {
      dispatch(fetchSubscription(id));
    } else if (shouldReset) {
      dispatch(integrationsActions.setSubscription(initialSubscription || initialState.subscription));
    }
  }, [id, loadedSubscriptionId, fetchingSubscription]);

  useEffect(() => {
    dispatch(queryConnections());
    dispatch(queryOperators());
    dispatch(queryDataSources());
  }, []);

  useEffect(() => {
    if (data_source) {
      dispatch(queryDataTypes(data_source));
    }
  }, [data_source]);

  useEffect(() => {
    if (data_source && data_type) {
      dispatch(queryDataFields({ data_source, data_type }));
      dispatch(fetchCDMMap({ data_source, data_type, full: query }));
    }
  }, [data_source, data_type]);


  if (fetchingSubscription) { // TODO loading indicator
    return <div>Loading...</div>;
  }

  return children;
}

const IntegrationsStoreProvider = ({ children }) => {
  return (
    <Provider store={integrationsStore}>
      {children}
    </Provider>
  );
};

const IntegrationsProvider = ({ subscriptionId, initialSubscription, query = false, children }) => { // TODO rename to something like SubscriptionProvider
  return (
    <IntegrationsStoreProvider>
      <IntegrationsLoader id={subscriptionId} initialSubscription={initialSubscription} query={query}>
        {children}
      </IntegrationsLoader>
    </IntegrationsStoreProvider>
  );
};

const withIntegrationsStore = (Component) => (props) => { // TODO rename to withIntegrationsProvider
  return (
    <IntegrationsStoreProvider>
      <Component {...props} />
    </IntegrationsStoreProvider>
  );
};

const withIntegrations = (Component) => ({ subscriptionId, ...rest }) => { // TODO and then this would become withSubscriptionProvider
  return (
    <IntegrationsProvider subscriptionId={subscriptionId}>
      <Component {...rest} />
    </IntegrationsProvider>
  );
};

const dataSourcesWithConnectionSelector = createSelector(
  (state) => state.integrations.dataSources,
  (state) => state.integrations.connections,
  (dataSources, connections) => {
    return dataSources.map((ds) => {
      const connection = connections.find(c => c.data_source.name === ds.value);

      return {
        ...ds,
        connected: !!connection,
        connection_id: connection?.id,
        connection,
      };
    });
  }
);

const useIntegrations = () => {
  const dispatch = useDispatch();

  const integrations = useSelector(state => state.integrations);

  if (!integrations) {
    throw new Error('Missing IntegrationsProvider');
  }

  const dataSources = useSelector(dataSourcesWithConnectionSelector);

  const {
    updateSubscription,
    setSubscription,
    setSubscriptionDataSource,
    setSubscriptionDataType,
  } = integrationsActions;

  const actions = wrapAll({
    fetchSubscription,
    saveSubscription,
    updateSubscription,
    setSubscription,
    setSubscriptionDataSource,
    setSubscriptionDataType,

    queryDataSources,
    queryDataTypes,
    queryDataFields,
    fetchCDMMap,

    queryConnections,
    destroyConnection,
    syncConnection,
    createConnection,
    queryConnectionData,

    queryOperators,
  }, dispatch);


  return {
    ...integrations,
    dataSources,
    ...actions,
    API,
  };
};

export {
  IntegrationsStoreProvider,
  IntegrationsProvider,
  withIntegrationsStore,
  withIntegrations,
  useIntegrations,
};
