import React from "react";

import authApi from "../auth/authApi";

import { Divider } from "rsuite";
import StripeDefaultCardDisplay from "../stripe/StripeDefaultCardDisplay";
import Cents from "../ui/Cents";
import DopeLoader from "../ui/DopeLoader";
import DopeCheckbox from "../ui/DopeCheckbox";

import "./PurchaseSummary.scss";

const detailsCopy = {
  AS_YOU_GO: {
    header: "Your account is set up to “Pay as You Go”",
    body: ["Each piece of this campaign will be billed on the day of sending.", "We’ll bundle all your payments and charge you once at the end of each day."]
  },
  UPFRONT: {
    header: "Your account is set up to “Pay Upfront”",
    body: ["This is the max cost for your campaign.", "We’ll preauthorize your card and officially charge it at the end of the day with any other transactions."]
  },
  campaign: {
    subheader: "Go to Payments & Billing to see your final cost.",
  },
  automation: {
    subheader: "Go to the campaign triggered to see more details on sendings.",
  }
};

const PurchaseSummary = ({
  purchase_summary,
  header = "ESTIMATED SUMMARY",
  priceHeader = "Total Cost",
  onClickUpdateCard = null,
  resourceType,
  showConfirmation = true,
  confirmed = false,
  onChangeConfirmed,
}) => {
  if (!purchase_summary) {
    return <DopeLoader />;
  }

  const total = purchase_summary.line_items.reduce((acc, line_item) => {
    return acc + (line_item.required_credits * line_item.unit_price);
  }, 0);

  const purchaseSetting = authApi.getCurrentAccount().purchase_setting;
  const detailsHeader = detailsCopy[purchaseSetting].header;
  const detailsBody = detailsCopy[purchaseSetting].body;
  const detailsSubheader = detailsCopy[resourceType].subheader;

  const confirmationInput = showConfirmation && (
    <div className="page-content-box dark">
      <DopeCheckbox
        helpText={`I acknowledge that I am scheduling this ${resourceType} and agreeing to pay for the listed cost.  I understand that I can cancel any sending scheduled for the future and will be credited for those sendings.`}
        value={confirmed}
        name="confirmedPurchase"
        onChange={onChangeConfirmed}
      />
    </div>
  );

  return (
    <div className="split-cols">
      <div>
        <div className="header-3 margin-bottom">PAYMENT</div>

        <div className="label">Payment Details</div>
        <div className="page-content-box">
          <p className="label large bold">{detailsHeader}</p>
          <ul>
            {detailsBody.map((line, index) => <li key={index} className="label optional color-text-secondary margin-8-b">{line}</li>)}
            <li className="label optional color-text-secondary margin-8-b">Mail is discounted when purchased at larger quantities meaning your actual cost could be less than what is shown in the summary.</li>
            <li className="label optional color-text-secondary margin-8-b">If you have credits, we’ll use those first and put everything else on the card below.</li>
            <li className="label optional color-text-secondary margin-8-b">Any address we cannot fulfill will be refunded as a credit.</li>
          </ul>
        </div>

        <div className="label">Payment Method</div>
        <div className="page-content-box">
          <StripeDefaultCardDisplay onClickUpdate={onClickUpdateCard} />
        </div>

        {confirmationInput}
      </div>


      <div>
        <div className="purchase-summary page-content-box dark">
          <div className="purchase-header text-center">{header}</div>
          <div className="label larger color-text-secondary optional">This is not a receipt. {detailsSubheader}</div>

          <Divider/>

          <table>
            <thead>
            <tr>
              <th className="product-header">Item</th>
              <th>Qty.</th>
              <th>Cost</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            {purchase_summary.line_items.map((line_item, index) => {
              const total = line_item.required_credits * line_item.unit_price;
              return (
                <tr key={index}>
                  <td className="product-name">{line_item.product_name}</td>
                  <td>{line_item.required_credits}</td>
                  <td>{line_item.unit_price_str}</td>
                  <td><Cents>{total}</Cents></td>
                </tr>
              );
            })}
            </tbody>
          </table>

          <Divider/>

          <div className="split">
            <span className="purchase-header normal">{priceHeader}</span>
            <span className="purchase-header"><Cents>{total}</Cents></span>
          </div>

          <span className="label optional color-text-secondary">
            **Suppression is removed on list generation (after your campaign is scheduled). You will not be charged for the suppression.**
          </span>

        </div>
      </div>
    </div>
  );
};

export default PurchaseSummary;
