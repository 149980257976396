import React, { useEffect, useState } from "react";

import DopeLoader from "../ui/DopeLoader";

import map from "lodash/map";

import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";

import PurchaseCredits from "../purchasing/PurchaseCredits";

import "./CreditSummary.scss";

const accountApi = new DopeApi("account");

const displayNames = {
  postcard: "Postcards",
  handwritten_card: "Handwritten Cards",
  goodies: "Goodies",
  data: "Data"
}

const CreditSummary = () => {
  const [creditBalances, setCreditBalances] = useState(null);
  const [loading, setLoading] = useState(true);

  const accountId = authApi.getCurrentAccountId();

  const handleLoad = () => {
    setLoading(true);
    accountApi.get(accountId).then(({ credit_balances }) => {
      setCreditBalances(credit_balances);
      setLoading(false);
    });
  };

  useEffect(handleLoad, [accountId]);

  let content = <DopeLoader />;
  if (!loading && creditBalances) {
    content = (
      <div className="credit-summary-list">
        {
          map(creditBalances, (balance, creditType) => {
            return (
              <div key={creditType} className="credit-summary-item">
                <div className="credit-summary-type">{displayNames[creditType] ? displayNames[creditType] : creditType}</div>
                <div className="credit-summary-amount">{balance}</div>
              </div>
            );
          })
        }
      </div>
    );
  }

  return (
    <div className="credit-summary">
      <div className="header-3">Credits</div>
      {content}
      <PurchaseCredits onSuccess={handleLoad} />
    </div>
  );
};

export default CreditSummary;
