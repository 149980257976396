import React, { useState, useEffect } from "react";
import { Drawer } from "rsuite";
import { MdAdd } from "react-icons/md";

import DopeApi from "../services/DopeApi";

import { DopeFormGroup } from "../ui/DopeForm";
import DopeButton from "../ui/DopeButton";


const filterApi = new DopeApi("data_axle_filter");

const AudienceFiltersInput = ({ onUpdate, onRemove, values, buttonClass = "text-button float-right" }) => { // values here is listGenerationSetting
  const [filters, setFilters] = useState([]);
  const [open, setOpen] = useState(false);

  const audienceScope = {
    residential: "people",
    all_residential: "people",
    commercial: "places",
  }[values.audience_type];

  useEffect(() => { // TODO minor loader
    filterApi.query({ scopes: [{ name: "active" }, { name: audienceScope }], sort_direction: 'asc' })
      .then(data => {
        setFilters(data.data_axle_filters);
      })
      .catch(error => console.log(error));
  }, [audienceScope]);

  const handleInputChange = (update) => {
    onUpdate(update);
  };

  const handleInputRemove = (filterKey) => {
    onRemove(filterKey);
  };

  const filterInputs = filters.map(filter => {
    const inputConfig =  {
      inputType: 'data_axle_filter',
      name: 'data_axle_filters',
      customInput: 'DataAxleFilterInputs',
      props: {
        inputType: filter.data_type,
        label: filter.display_name,
        helperText: filter.description,
        labelKey: filter.filter_attribute,
        filter: filter,
        values,
        onChange: handleInputChange,
        onRemove: handleInputRemove,
      }
    };

    return <DopeFormGroup key={filter.id} input={inputConfig} values={values} onChange={handleInputChange} />;
  });

  return (
    <>
      <DopeButton
        props={{
          buttonClass: buttonClass,
          label: "Add more filters",
          onClick: () => setOpen(true),
        }}
        icon={<MdAdd style={{fontSize: "16px", marginRight: "4px"}} />}
      />


      <Drawer
        size="sm"
        placement="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Drawer.Header style={{ borderBottom: "none"}}>
          <h4 className="header-4 margin-4-t">How else do you want to narrow down your audience?</h4>
        </Drawer.Header>
        <Drawer.Body style={{paddingTop: 4}}>
          <div className="rs-form">
            {filterInputs}
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default AudienceFiltersInput;
