import React from "react";
import { useOutletContext } from "react-router-dom";

import { useAutomation } from "./automationSlice";

import DopePageStepHeader from "../ui/DopePageStepHeader";
import DopeSelect from "../ui/DopeSelect";
import DopeTextInput from "../ui/DopeTextInput";
import { DopeFormGroup } from "../ui/DopeForm";
import AutomationDispatchTemplates from "./AutomationDispatchTemplates";
import AudienceFiltersInput from "../campaigns/AudienceFiltersInput";
import CampaignListsSelect from "../campaigns/CampaignListsSelect";
import { FiltersSummary } from "../campaigns/CampaignFiltersSummary";
import SubscriptionSummary from "../integrations/Subscriptions/SubscriptionSummary";
import { Radio, RadioGroup } from "rsuite";

const audienceTypeOptions = [
  { value: 'list', label: 'Direct Sending', helpText: '1 card to 1 address', icon: 'mail' },
  { value: 'blitz', label: 'Neighborhood Blitz', helpText: 'Mail to X number of neighbors of your clients', icon: 'house' },
];

const suppressPreviousSendsInput = {
  inputType: "checkbox",
  name: "suppress_previous_sends",
  props: {
    headerText: "Filter out anyone you've sent to?",
    size: "sm",
  },
};

const metersPerMile = 1609; // max distance options should be a shared constant

const maxDistanceOptions = [
  { label: "3 miles", value: 3 * metersPerMile },
  { label: "5 miles", value: 5 * metersPerMile },
  { label: "10 miles", value: 10 * metersPerMile },
  { label: "25 miles", value: 25 * metersPerMile },
  { label: "50 miles", value: 50 * metersPerMile },
  { label: "100 miles", value: 100 * metersPerMile },
];

const inputStyle = { width: 150 };

const AutomationCampaignTemplate = () => {
  const { automation, derivedAutomationAttrs, actions } = useAutomation();
  const { isNativeTrigger } = derivedAutomationAttrs;
  const { pageTabs } = useOutletContext();

  const listGenerationSetting = automation.campaign_template.list_generation_setting;

  const isBlitz = listGenerationSetting.generation_type === 'blitz';

  const blitzSettings = isBlitz && (
    <>
      <div className="label large bold margin-top margin-4-b">How many neighbors do you want to send to?</div>
      <div className="vertical-align gap-10">
        <DopeTextInput
          name="max_contacts_per_generation"
          value={listGenerationSetting.max_contacts_per_generation}
          onChange={actions.updateListGenerationSetting}
          type="number"
          style={inputStyle}
        />
      </div>

      <div className="label large bold margin-top margin-4-b">At what distance should we should cap our search?</div>
      <div className="vertical-align gap-10">
        <DopeSelect
          name="max_distance"
          value={listGenerationSetting.max_distance}
          onChange={actions.updateListGenerationSetting}
          options={maxDistanceOptions}
          style={inputStyle}
          searchable={false}
        />
      </div>

      <div className="label large bold margin-top">Do you want to filter out certain neighbors?</div>
      <AudienceFiltersInput
        onUpdate={actions.updateDataAxleFilters}
        onRemove={actions.removeDataAxleFilter}
        values={listGenerationSetting}
        buttonClass="outlined-black float-none margin-4-t margin-4-b"
      />
      <div>
        <FiltersSummary
          filters={listGenerationSetting.data_axle_filters}
          onRemove={actions.removeDataAxleFilter}
        />
      </div>
    </>
  );

  return (
    <div>
      <div className="page">
        {pageTabs}
        <div className="page-header">
          <p className="header-text">Define what happens after your trigger</p>
          <p className="help-text">Choose what we should send, and to who, after your trigger is fired.</p>
        </div>

        {isNativeTrigger && (<SubscriptionSummary subscriptionId={automation.subscription_id} />)}

        <DopePageStepHeader
          step="1"
          headerText="Add Mailers to your campaign"
          helpText="Browse your designs and select which mailers should go out when."
        />

        <AutomationDispatchTemplates />
      </div>

      <div className="page">
        <DopePageStepHeader
          step="2"
          headerText="What type of automation are we creating, who should your campaign go to?"
          helpText="When your automation is triggered, select who should receive your campaign."
        />

        {/*<DopeCardSelect*/}
        {/*  options={audienceTypeOptions}*/}
        {/*  value={listGenerationSetting.generation_type}*/}
        {/*  onSelect={value => actions.updateListGenerationSetting({ generation_type: value })}*/}
        {/*  layout="horizontal"*/}
        {/*/>*/}

        <RadioGroup value={listGenerationSetting.generation_type} onChange={value => actions.updateListGenerationSetting({ generation_type: value })} >
          {audienceTypeOptions.map((option) => (
            <Radio value={option.value} key={option.value} >
              <div className="header-6 line-height-1 margin-4-b">{option.label}</div>
              <div className="label large label-blue-grey">{option.helpText}</div>
            </Radio>
          ))}
        </RadioGroup>

        {blitzSettings}

      </div>

      <div className="page">
        <DopePageStepHeader
          step="3"
          headerText="Let’s refine your audience. Should we add or remove people from this campaign?"
          helpText="When your automation is triggered, select who should receive your campaign."
        />

        <div className="page-panel rs-form">
          <div className="header-5 margin-bottom">Refine Your Audience</div>

          <DopeFormGroup
            input={suppressPreviousSendsInput}
            values={listGenerationSetting}
            onChange={actions.updateListGenerationSetting}
          />

          <CampaignListsSelect
            headerText="Add suppression logic to make sure certain people don't receive this campaign?"
            name="list_generation_setting_suppression_lists"
            values={listGenerationSetting}
            onChange={actions.updateListGenerationSetting}
          />
        </div>
      </div>
    </div>
  );
};

export default AutomationCampaignTemplate;
