import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import { asNestedDestroyable } from "../services/createModelSelectors";

const [designSlice, asyncActions] = createModelSlice('design', {
  updateMailTemplate: ({ current: design }, { payload: update }) => {
    Object.assign(design.mail_template, update);
  },
});

export default designSlice.reducer;

export const {
  resetToInitial,
} = designSlice.actions;

const designToParams = (design, prevDesign) => {
  let mail_template_params = {};

  if (design.mail_template_type === 'HandwrittenCardTemplate') {
    const { mail_template } = design;

    const { cover_image_type, header_image_type, footer_image_type, ...rest } = mail_template;

    const mail_template_attributes = {
      ...rest,
    };

    mail_template_params = { mail_template_attributes };
  } else if (design.mail_template_type === 'PostcardTemplate') {
    const { mail_template } = design;

    const mail_template_attributes = {
      ...mail_template,
    };

    mail_template_params = { mail_template_attributes };
  } else if (design.mail_template_type === 'GoodieTemplate') {
    const { mail_template } = design;

    const mail_template_attributes = {
      ...mail_template,
    };

    mail_template_params = { mail_template_attributes };
  }

  return {
    ...design,
    ...asNestedDestroyable(prevDesign, design, 'taggings'),
    ...mail_template_params,
  };
};

const designSelector = state => {
  return state.design.current;
}

const derivedDesignAttrsSelector = createSelector([designSelector], (design) => {
  if (!design) return {};

  const derivedUpdates = {};

  derivedUpdates.tags = design.taggings?.map(({ tag }) => tag) ?? [];

  return derivedUpdates;
});

export function useDesign(designId) {
  const dispatch = useDispatch();
  const design = useSelector(designSelector);
  const derivedDesignAttrs = useSelector(derivedDesignAttrsSelector);
  const prevDesign = useSelector(state => state.design.prev);
  const isSaving = useSelector(state => state.design.isSaving);

  const actions = wrapAll({
    ...designSlice.actions,
    ...asyncActions,
    resetToInitial: resetToInitial,
    save: (additionalParams = {}, mailTemplateParams={}) => {
      const { files, json_template, ...extras } = additionalParams;
      const { mail_template, design_versions, activity_histories, product_name, ...params } = { ...designToParams(design, prevDesign), ...extras };

      const mailTemplate = { ...mail_template, ...(files ?? {}), json_template: json_template ?? null };


      delete params.errors;

      return asyncActions.save({...params, mail_template_attributes: mailTemplate });
    },
    approve: () => {
      return asyncActions.save({ status: 'approved', id: design.id });
    },
    requestRevision: () => {
      return asyncActions.save({ status: 'in_design', id: design.id });
    },
    restoreForRevision: () => {
      return asyncActions.save({ status: 'needs_review', id: design.id });
    },
    archive: () => {
      return asyncActions.save({ status: 'archived', id: design.id });
    },
    addTagsToDesign: (tags) => {
      const params = {
        id: design.id,
        ...asNestedDestroyable(prevDesign, {...prevDesign, taggings: tags.taggings}, 'taggings'),
      }
      return asyncActions.save(params);
    }
  }, dispatch);

  useEffect(() => {
    const shouldGet = designId && (!design || design.id !== designId);
    if (shouldGet) {
      actions.get(designId);
    }
  }, [designId, design?.id]);



  return {
    design,
    derivedDesignAttrs,
    actions,
    isSaving,
  };
};

