import React from "react";

// https://nivo.rocks/bar/
import { ResponsiveBar } from '@nivo/bar'

const mailTypeKeyToLabelMap = {
  postcard: "Postcards",
  handwritten: "Handwritten",
  goodies: "Goodies",
};

const mailerTypes = Object.keys(mailTypeKeyToLabelMap);

const tooltipStyles = {
  wrapper: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "15px",
    minWidth: "225px",
    boxShadow: "0px 6px 6px 0px rgba(186,186,186,1)",
  },
  header: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "15px",
  },
  item: {
    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#000000",
      margin: "5px 0"
    },
    type: {
      fontSize: "14px",
      fontWeight: 300,
    },
    count: {
      fontSize: "16px",
      fontWeight: 600,
    },
  }
};

const MailingsGraph = ({ data }) => {
  return (
    <ResponsiveBar
      colors={{ scheme: 'red_yellow_green' }}
      data={data}
      indexBy="week"
      keys={mailerTypes}
      margin={{top: 50, right: 130, bottom: 50, left: 60}}
      padding={0.3}
      groupMode="stacked"
      valueScale={{type: 'linear'}}
      indexScale={{type: 'band', round: true}}
      theme={{
        axis: {
          legend: {
            text: {
              fontSize: "14px",
              fontWeight: 600,
              fill: "#8793A6"
            }
          },
          ticks: {
            line: {
              strokeWidth: "0px",
            }
          },
        },
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1.6
          ]
        ]
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role="application"
      ariaLabel="Recent Mailers History"
      barAriaLabel={e => +e.formattedValue + " mailers"}
      tooltip={({ data }) => {
        return (
          <div style={tooltipStyles.wrapper}>
            <div style={tooltipStyles.header}>Mailer Breakdown</div>
            {
              mailerTypes.map((type, i) => (
                <div key={type} style={tooltipStyles.item.wrapper}>
                  <div style={tooltipStyles.item.type}>{mailTypeKeyToLabelMap[type]}</div>
                  <div style={tooltipStyles.item.count}>{data[type] || 0}</div>
                </div>
              ))
            }
          </div>
        )
      }}
    />
  );
}

export default MailingsGraph;
