import React, { useState, useEffect } from "react";
import moment from "moment";
import compact from "lodash/compact";
import uniqBy from "lodash/uniqBy";
import { DateRangePicker, Dropdown, Checkbox, InputGroup, Input, IconButton } from "rsuite";
import { Icon, Close } from "@rsuite/icons";
import { MdOutlineArrowDropDown, MdOutlineClear, MdOutlineSearch } from "react-icons/md";

const DopeSearchInput = ({ value, onChange }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: "auto" }}>
      <InputGroup size="sm" style={{ width: "300px" }}>

        <Input onChange={value => onChange(value)} value={value} placeholder="Search"/>

        {value && value.length > 0 ? (
          <InputGroup.Addon onClick={() => onChange('')} className="clickable">
            <Icon as={MdOutlineClear} />
          </InputGroup.Addon>
        ) : (
          <InputGroup.Addon>
            <Icon as={MdOutlineSearch} />
          </InputGroup.Addon>
        )}

      </InputGroup>
    </div>
  );
};

const DopeDateRangeFilter = ({ filterInput, filters, setFilters }) => {
  const { title, name, field, type, ...rest } = filterInput;

  const myFilters = filters.filter(f => f.name === name);
  const startDateFilter = myFilters.find(f => f.operator === '>=') || {};
  const endDateFilter = myFilters.find(f => f.operator === '<=') || {};
  const value = compact([startDateFilter.value, endDateFilter.value]);

  const handleChange = (rangeValues) => {
    const isClear = !rangeValues;
    let nextFilters = filters.filter(f => f.name !== name);

    if (!isClear) {
      const [startDate, endDate] = rangeValues;
      const newFilters = [
        { name, field, operator: '>=', value: startDate },
        { name, field, operator: '<=', value: moment(endDate).endOf('day').toDate() },
      ];
      nextFilters = [...nextFilters, ...newFilters];
    }

    setFilters(nextFilters);
  };

  return (
    <DateRangePicker
      onChange={handleChange}
      value={value}
      placeholder="Select Date Range"
      size="sm"
      character=" – "
      format="M/dd/yy"
      {...rest}
    />
  );
};

const optionsListStyles = { width: 400, maxHeight: 300, overflowY: 'auto' };

const DopeMultiSelectFilter = ({ filterInput, filters, setFilters }) => {
  const { title, name, field, type, getOptions, searchable, valueKey = "value", labelKey = "label", ...rest } = filterInput;
  const filter = filters.find(f => f.name === name) || { value: [] };
  const selectedCount = filter.value.length;
  const active = selectedCount > 0;

  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const selectedOptions = allOptions.filter(option => filter.value.includes(option[valueKey]));
  const viewOptions = uniqBy([...options, ...selectedOptions], valueKey);

  const handleFetch = async () => {
    setLoading(true);
    const options = await getOptions(searchValue);
    setOptions(options);
    setAllOptions(uniqBy([...allOptions, ...options], valueKey));
    setLoading(false);
  };

  const handleChange = (option, value, checked) => {
    let nextValue;
    if (checked) {
      nextValue = [...filter.value, value];
    } else {
      nextValue = filter.value.filter(v => v !== value);
    }
    let nextFilters = filters.filter(f => f.name !== name);
    if (nextValue.length > 0) {
      nextFilters = [...nextFilters, { name, field, operator: "in", value: nextValue }];
    }
    setFilters(nextFilters);
  };

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilters(filters.filter(f => f.name !== name));
  };

  useEffect(() => {
    handleFetch();
  }, [searchValue]);

  const renderOption = (option) => {
    const checked = filter.value.includes(option[valueKey]);
    return (
      <div className="pad-row sm border-top" key={option[valueKey]}>
        <Checkbox
          checked={checked}
          onChange={() => handleChange(option, option.value, !checked)}
        >
          {option[labelKey]}
        </Checkbox>
      </div>
    );
  };

  const optionItems = viewOptions.map(renderOption);

  const optionsList = (
    <div style={optionsListStyles}>
      {optionItems}
    </div>
  );

  const searchInput = searchable && (
    <div className="margin-16">
      <Input
        value={searchValue}
        onChange={setSearchValue}
        placeholder="Search"
      />
    </div>
  );

  const clearButton = active && (
    <IconButton
      icon={<Close />}
      circle
      size="xs"
      onClick={handleClear}
      appearance="subtle"
      style={{ marginRight: "6px"}}
    />
  );

  const menu = (
    <div>
      {searchInput}
      {optionsList}
    </div>
  );

  const dropdownTitle = (
    <>
      {clearButton}
      {title}
      {active && ` (${selectedCount})`}
      <Icon as={MdOutlineArrowDropDown} style={{ fontSize: "20px", color: "#494B4E", marginLeft: "6px"}} />
    </>
  );

  return (
    <Dropdown
      title={dropdownTitle}
      toggleAs="div"
      className="dope-filter-dropdown"
      {...rest}
    >
      {menu}
    </Dropdown>
  );
};

const typeToComponent = {
  DateRange: DopeDateRangeFilter,
  MultiSelect: DopeMultiSelectFilter,
};

const DopeFilter = ({ filterInput, filters, setFilters }) => {
  const FilterComponent = typeToComponent[filterInput.type];
  if (!FilterComponent) return null;

  return <FilterComponent filterInput={filterInput} filters={filters} setFilters={setFilters} />;
};

const DopeFilters = ({ filterInputs, filters, setFilters, searchText, onSearchTextChange = null }) => {
  const filterItems = filterInputs.map((filterInput, i) => (
    <DopeFilter key={i} filterInput={filterInput} filters={filters} setFilters={setFilters} />
  ));

  const search = onSearchTextChange && (
    <DopeSearchInput value={searchText} onChange={onSearchTextChange} />
  );

  return (
    <div className="vertical-align margin-4-b gap-xs">
      {filterItems}
      {search}
    </div>
  );
};

export default DopeFilters;
