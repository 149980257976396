import React, { useEffect, useState } from "react";
import {unixTimestampToDateStr} from "../utils/date";
import DopePlaceholder from "../ui/DopePlaceholder";
import InvoiceSummaryTable from "./InvoiceSummaryTable";
import InvoiceTransactionsTable from "./InvoiceTransactionsTable";
import InvoiceSubscriptionTable from "./InvoiceSubscriptionTable";
import DopeActionButton from "../ui/DopeActionButton";
import { useDopeUI } from "../ui/dopeUISlice";
import StripeApi from "./StripeApi";
import DopeButton from "../ui/DopeButton";

const stripeApi = new StripeApi;

const trimString = (str, length) => {
  let splitStr = str.split(" × ")
  let preTrimmedStr = splitStr[splitStr.length - 1];
  if (preTrimmedStr.length <= length) {
    return preTrimmedStr;
  }

  return preTrimmedStr.substring(0, length) + "...";
}

const InvoiceDrawer = ({ id, account, onClose }) => {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [invoice, setInvoice] = useState(null);
  const [payment, setPayment] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      const stripeData = await stripeApi.get(`show_stripe_invoice/${id}`);
      setInvoice(stripeData.invoice);
      if (stripeData.stripe_payment) setPayment(stripeData.stripe_payment);
      stripeData.invoice.lines.data.forEach((line_item) => {
        if (line_item.type === "subscription") {
          setSubscription(line_item);
        }
      });
    }

    fetchInvoice();
  }, []);

  const payInvoice = async () => {
    try {
      await stripeApi.post(`pay_stripe_invoice/${id}`);
      dopeUIActions.addFlashMessage({ header: "Payment Successful", body: "Your payment has been processed", type: "success"});
      onClose();
    } catch (err) {
      dopeUIActions.addFlashMessage({ header: "Payment Form Rejected", body: "Please update your default payment method", type: "error" });
    }
  }

  return (
    <>
      {invoice ? (
        <div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            {account && <div style={{fontWeight: 700, fontSize: "11px", color: "#8793A6"}}>
              <h5 style={{fontWeight: 600, fontSize: "20px", color: "#292B2E"}}>{account.name}</h5>
              <div>{account.billing_email}</div>
              <div>{account.address_1}</div>
              {account.city && <div>{account.city}, {account.state} {account.zip}</div>}
            </div>}

            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
              <div style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6"}}>{unixTimestampToDateStr(invoice.created, { month: 'long', day: 'numeric', year: 'numeric' })}</div>
              <div style={{ fontWeight: 600, fontSize: "16px", color: "#8793A6"}}>{invoice.number}</div>
            </div>
          </div>

          {subscription && <InvoiceSubscriptionTable subscription={subscription}/>}
          <InvoiceSummaryTable invoice={invoice} trimString={trimString} />
          <InvoiceTransactionsTable payment={payment} subscription={subscription} />

          <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <DopeButton
              props={{
                onClick: onClose,
                label: "Close",
                buttonClass: "outlined-red",
                styles: { width: "127px"}
              }}
            />
            {/*{ invoice.status === "open" && <DopeActionButton props={{ primary: true }} onClick={payInvoice} label="Pay Invoice" />}*/}
          </div>
        </div>
      ) : (
        <DopePlaceholder type={"paragraph"} rows={10} />
      )}
    </>
  );
}

export default InvoiceDrawer;
