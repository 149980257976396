import React from "react";
import { useOutletContext } from "react-router-dom";

import { useAutomation } from "./automationSlice";

import { DopeFormGroup } from "../ui/DopeForm";
import DopePlayer from "../ui/DopePlayer";

const nameInput = { inputType: "text", name: "name", props: { label: "Automation Name" } };
const descriptionInput = { inputType: "text", name: "description", props: { label: "Description", as: "textarea", rows: 3 } };
const tagsInput = { inputType: "tags", name: "taggings", props: { label: "Tags", modelName: "automation" } };

const videoUrl = "https://dopemail-assets.s3.amazonaws.com/Streamlining+Direct+Mail+Campaigns+with+Dope+Automation.mp4" ;

const AutomationSetup = () => {
  const { automation, actions } = useAutomation();
  const { pageTabs } = useOutletContext();

  const inputProps = { values: automation, onChange: actions.update, errors: automation.errors };

  return (
    <div className="page">
      {pageTabs}
      <div className="page-header">
        <p className="header-text">Automation setup</p>
        <p className="help-text">Name your automation, give it a description, and add tags to help organize your automations!</p>
      </div>

      <div className="split-cols rs-form">
        <div>
          <DopeFormGroup input={nameInput} {...inputProps} />
          <DopeFormGroup input={descriptionInput} {...inputProps} />
          <DopeFormGroup input={tagsInput} {...inputProps} />
        </div>

        <div>
          <DopePlayer url={videoUrl} />
        </div>
      </div>
    </div>
  );
};

export default AutomationSetup;
