import React from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";

import DopeListPage from "../ui/DopeListPage";

import AutomationRunActionCell from "./AutomationRunActionCell";
import {handleFilterChange} from "../utils/fn";

const automationRunApi = new DopeApi('automation_run');
const automationApi = new DopeApi('automation');

const columns = [
  { type: 'event_time', dataKey: 'created_at', helpTextKey: 'automation_name', label: 'Trigger Date', sortable: true },
  { type: 'text', dataKey: 'full_name', label: 'Contact Name' },
  { type: 'address_text', dataKey: 'address_1', label: 'Address' },
  { type: 'pill', pillTypeKey: 'status', dataKey: 'status', label: 'Status' },
  { type: 'custom_component', label: 'Actions', Component: AutomationRunActionCell },
];

const statusOptions = [
  { value: 'error', label: 'Error' },
  { value: 'invalid', label: 'Invalid' },
  { value: 'duplicate', label: 'Duplicate' },
  { value: 'needs_approval', label: 'Needs Approval' },
  { value: 'pending', label: 'Pending' },
  { value: 'complete', label: 'Complete' },
  { value: 'throttled', label: 'Throttled' },
  { value: 'declined', label: 'Declined' },
];


const AllAutomationRunsTable = () => {
  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters }
    = useQuery2({
      api: automationRunApi,
      initialSearchScope: "action_params",
    });

  const applyStatusFilter = (statuses) => {
    const statusFilter = {
      filterField: 'automation_runs.status',
      operator: 'in',
      filterCategory: 'status',
    };

    handleFilterChange(filters, setFilters, statuses, statusFilter);
  };

  const applyAutomationFilter = (automationIds) => {
    const automationFilter = {
      filterField: 'automation_id',
      operator: 'in',
      filterCategory: 'automation',
    };

    handleFilterChange(filters, setFilters, automationIds, automationFilter);
  }

  const filterInputs = [
    { title: "Status", getOptions: () => statusOptions, applyFilter: applyStatusFilter, stateValue: 'statuses' },
    { title: "Automation",
      getOptions: (name = "") => automationApi.getOptions({
        valueKey: 'id',
        labelKey: 'name',
        search: { text: name, column: 'name' },
      }),
      applyFilter: applyAutomationFilter
    }
  ];

  return (
    <div>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        filters={filters}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
      />
    </div>
  );
};

export default AllAutomationRunsTable;
