import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from "react-redux";

import { saveCampaign, getCampaignPurchaseSummary } from './campaignSlice';

const initialState = {
  hoveringGeoShapeId: null,
  filtersDrawerOpen: false,
  saving: false,
  purchase_summary: null,
  confirmedPurchase: false,
};

export const campaignUISlice = createSlice({
  name: 'campaignUI',
  initialState,
  reducers: {
    updateCampaignUI: (campaignUI, { payload: update }) => {
      Object.assign(campaignUI, update);
    },
    setHoveringGeoShapeId: (campaignUI, { payload: id }) => {
      campaignUI.hoveringGeoShapeId = id;
    },
    resetToInitial: (campaignUI) => {
      Object.assign(campaignUI, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCampaign.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveCampaign.fulfilled, (state, action) => {
        state.saving = false;

        if (!!action.payload.action_results?.purchase_summary) { // adding here so doesnt get removed from random draft save
          state.purchase_summary = action.payload.action_results.purchase_summary;
        }
      })
      .addCase(saveCampaign.rejected, (state) => {
        state.saving = false;
      })
  }
});

export default campaignUISlice.reducer;

export const {
  updateCampaignUI,
  setHoveringGeoShapeId,
  resetToInitial,
} = campaignUISlice.actions;

export const selectCampaignUI = (state) => state.campaignUI;

export function useCampaignUI() {
  const dispatch = useDispatch();
  const campaignUI = useSelector(selectCampaignUI);
  const actions = {
    update: (payload) => dispatch(updateCampaignUI(payload)),
    setHoveringGeoShapeId: (id) => dispatch(setHoveringGeoShapeId(id)),
    resetToInitial: () => dispatch(resetToInitial()),
  };

  return [campaignUI, actions];
}


