import React from "react";
import { useCampaignListGenerationSettings } from "./campaignSlice";

import { DopeFormGroup } from "../ui/DopeForm";
import CampaignListsSelect from "./CampaignListsSelect";

const ExtraMailRules = () => {
  const [settings, actions] = useCampaignListGenerationSettings();
  const { generation_type } = settings;
  const isListCampaign = generation_type === "list";

  let suppressStartingInput = null;
  if (!isListCampaign) {
    suppressStartingInput = (
      <DopeFormGroup
        input={{ inputType: "checkbox", name: "suppress_starting", props: {
          headerText: "Suppress starting address",
          size: "sm",
          className: "non-bold",
        }}}
        values={settings}
        onChange={actions.update}
        errors={settings.errors}
      />
    );
  }

  return (
    <div className="page-panel rs-form">
      <p className="header-text">Refine your audience</p>

      {suppressStartingInput}

      <CampaignListsSelect
        headerText="Add seeding to this mailing by adding additional contacts or lists?"
        name="list_generation_setting_seed_lists"
        values={settings}
        onChange={actions.update}
      />

      <CampaignListsSelect
        headerText="Add suppression logic to make sure certain people don’t receive this campaign?"
        name="list_generation_setting_suppression_lists"
        values={settings}
        onChange={actions.update}
      />
    </div>
  );
};

export default ExtraMailRules;
