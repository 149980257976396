import React, { useEffect } from 'react';
import { useParams, useLocation, useNavigate, Outlet, matchPath } from 'react-router-dom';
import { useCampaign } from './campaignSlice';
import { useCampaignUI } from './campaignUISlice';
import { useDopeUI } from '../ui/dopeUISlice';
import { toDateString } from "../utils/date";
import useDopePageTabs from '../ui/useDopePageTabs';
import DopePageLoader from '../ui/DopePageLoader';
import { Button } from 'rsuite';
import DopeButton from "../ui/DopeButton";

import './Campaign.scss';

const campaignPages = [
  {
    name: 'Campaign setup',
    path: '/campaigns/:id/details',
    isValid: (campaign) => {
      const hasName = !!campaign.name;
      const isListBlitz = campaign.list_generation_setting.generation_type === 'list_blitz';

      if (isListBlitz) {
        return hasName && !!campaign.list_generation_setting.starting_list_id;
      }

      return hasName;
    },
  },
  {
    name: 'Generate mailing list',
    path: '/campaigns/:id/audience',
    isValid: (campaign) => {
      const isListBlitz = campaign.list_generation_setting.generation_type === 'list_blitz';
      if (isListBlitz) {
        return !!campaign.list_generation_setting.starting_list_id;
      }

      const { latitude, longitude, address_1, zip } = campaign.list_generation_setting;
      const hasStartingAddress = !!latitude && !!longitude && !!address_1 && !!zip;
      const hasShape = !!campaign.list_generation_setting.geo_shapes.length;

      return hasStartingAddress && hasShape;
    },
  },
  {
    name: 'Build your campaign',
    path: '/campaigns/:id/mailers',
    isValid: (campaign) => {
      return !!campaign.dispatches.length;
    },
  },
  {
    name: 'Review campaign',
    path: '/campaigns/:id/schedule',
    isValid: (campaign) => {
      const hasDispatches = !!campaign.dispatches.length;
      const noDispatchesAreInPast = campaign.dispatches.every(d => d.date >= toDateString(new Date()));

      return hasDispatches && noDispatchesAreInPast;
    },
  }
];

const listCampaignPages = [
  {
    name: 'Campaign Setup',
    path: '/campaigns/:id/details',
    isValid: (campaign) => {
      const hasName = !!campaign.name;
      const hasStartingList = !!campaign.list_generation_setting.starting_list_id;

      return hasName && (hasStartingList || campaign.is_automation);
    },
  },
  {
    name: 'Build your campaign',
    path: '/campaigns/:id/mailers',
    isValid: (campaign) => {
      return !!campaign.dispatches.length;
    }
  },
  {
    name: 'Review campaign',
    path: '/campaigns/:id/schedule',
    isValid: (campaign) => {
      return !!campaign.dispatches.length;
    },
  }
];

const getPages = (campaign) => {
  const isListOnly = campaign.list_generation_setting.generation_type === 'list';
  return isListOnly ? listCampaignPages : campaignPages;
};

const CampaignPages = () => {
  const [campaign, actions] = useCampaign();
  const [ui] = useCampaignUI();
  const [dopeUI, { addFlashMessage }] = useDopeUI();
  const navigate = useNavigate();

  const pages = getPages(campaign);

  const {
    PageTabs,
    BackLink,
    navigateForward,
    isLastPage,
    isSecondToLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: campaign, exitUrl: '/campaigns', exitText: 'Back to all Campaigns' });

  const nextDisabled = !isValid;

  const continueButton = (
    <DopeButton
      disabled={nextDisabled}
      className="next-btn"
      props={{
        buttonClass: 'filled',
        label: isSecondToLastPage ? 'Save & Review' : 'Next',
        onClick: navigateForward,
      }}
    />
  );

  const handleSchedule = () => {
    actions.schedule()
      .then(actionResult => {
        const campaign = actionResult.payload;
        const scheduleResults = campaign.action_results.schedule;

        if (scheduleResults.success) {
          navigate(`/campaigns`);
        } else {
          scheduleResults.error_messages.forEach(body => addFlashMessage({ body, timeout: 10000, type: 'error' }));
        }
      });
  };

  const scheduleButton = (
    <DopeButton
      disabled={nextDisabled || !ui.confirmedPurchase}
      loading={ui.saving}
      className="next-btn"
      props={{
        buttonClass: 'filled',
        label: 'Schedule Campaign',
        onClick: handleSchedule,
      }}
    />
  );

  const nextButton = isLastPage ? scheduleButton : continueButton;

  const withActions = !campaign.scheduled;

  const actionsElement = withActions && (
    <div className="footer-actions margin-bottom">
      <Button className="save-btn" appearance="subtle" onClick={() => actions.save()} loading={ui.saving}>Save as draft</Button>
      {nextButton}
    </div>
  );

  const pageTabs = <PageTabs />;

  return (
    <div className='campaign-page-wrapper'>

      <BackLink className="margin" />

      <div className='campaign-page'>
        <div>

          <Outlet context={{pageTabs}} />

          {actionsElement}

        </div>
      </div>

    </div>
  );
}

const Campaign = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [campaign, actions] = useCampaign();
  const [ui, uiActions] = useCampaignUI();

  useEffect(() => {
    if (!campaign) {
      actions.get(id + location.search)
        .then(actionResult => {
          const camp = actionResult.payload;
          const shouldDirectToFormPage = camp.status !== "scheduled" && camp.status !== "queued" && camp.status !== "generating";
          if (!camp.scheduled) {
            const pages = getPages(camp);
            const firstInvalidPage = pages.find(page => !page.isValid(camp));
            const toPage = firstInvalidPage ? firstInvalidPage.path : pages[pages.length - 1].path;
            const to = toPage.replace(':id', id);
            navigate(to);
          }
        });
    }

    return () => {
      uiActions.resetToInitial();
      actions.resetToInitial();
    };
  }, []);

  if (!campaign) {
    return (<DopePageLoader />);
  }

  const isProcessing = campaign.status === 'generating' || campaign.status === 'queued';
  if (isProcessing) {
    return (
      <div className="page header-5 text-center">
        Campaign {campaign.name} is processing. Please check back later.
      </div>
    );
  }

  const pages = getPages(campaign);
  const isFormPath = pages.some(page => matchPath({ path: page.path }, location.pathname));

  return isFormPath ? (<CampaignPages />) : (<Outlet />);
};

export default Campaign;
