import React, { useEffect } from "react";

import classNames from "classnames";
import { useLocation, useNavigate, matchPath, useParams } from 'react-router-dom';

import './DopePageTabs.scss';

const useDopePageTabs = ({ pages, resource, exitUrl, exitText }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: pathId } = useParams();

  useEffect(() => {
    if (pathId && resource && pathId !== resource.id) {
      const newPath = location.pathname.replace(pathId, resource.id);
      navigate(newPath, { replace: true }); // TODO work on this so cannot navigate back to new
    }
  }, [resource?.id, pathId, location.pathname]);

  const activePage = pages.find((page) => {
    return matchPath({ path: page.path }, location.pathname);
  });
  const isValid = resource && activePage.isValid(resource);

  const activeIndex = pages.indexOf(activePage);

  const nextPage = pages[activeIndex + 1];
  const isLastPage = !nextPage;

  const isSecondToLastPage = activeIndex === pages.length - 2;

  const prevPage = pages[activeIndex - 1];
  const isFirstPage = !prevPage;

  const getUrl = (page) => {
    return page.path.replace(':id', resource.id);
  };

  const exit = () => navigate(exitUrl);

  const navigateBack = () => {
    if (isFirstPage) {
      return navigate(exitUrl);
    }

    navigate(getUrl(prevPage, resource));
  };

  const navigateForward = () => {
    if (isLastPage) {
      return;
    }

    navigate(getUrl(nextPage, resource));
  };

  const backLinkText = isFirstPage ? exitText : `Back to ${prevPage.name}`;

  const BackLink = ({ className = '' }) => (
    <div className={`link ${className}`} onClick={navigateBack}>← {backLinkText}</div>
  );

  const PageTabs = () => (
    <div className="page-tabs">
      {pages.map((page, index) => {
        const { name, path, isValid } = page;
        const prevPage = pages[index - 1];
        const displayName = `${index + 1}. ${name}`;
        const prevPageIsInvalid = prevPage && resource && !prevPage.isValid(resource);
        const disabled = prevPageIsInvalid;
        const active = activePage.path === path;
        const valid = resource && isValid(resource);
        const classes = classNames('page-tab', { active, disabled, valid });
        return (
          <div className={classes} key={name} onClick={() => navigate(getUrl(page, resource))}>
            {displayName}
          </div>
        );
      })}
    </div>
  );

  return {
    PageTabs,
    BackLink,
    navigateForward,
    navigateBack,
    exit,
    activePage,
    isValid,
    isFirstPage,
    isSecondToLastPage,
    isLastPage,
  };
};

export default useDopePageTabs;
