import React, {useEffect, useState} from "react";
import FileDownloadIcon from '@rsuite/icons/FileDownload';

import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";
import DopeActionButton from "../ui/DopeActionButton";
import { useAccount } from "../accounts/accountSlice";
import  { useDopeUI } from "../ui/dopeUISlice";
import DopePlaceholder from "../ui/DopePlaceholder";
import {formatToLocaleDateString} from "../utils/date";
import "./Subscriptions.scss";

const accountApi = new DopeApi("account");
const accountAssignmentApi = new DopeApi("account_assignment");

const planClass = {
  "LIL' TASTE": "lil-taste",
  "DOPE": "dope",
  "DOPER": "doper",
  "DOPEST": "dopest",
  "FREEBIE": "freebie",
}

const mainStripeProductId = process.env.REACT_APP_MAIN_STRIPE_PRODUCT;

const getMainSubscription = (accountSubscriptions) => {
  return accountSubscriptions.find(sub => sub.items.data.find(item => item.price.product === mainStripeProductId));
}

const getMainSubscriptionPlan = (subscription) => {
  let plan = {};
  if (subscription) {
    subscription.items.data.forEach(item => {
      if (item.price.product === mainStripeProductId) {
        plan = item.price;
      }
    })
  }

  return plan;
}

const planIsCurrentPlan = (plan, accountMainPlan) => {
  return plan.id === accountMainPlan.id;
}


const SubscriptionItem = ({ plan, selectedPlan, currentSubscription, currentPlan, handlePlanSelect, selectable }) => {
  const { nickname, unit_amount } = plan;
  const isCurrentPlan = planIsCurrentPlan(plan, currentPlan);
  const isSelectedPlan = selectedPlan.id === plan.id;

  const clickHandler = () => {
    if (!isCurrentPlan) {
      handlePlanSelect(plan)
    }
  }

  if (Object.keys(plan).length === 0) {
    return null;
  }

  return (
    <div
      className={`flex row space-between subscription-wrapper margin-16-b ${selectable ? "clickable" : ""} ${isSelectedPlan && selectable ? "selected" : "unselected"}`}
      onClick={clickHandler}
    >
      <div style={{display: "flex", flexDirection: "column"}}>
        {isSelectedPlan && selectable && <span className="select-text">SELECTED PLAN</span>}
        {isCurrentPlan && selectable && <span className="current-plan">CURRENT PLAN</span>}
        <span className={`subscription-logo ${planClass[nickname]}`}>{nickname}</span>
        <span style={{ fontWeight: 700, fontSize: "11px", color: "#255FDF"}}>Start Date: {formatToLocaleDateString(new Date(currentSubscription.created * 1000).toDateString(), true)}</span>
      </div>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "flex-end"}}>
        <span className="subscription-price monthly-price">${unit_amount / 100}/{plan.recurring.interval}</span>
        <span style={{ fontWeight: 400, fontSize: "11px", color: "#6F6F6F"}}>(next bill date: {formatToLocaleDateString(new Date(currentSubscription.current_period_end * 1000).toDateString(), true)})</span>
        <span style={{ fontWeight: 600, fontSize: "14px", color: "#255FDF"}}><FileDownloadIcon /> Download Terms & Services</span>
      </div>

      {/*/!* TODO: We have varying costs depending on volume, how to handle? *!/*/}
      {/*<span>${JSON.parse(metadata.postcardPrice)[0].cost} per 6x9 Postcard</span>*/}
      {/*<span>${JSON.parse(metadata.handwrittenCardPrice)[0].cost} Handwritten Cards</span>*/}
      {/*<span>${JSON.parse(metadata.goodiesPrice)[0].cost} Box of Cookies</span>*/}
      {/*<span>FREE Designs</span>*/}

    </div>
  );
}

const SubscriptionSelector = ({ selectable }) => {
  const [subscriptionOptions, setSubscriptionOptions] = useState([])
  const [selectedPlan, setSelectedPlan] = useState({})
  const [accountSubscriptions, setAccountSubscriptions] = useState([])
  const [accountExecutive, setAccountExecutive] = useState([]);
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [account, accountActions] = useAccount();

  useEffect(() => {
    const fetchSubscriptions = async () => {
        try {
          const subscriptions = await accountApi.fetchMember(authApi.getCurrentAccountId(), "stripe_subscription_data");
          setSubscriptionOptions(subscriptions.stripe_subscription_options);
          setAccountSubscriptions(subscriptions.account_stripe_subscriptions.map(subscription => subscription));
        } catch (error) {
          console.log(error);
        }

    }

    fetchSubscriptions();
    fetchAccountExecutive();
  }, []);

  const fetchAccountExecutive = async () => {
    const accountAssignments = await accountAssignmentApi.getMany({
      filters: [{ field: "account_id", value: authApi.getCurrentAccountId(), operator: "=" }],
      scopes: [{name: "account_executives"}]
    })
    setAccountExecutive(accountAssignments[0]);
  }

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  }

  const handleSelectPlan = () => {
    if (Object.keys(selectedPlan).length === 0) {
      dopeUIActions.addFlashMessage({
        header: "No plan selected",
        body: "Please select a plan before continuing",
        type: "error",
      });
    } else {
      accountActions.purchaseSubscription({stripe_subscription_id: selectedPlan.id})
    }
  }

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
    navigator.clipboard.writeText(email).then(() => {
      dopeUIActions.addFlashMessage({header: 'Email copied to clipboard', body: email, type: 'success' });
    }).catch(err => {
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    });
  }

  const baseSubscription = getMainSubscription(accountSubscriptions)
  const baseSubscriptionPlan = getMainSubscriptionPlan(baseSubscription)
  const planDisplayOptions = selectable ? subscriptionOptions : [baseSubscriptionPlan]

  return (
    <>
      {subscriptionOptions.length > 0 ? (
        <div>
          { planDisplayOptions.map(plan => (
            <SubscriptionItem
              key={plan.id}
              plan={plan}
              selectedPlan={selectedPlan}
              handlePlanSelect={handlePlanSelect}
              currentPlan={baseSubscriptionPlan}
              currentSubscription={baseSubscription}
              selectable={selectable}
            />)
          )}
          { selectable && <DopeActionButton
            label="Select Plan"
            props={{primary: true}}
            onClick={handleSelectPlan}
          />
          }
        </div>
        ) : (
          <DopePlaceholder type={"image"} rows={5} />
        )
      }

      <div style={{textAlign: "center", marginTop: "50px", fontSize: "16px", fontWeight: 400}}>
        Need to change your subscription level or want to cancel? Contact your
        <span style={{color: "#255FDF", cursor: accountExecutive  ? "pointer" : ""}} onClick={accountExecutive ? () => handleEmailClick(accountExecutive.user.email) : () => {}}> Account Executive </span>
         or reach out to us at
        <span style={{color: "#255FDF", cursor: "pointer"}} onClick={() => handleEmailClick("support@dopemarketing.com")}> support@dopemarketing.com </span>
      </div>
    </>
  );
}

export default SubscriptionSelector;
