import React, {useEffect,useState} from "react";
import {RadioTile, RadioTileGroup} from "rsuite";
import {Icon} from "@rsuite/icons";

const selectedStyles = {
  border: "2px solid #255FDF",
  background: "#BCD2E11A"
};

const radioTileStyles = {
  gap: "16px",
  height: "100px",
  width: "137px",

  fontSize: "14px",
  color: "#000000"
};

const selectedTileStyles = { ...radioTileStyles, ...selectedStyles };

const DopeRadioTile = ({ name, value, onChange, options, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue);

  // Hide the selected radio button checkmark
  useEffect(() => {
    let elements = document.querySelectorAll('.rs-radio-tile-mark, .rs-radio-tile-icon');

    elements.forEach(element => {
      element.style.display = 'none';
    });

  }, []);

  const isSelected = (value, index) => {
    if (!selected && defaultValue !== null) {
      return index === 0;
    } else {
      return selected === value;
    }
  };

  const handleSelect = (activeKey) => {
    setSelected(activeKey);
  };

  return (
    <RadioTileGroup value={value} onChange={nextValue => onChange({ [name]: nextValue })} defaultValue={defaultValue} style={{display: "flex", flexDirection: "row"}}>
      {options.map((option, index) => (
        <RadioTile value={option.value} key={option.value} onChange={value => handleSelect(value)} style={isSelected(option.value) ? selectedTileStyles : radioTileStyles}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div style={{display: "flex", justifyContent: "center"}}>
              <Icon as={option.icon} style={{fontSize: "32px", color: isSelected(option.value, index) ? "#255FDF" : "#494B4E"}}/>
            </div>
            <div style={{textAlign: "center", color: "#292B2E"}}>{option.label}</div>
          </div>
        </RadioTile>
      ))}
    </RadioTileGroup>
  );
};

export default DopeRadioTile;
