import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  drawerListId: null,
}

export const listUISlice = createSlice({
  name: "listUI",
  initialState,
  reducers: {
    updateListUI: (listUI, { payload: update }) => {
      Object.assign(listUI, update);
    },
    setDrawerListId: (listUI, { payload: id }) => {
      listUI.drawerListId = id;
    }
  }
});

export default listUISlice.reducer;

export const {
  updateListUI,
  setDrawerListId,
} = listUISlice.actions;

export const selectListUI = (state) => state.listUI;

export function useListUI() {
  const dispatch = useDispatch();
  const listUI = useSelector(selectListUI);
  const actions = {
    update: (payload) => dispatch(updateListUI(payload)),
    setDrawerListId: (id) => dispatch(setDrawerListId(id)),
  };

  return [listUI, actions];
}