import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { BsFillPersonVcardFill } from "react-icons/bs";
import { FaUpload } from "react-icons/fa6";

import { useDesign } from "./designSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import DopePageStepHeader from "../ui/DopePageStepHeader";

import DesignGuidelinesModal from "./DesignGuidelinesModal";

import PostcardApprovalDrawer from "./PostcardApprovalDrawer";

import { sizeDisplay, objectURLToFile, sizeToRatio  } from "../utils/design";

import './Design.scss';
import 'react-image-crop/src/ReactCrop.scss';
import DopeDesignInput from "./DopeDesignInput";
import DopeButton from "../ui/DopeButton";
import HotLeadIcon from "../icons/HotLeadIcon";
import DopePlayer from "../ui/DopePlayer";

const nameInput = {
  inputType: "text",
  name: "name",
  props: { label: "Design Name" },
};

const objectiveTypeInput = {
  inputType: "radio",
  name: 'objective_type',
  props: {
    label: "Mailer Tag",
    options: [
      { label: 'Marketing', value: 'marketing', helpText: '(Brand awareness targeting new customers)' },
      { label: 'Thank You', value: 'thank_you', helpText: '(More reviews & referrals from current jobs)' },
      { label: 'Reactivation', value: 'reactivation', helpText: '(Targeting existing customers)' },
    ],
    defaultValue: 'marketing',
  },
};

const mailerTypeInput = { inputType: "radio_tile", name: "mailer_type", props: {
  label: "Mailer Type",
  defaultValue: null,
  options: [
    { value: "standard", label: "Standard Postcard", icon: BsFillPersonVcardFill },
    { value: "hot_lead", label: "Hot Lead Postcard", icon: HotLeadIcon },
  ],
}};

const tagsInput = {
  inputType: "tags",
  name: "taggings",
  props: { label: "Design Tags", modelName: "design" }
};


const PostcardDesignForm = () => {
  const { design, actions } = useDesign();

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const size = params.get('size') || design.mail_template.size || '6x9';

  const designInputProps = { values: design, onChange: actions.update, errors: design.errors };
  const templateInputProps = { values: design.mail_template, onChange: actions.updateMailTemplate, errors: design.errors };


  const [front, setFront] = useState(design.mail_template?.front_image_url);
  const [back, setBack] = useState(design.mail_template?.back_image_url);


  const [loading, setLoading] = useState(false);

  const [showDesignGuidelines, setShowDesignGuidelines] = useState(false);
  const [showApproval, setShowApproval] = useState(false);

  useEffect(() => {
    actions.update({ mail_template_type: 'PostcardTemplate' });

    if (size) {
      actions.updateMailTemplate({ size });
    }
  });

  useEffect(() => {
    if (design.id === 'new') {
      actions.updateMailTemplate({ mailer_type: null });
    }
  }, [design?.id]);

  const handleUpload = async () => {
    setLoading(true);

    const now = Date.now();

    const [frontImage, backImage] = await Promise.all([
      objectURLToFile(front, `front_${now}.png`),
      objectURLToFile(back, `back_${now}.png`),
    ]);

    await actions.save({
      files: {
        front_image: frontImage,
        back_image: backImage,
      },
      status: 'approved',
    });

    setLoading(false);

    navigate('/designs');
  };

  const canSubmit = () => {
    if (loading) {
      return false;
    }

    const mailerTypeOptions = mailerTypeInput.props.options.map(({ value }) => value);

    if (!mailerTypeOptions.includes(design.mail_template.mailer_type)) {
      return false;
    }

    if (front === null || back === null) {
      return false;
    }

    if (!design.name || design.name.trim() === '') {
      return false;
    }

    return true;
  };

  return (
    <div className="postcard-design-form-wrapper">
      <div className="page">
        <div className="page-header">
          <p className="header-text">Upload a New "{sizeDisplay(size)} Postcard" Design</p>
        </div>

        <div>
          <DopePageStepHeader
            step="1"
            headerText="The Basics"
          />
          <div className="flex row">
            <div className="rs-form margin-40-b postcard-basics">
              <div>
                <DopeFormGroup input={nameInput} {...designInputProps} />
              </div>
              <div>
                <DopeFormGroup
                  input={mailerTypeInput}
                  onChange={({ mailer_type }) => actions.updateMailTemplate({ mailer_type })}
                  values={design.mail_template}
                  errors={design.errors}
                  />
              </div>
              <div>
                <DopeFormGroup input={objectiveTypeInput} {...templateInputProps} />
              </div>
              <div>
                <DopeFormGroup input={tagsInput} {...designInputProps} />
              </div>
            </div>

            <div className="full-width">
              <DopePlayer url="https://dopemail-assets.s3.amazonaws.com/Uploading+Custom+Designs+on+Dope+Platform+%F0%9F%8E%A8.mp4" />
            </div>
          </div>
        </div>
      </div>


      <div className="page">
        <DopePageStepHeader
          step="2"
          headerText="Upload Designs"
          helpText={(<>Use our <a onClick={() => setShowDesignGuidelines(true)}>Design Guidelines</a> to make sure your design fits our specs.</>)}
        />
        <p className='instructions'></p>
        <div className={`postcard-front-preview ${design.mail_template.mailer_type}`}>
          <h6>Front Preview</h6>
          <DopeDesignInput
            size={size}
            onChange={(srcImage, previewImage) => {
              setFront(previewImage);
            }}
            allowCrop={false}
          />
        </div>

        <div className={`postcard-back-preview ${design.mail_template.mailer_type}`}>
          <h6>Back Preview</h6>
          <DopeDesignInput
            size={size}
            onChange={(srcImage, previewImage) => {
              setBack(previewImage);
            }}
            allowCrop={false}
          />
        </div>
      </div>
      <div className="page-actions" style={{ margin: "2rem 1rem"}}>
        <DopeButton
          icon={<FaUpload style={{fontSize: '10px', marginRight: "5px"}} />}
          disabled={!canSubmit()}
          props={{
            label: "Upload as Approved",
            buttonClass: "filled",
            onClick: () => {
              if (canSubmit()) {
                setShowApproval(true);
              }
            }
          }}
        />
      </div>

      <DesignGuidelinesModal
        open={showDesignGuidelines}
        onClose={() => setShowDesignGuidelines(false)}
      />

      <PostcardApprovalDrawer
        open={showApproval}
        onConfirm={handleUpload}
        onCancel={() => setShowApproval(false)}
        loading={loading}
        design={design}
        actions={actions}
        front={front}
        back={back}
        aspectRatio={sizeToRatio(size)}
      />
    </div>
  );
};

export default PostcardDesignForm;
