import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

import DopeApi from '../services/DopeApi';

const api = new DopeApi('design_version');

const postComment = createAsyncThunk(
  'design_version/comment',
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      return await api.postMember(id, 'comment', { ...params });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const deleteComment = createAsyncThunk(
  'design_version/deleteComment',
  async ({ id, comment_id }, { rejectWithValue }) => {
    try {
      return await api.deleteMember(id, 'comment', { comment_id });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const postAttachment = createAsyncThunk(
  'design_version/attach',
  async ({ id, attachment }, { rejectWithValue }) => {
    try {
      return await api.uploadMember(id, 'attach', { attachment });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const deleteAttachment = createAsyncThunk(
  'design_version/detach',
  async ({ id, attachment_id }, { rejectWithValue }) => {
    try {
      return await api.deleteMember(id, 'detach', { attachment_id });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const [designVersionSlice, asyncActions] = createModelSlice('design_version', {}, (builder) => {
  builder.addCase(postComment.fulfilled, (state, action) => {
    state.current.comments.push(action.payload);

    return state;
  })
  builder.addCase(deleteComment.fulfilled, (state, action) => {
    state.current.comments = state.current.comments.filter(comment => comment.id !== action.payload.id);

    return state;
  })
  .addCase(postAttachment.fulfilled, (state, action) => {
    state.current.attachments_urls.push(action.payload);

    return state;
  })
  .addCase(deleteAttachment.fulfilled, (state, action) => {
    // eslint-disable-next-line eqeqeq
    state.current.attachments_urls = state.current.attachments_urls.filter(attachment => attachment.id != action.payload);
    return state;
  });
});

export default designVersionSlice.reducer;

const designVersionToParams = (designVersion, prevDesignVersion) => {

  return {
    ...designVersion,
  };
};

const designVersionSelector = state => {
  return state.designVersion.current;
}

const derivedDesignVersionAttrsSelector = createSelector([designVersionSelector], (designVersion) => {
  if (!designVersion) return {};

  const derivedUpdates = {};

  return derivedUpdates;
});

export function useDesignVersion(designVersionId) {
  const dispatch = useDispatch();
  const designVersion = useSelector(designVersionSelector);
  const derivedDesignVersionAttrs = useSelector(derivedDesignVersionAttrsSelector);
  const prevDesignVersion = useSelector(state => state.designVersion.prev);
  const isSaving = useSelector(state => state.designVersion.isSaving);

  const actions = wrapAll({
    ...designVersionSlice.actions,
    ...asyncActions,
    save: (additionalParams = {}, mailTemplateParams={}) => {
      const { files, json_template, ...extras } = additionalParams;
      const { mail_template, ...params } = { ...designVersionToParams(designVersion, prevDesignVersion), ...extras };

      const mailTemplate = { ...mail_template, ...(files ?? {}), json_template: json_template ?? null };


      delete params.errors;

      return asyncActions.save({...params, mail_template_attributes: mailTemplate });
    },
    approve: () => {
      const params = designVersionToParams(designVersion, prevDesignVersion);
      return asyncActions.save({ ...params, status: 'approved' });
    },
    comment: ({ ...params }) => {
      return postComment({ ...params, id: designVersion.id });
    },
    deleteComment: ({ comment_id }) => {
      return deleteComment({ comment_id, id: designVersion.id });
    },
    attach: ({ attachment, ...params }) => {
      return postAttachment({ attachment, ...params, id: designVersion.id });
    },
    detach: ({ attachment_id }) => {
      return deleteAttachment({ attachment_id, id: designVersion.id });
    },
  }, dispatch);

  useEffect(() => {
    const shouldGet = designVersionId && (!designVersion || designVersion.id !== designVersionId);
    if (shouldGet) {
      actions.get(designVersionId);
    }
  }, [designVersionId, designVersion?.id]);



  return {
    designVersion,
    derivedDesignVersionAttrs,
    actions,
    isSaving,
  };
};

