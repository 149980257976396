import React, {useEffect} from "react";
import {SelectPicker} from "rsuite";

const logoStyle = {
  fontWeight: 900,
  fontSize: "32px",
  fontFamily: "Montserrat",
  lineHeight: "120%",
  fontStyle: "normal",
}

const logoColors = {
  "LIL' TASTE": "#DB6540",
  "DOPE": "#DB9940",
  "DOPER": "#999999",
  "DOPEST": "#EBCC53",
  "FREEBIE": "#4A90E2",
}

const selectStyles = {
  boxShadow: "0px 6px 6px 0px #BABABA40",
  border: "1px solid #F3F3F3",
  cursor: "pointer",
  width: "320px",
  height: "108px",
  padding: "20px"
}


const SubscriptionItem = ({subscription}) => {

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "20px"}}>
      <div style={{...logoStyle, color: logoColors[subscription.nickname]}}>
        {subscription.nickname}
      </div>
      <div style={{fontSize: "18px", fontWeight: 600, color: "#000000"}}>
        ${subscription.unit_amount / 100}/{subscription.recurring.interval}
      </div>
    </div>
  )
}

const SubscriptionSelectDropdown = ({ selectedSubscription, subscriptionOptions, handleSelectChange }) => {
  useEffect(() => {
    const defaultSubscription = subscriptionOptions.find(sub => sub.nickname === "DOPE" && sub.recurring.interval === "month")
    if (defaultSubscription) {
      handleSelectChange(defaultSubscription.id)
    }
  }, [subscriptionOptions])

  return (
    <>
      <h5 className="subscription-subheader">Your Subscription</h5>
      <SelectPicker
        data={subscriptionOptions}
        onChange={handleSelectChange}
        searchable={false}
        placeholder={"Select a plan"}
        value={selectedSubscription}
        cleanable={false}
        style={{width: "481px"}}
        renderValue={(value, item) => {
          if (item) {
            return <SubscriptionItem subscription={item} />;
          }
          return null;
        }}
        renderMenuItem={(label, item) => (
          <SubscriptionItem subscription={item}/>
        )}
      />
    </>
  )
}

export default SubscriptionSelectDropdown;
