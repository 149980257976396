import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";
import { useAccount } from "../accounts/accountSlice";

import { Divider } from "rsuite";

import DopeTabs from "../ui/DopeTabs";
import CampaignAudienceSummary from "./CampaignAudienceSummary";
import CampaignFiltersSummary from './CampaignFiltersSummary';
import { TagList } from "../ui/DopeTags";
import CampaignDispatchList from "./CampaignDispatchList";
import CampaignMailPieceTable from "./CampaignMailPieceTable";
import {useStripeUI} from "../stripe/stripeUISlice";
import DopeDrawer from "../ui/DopeDrawer";
import InvoiceDrawer from "../stripe/InvoiceDrawer";
import React, {useEffect} from "react";
import authApi from "../auth/authApi";

const DispatchInvoiceDrawer = () => {
  const [stripeUI, stripeUiActions] = useStripeUI();
  const [account, actions] = useAccount();

  useEffect(() => {
    if (!account) {
      actions.get(authApi.getCurrentAccountId());
    }
  }, []);

  return (
    <DopeDrawer
      className="invoice-drawer"
      open={!!stripeUI.drawerInvoiceId}
      onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
      bodyContent={
        <InvoiceDrawer
          id={stripeUI.drawerInvoiceId}
          account={account}
          onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        />
      }
    />
  );
};


const CampaignShow = () => {
  const [campaign, campaignActions] = useCampaign();
  const [settings] = useCampaignListGenerationSettings();


  const campaignDetails = (
    <div className="pad-t">
      <div className="campaign-review-label large">Mailer Schedule</div>
      <CampaignDispatchList
        withCancel
        editable={false}
      />

      <Divider />

      <CampaignAudienceSummary />
    </div>
  );

  const subheader = {
    list: campaign.list_generation_setting?.starting_list?.name,
    blitz: "Addresses around " + campaign.list_generation_setting.full_address,
    list_blitz: "",
  }

  return (
    <div className="campaign-page-wrapper">
      <div className='campaign-page'>
        <div className="page">
          <div className="campaign-name-header column">
            <span>{campaign.name}</span>
            {campaign.is_automation && <span className="label large blue-grey">Trigger: {campaign.trigger_full_name} ({campaign.trigger_full_address})</span>}
            {!campaign.is_automation && <span className="label large blue-grey">{subheader[campaign.list_generation_setting.generation_type]}</span>}
          </div>

          <div style={{ paddingTop: 20 }}>
            <DopeTabs>
              <DopeTabs.Tab name="details" label="Campaign Details">
                {campaignDetails}
              </DopeTabs.Tab>

              <DopeTabs.Tab name="mailing" label="Mailing List">
                <CampaignMailPieceTable />
              </DopeTabs.Tab>
            </DopeTabs>
          </div>
        </div>
      </div>

      <DispatchInvoiceDrawer />

    </div>
  );
};

export default CampaignShow;
