import React, { useEffect } from "react";

import DopeListPage from "../ui/DopeListPage";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import {handleFilterChange} from "../utils/fn";
import authApi from "../auth/authApi";
import ListStatusCell from "./ListStatusCell";

const listApi = new DopeApi('list');
const taggingsApi = new DopeApi('taggings')

const setFavorite = async (rowData) => {
  await listApi.update({id: rowData.id, favorite: !rowData.favorite});
}

const columns = [
  { type: 'name_link',
    dataKey: 'name',
    sortable: true,
    label: 'Name',
    getUrl: (list) => `/lists/${list.id}/details`,
    customStyles: { fontSize: '14px', fontWeight: 400, color: '#292B2E' }
  },
  { type: 'timestamp', dataKey: 'created_at', label: 'Date Created', sortable: true },
  { type: 'text', dataKey: 'contact_lists_count', label: 'Contacts', sortable: true },
  { type: 'pill', dataKey: 'data_source', label: 'List Type', pillType: 'tag', sortable: true, pillLabelMap: { data_axle: "Blitz" } },
  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
  { type: 'custom_component', width: 180, label: 'Upload Status', dataKey: 'effective_status', Component: ListStatusCell },
];

const listTypeOptions = [
  { value: "user", label: "User" },
  { value: "data_axle", label: "Blitz" },
];

const ListListPage = ({ setListCount }) => {
  const { error, tableProps, paginationProps , onSearchClear, onSearchInput, setFilters, filters }
    = useQuery2({
      api: listApi,
      initialSearchScope: "name"
    });

  useEffect(() => {
    setListCount(paginationProps.total);
  }, [paginationProps.total]);

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const applyListTypeFilters = (lists) => {
    const filterObject = {
      filterField: "data_source",
      operator: "in",
      filterCategory: "List Type"
    }
    handleFilterChange(filters, setFilters, lists, filterObject)
  }

  const filterInputs = [
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "List" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    },
    { title: "List Type", getOptions: () => listTypeOptions, applyFilter: applyListTypeFilters }
  ]


  return (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      onSearchChange={onSearchInput}
      onSearchClear={onSearchClear}
      filterInputs={filterInputs}
      setFilters={setFilters}
      filters={filters}
      error={error}
    />
  );
}

export default ListListPage;
