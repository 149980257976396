import React, { useEffect } from 'react';

import { useParams, useLocation, useNavigate, Outlet, matchPath } from 'react-router-dom';

import { useAutomation } from './automationSlice';
import { useAutomationUI } from './automationUISlice';
import { useDopeUI } from '../ui/dopeUISlice';
import { errorsToErrorList } from '../utils/errors';

import useDopePageTabs from '../ui/useDopePageTabs';
import DopePageLoader from '../ui/DopePageLoader';
import { DopeButton, DopeLinkButton } from "../ui/DopeButton";

const REQUIRED_MAP_FIELDS = ['address1', 'city', 'state', 'firstName', 'lastName'];

const automationToValidityAttrs = (automation) => {
  const hasName = !!automation.name;
  const isPersisted = !!automation.id && automation.id !== 'new'; // Hack for now, to force user to use save and continue button as we need an id for the triggering
  const hasDispatches = !!automation.campaign_template.dispatch_templates.length;
  const hasThrottle = !!automation.throttle_days && !!automation.throttle_count;

  return {
    hasName,
    isPersisted,
    hasDispatches,
    hasThrottle,
  };
};

const pages = [
  {
    name: 'Automation setup',
    path: '/automations/:id/details',
    isValid: (automation) => {
      const { hasName, isPersisted } = automationToValidityAttrs(automation);

      return hasName && isPersisted;
    },
  },
  {
    name: 'Trigger setup',
    path: '/automations/:id/trigger',
    isValid: (automation) => {
      if (automation.trigger_source !== 'native') return true;
      if (!automation.subscription_data) return false;

      const sub = automation.subscription_data;
      const hasDataSource = !!sub.data_source;
      const hasDataType = !!sub.data_type;
      const hasEvent = !!sub.event;
      const hasRules = !!sub.conditions?.rules?.length;
      const hasNeededRules = hasRules || sub.event === 'create';
      // TODO has changeRule
      const hasCDMdMap = !!sub.cdm_map;
      const hasRequiredFields = !!hasCDMdMap && REQUIRED_MAP_FIELDS.every(field => !!sub.cdm_map[field]);
      const hasValidSub = hasDataSource && hasDataType && hasEvent && hasNeededRules && hasCDMdMap && hasRequiredFields;

      return hasValidSub;
    },
  },
  {
    name: 'Mailer setup',
    path: '/automations/:id/mailers',
    isValid: (automation) => {
      return !!automation.campaign_template.dispatch_templates.length;
    }
  },
  {
    name: 'Review automation',
    path: '/automations/:id/activate',
    isValid: (automation) => {
      return !!automation.throttle_days && !!automation.throttle_count;
    },
  }
];

const AutomationFormPages = () => {
  const { automation, derivedAutomationAttrs, actions, isSaving } = useAutomation();
  const [automationUI] = useAutomationUI();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const navigate = useNavigate();

  const {
    PageTabs,
    BackLink,
    navigateForward,
    isLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: automation, exitUrl: '/automations', exitText: 'Back to all Automations' });

  const navigateShow = (automation) => navigate(`/automations/${automation.id}/show`);

  const flashErrors = (errors) => {
    dopeUIActions.addFlashMessage({
      type: 'error',
      header: 'Save Failed',
      body: errorsToErrorList(errors),
      timeout: 10000,
    });
  };

  const save = (extraParams = {}) => {
    return actions.save(extraParams)
      .then(action => {
        const success = action.meta.requestStatus === 'fulfilled';
        if (success) {
          return Promise.resolve(action.payload);
        }

        return Promise.reject(action.payload);
      });
  };

  const handleSave = () => save().catch(flashErrors);
  const handleActivate = () => save({ status: 'active' }).then(navigateShow).catch(flashErrors);
  const handleSaveAndContinue = () => save().then(navigateForward).catch(flashErrors);

  const saveAndContinueButton = (
    <DopeButton
      className="next-btn"
      disabled={isSaving}
      loading={isSaving}
      props={{
        buttonClass: "filled",
        label: "Save & Continue",
        onClick: handleSaveAndContinue,
      }}
    />
  );

  const isActive = derivedAutomationAttrs.isActive;
  const activateButtonLabel = isActive ? "Save & View" : "Activate & Test Automation";
  const activateButtonDisabled = isActive ? false : !isValid || !automationUI.confirmedPurchase;
  const activateButton = (
    <DopeButton
      className="next-btn"
      disabled={activateButtonDisabled}
      loading={isSaving}
      props={{
        buttonClass: "filled",
        label: activateButtonLabel,
        onClick: handleActivate,
      }}
    />
  );

  const saveButtonLabel = derivedAutomationAttrs.isDraft ? "Save as draft" : "Save";
  const saveButton = (
    <DopeButton
      className="save-btn"
      loading={isSaving}
      props={{
        buttonClass: "text-button",
        label: saveButtonLabel,
        onClick: handleSave,
      }}
    />
  );

  const pageTabs = (
    <div>
      <PageTabs />
    </div>
  );

  const viewLink = isActive && (
    <DopeLinkButton
      onClick={handleActivate}
      text="Save & View →"
      disabled={isSaving}
      loading={isSaving}
    />
  );

  return (
    <div className='flex-1'>

      <div className="margin space-between vertical-align">
        <BackLink />

        {viewLink}
      </div>

      <div className="margin-bottom">

        <Outlet context={{ pageTabs }} />

        <div className="footer-actions">
          {saveButton}
          {isLastPage ? activateButton : saveAndContinueButton}
        </div>
      </div>
    </div>
  );
};


const Automation = () => {
  const { id } = useParams();
  const location = useLocation();
  const { automation, actions } = useAutomation();
  const [automationUI, automationUIActions] = useAutomationUI();

  const isFormPath = pages.some(page => matchPath({ path: page.path }, location.pathname));

  useEffect(() => {
    if (!automation || automation.id != id) {
      actions.get(id + location.search);
    }

    return () => {
      actions.resetToInitial();
      automationUIActions.resetToInitial();
    }
  }, []);

  if (!automation) {
    return (<DopePageLoader />);
  }

  return isFormPath ? (<AutomationFormPages />) : (<Outlet />);
};

export default Automation;
