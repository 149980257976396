import React from "react";

const DopePageStepHeader = ({ step, headerText, helpText, callOutText }) => {
  return (
    <div className="margin-bottom">
      <p className="vertical-align gap-10">
        <span className="header-5 pad-4 color-link border-radius-8 background-blue-grey-shade-light">Step {step}</span>
        <span className="header-4">{headerText}</span>
      </p>
      <p className="help-text">{helpText}</p>
      {callOutText && (<p className="bold">{callOutText}</p>)}
    </div>
  );
};

export default DopePageStepHeader;
