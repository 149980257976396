import React from "react";

import { useAutomation } from "./automationSlice";
import { IntegrationsProvider } from "../integrations/integrationsSlice";

import Subscription from "../integrations/Subscriptions/Subscription";
import SubscriptionConnectionSelect from "../integrations/Subscriptions/SubscriptionConnectionSelect";
import DopePageStepHeader from "../ui/DopePageStepHeader";

import '../integrations/integrations.scss';

const AutomationTriggerNative = () => {
  const { automation, actions } = useAutomation();

  const subProps = {
    connection: {
      header: (
        <DopePageStepHeader
          step="1"
          headerText="Choose your connection"
          helpText="Select the connection you want to trigger this automation."
        />
      ),
    },
    event: {
      header: (
        <div className="margin-header">
          <DopePageStepHeader
            step="2"
            headerText="Setup your trigger"
            helpText='Choose the status that your jobs will go through that should trigger this automation (ex: “When a Job is Updated”)'
          />
        </div>
      ),
    },
    conditions: {
      header: (
        <div className="header-5 margin-header">Add additional rules and filters to only trigger on certain jobs</div>
      ),
    },
    fieldMap: {
      header: (
        <div className="header-5 margin-header">Field Map</div>
      ),
    },
  };

  return [
    <IntegrationsProvider subscriptionId={automation.subscription_id}>
      <div className="page bottom" key="connection">
        <SubscriptionConnectionSelect header={subProps.connection.header} />
      </div>

      <div className="page" key="subscription">
        <Subscription
          onChange={subscription_data => actions.update({ subscription_data })}
          subProps={subProps}
        />
      </div>
    </IntegrationsProvider>
  ];
}

export default AutomationTriggerNative;
