import React from "react";

const ListDropdown = ({ list }) => {
  return (
    <div>
      <div>Edit List</div>
      <div>Download List</div>
      <div>Create New Contact</div>
      <hr/>
      <div>Add to a List</div>
      <div>Add to Favorites</div>
      <hr/>
      <div>Send a List Campaign</div>
      <div>Send a List Blitz</div>
      <hr/>
      <div>Delete List</div>
      <hr/>
      <div>List Type</div>
      <hr/>
      <div>CUSTOM TAGS</div>
    </div>
  )
}

export default ListDropdown;