import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';

import 'rsuite/dist/rsuite.min.css';
import './utility.scss';
import './App.scss';

import { Container } from 'rsuite';

import authApi from './auth/authApi';

import { setGetBaseUrl, setGetHeaders } from './integrations/IntegrationsAPI';

import LoginToggleForm from "./auth/LoginSignupContainer";
import ResetPassword from "./auth/ResetPassword";
import ForgotPassword from "./auth/ForgotPassword";
import AccountManagement from "./accounts/AccountManagement";
import UserManagement from './users/UserManagement';
import AccountAssignmentManagement from './accountAssignment/AccountAssignmentManagement';
import ListGenerationSettingManagement from './lists/ListGenerationSettingManagement';
import ListGenerationManagement from "./lists/ListGenerationManagement";
import ListManagement from "./lists/ListManagement";
import AdminNavbar from "./admin/AdminNavbar";
import DopeNavBar from "./ui/DopeNavBar";
import ContactManagement from "./contacts/ContactManagement";
import PostcardTemplateManagement from './postcardTemplates/PostcardTemplateManagement';
import CampaignManagement from './campaigns/CampaignManagement';
import DispatchManagement from './campaigns/DispatchManagement';
import DataAxleFilterManagement from "./filters/DataAxleFilterManagement";
import MailPieceManagement from './campaigns/MailPieceManagement';
import CampaignTemplateManagement from './campaignTemplates/CampaignTemplateManagement';
import DopeSidebar from "./ui/DopeSidebar";

import List from "./lists/List";
import ListDetailsPage from "./lists/ListDetailsPage";
import ListUploadContent from "./lists/ListUploadContent";
import CSVFieldMap from "./lists/CSVFieldMap";
import ListsAndContactsPage from "./contacts/ListsAndContactsPage";
import Overview from './overview/Overview';

import Contact from "./contacts/Contact";
import ContactDetailsPage from "./contacts/ContactDetailsPage";

import CampaignTable from './campaigns/CampaignTable';
import Campaign from './campaigns/Campaign';
import CampaignSetup from './campaigns/CampaignSetup';
import CampaignAudience from './campaigns/CampaignAudience';
import CampaignDispatchesPage from './campaigns/CampaignDispatchesPage';
import CampaignReview from './campaigns/CampaignReview';
import CampaignShow from './campaigns/CampaignShow';

import AutomationsAndRuns from './automations/AutomationsAndRuns';
import Automation from './automations/Automation';
import AutomationSetup from './automations/AutomationSetup';
import AutomationTrigger from './automations/AutomationTrigger';
import AutomationCampaignTemplate from './automations/AutomationCampaignTemplate';
import AutomationReview from './automations/AutomationReview';
import AutomationShow from './automations/AutomationShow';

import HasAndBelongsToTheSea from './ui/HasAndBelongsToTheSea';
import DopeFlashMessages from "./ui/DopeFlashMessages";

import Account from "./accounts/Account";
import AccountBilling from './accounts/AccountBilling';
import AccountBatchPurchaseTable from './accounts/AccountBatchPurchaseTable';
import AccountSubscriptionsTab from "./accounts/AccountSubscriptionsTab";
import AccountPaymentsTab from "./accounts/AccountPaymentsTab";
import AccountSettingsTab from "./accounts/AccountSettingsTab";
import AccountUserTokens from "./accounts/AccountUserTokens";
import AccountAgencyTab from "./accounts/AccountAgencyTab";

import AccountSignupForm from "./auth/AccountSignupForm";
import StripeCardAdderWrapper from "./stripe/StripeCardAdderWrapper";
import SubscriptionForm from "./subscriptions/SubscriptionForm";

import MailHistory from "./mailPieces/MailHistory";
import DesignsPage from './designs/DesignsPage';

import Design from './designs/Design';
import PostcardDesignForm from './designs/PostcardDesignForm';
import HandwrittenCardSetup from './designs/HandwrittenCardSetup';
import HandwrittenCardDesignForm from './designs/HandwrittenCardDesignForm';
import HandwrittenCardExtras from './designs/HandwrittenCardExtras';

import AutoConnect from './integrations/AutoConnect';

import UserAccess from "./accounts/UserAccess";
import RecentActivity from './overview/RecentActivity';
import AddOnSelectPage from "./subscriptions/AddOnSelectPage";
import SignupForm from "./auth/SignupForm";
import HandwrittenCardReview from './designs/HandwrittenCardReview';

import ProductPricing from './purchasing/ProductPricing';
import PulseAutomationTable from "./pulse/PulseAutomationTable";
import PulseAccountTable from "./pulse/PulseAccountTable";
import PulseAccountDetails from "./pulse/PulseAccountDetails";
import PulseSubscriptionDetails from "./pulse/PulseSubscriptionDetails";
import PulseUserTable from "./pulse/PulseUserTable";
import DopeTeam from "./pulse/DopeTeam";
import PaymentsAndCredits from "./pulse/PaymentsAndCredits";
import PulseBatchingDetails from "./pulse/PulseBatchingDetails";
import RakeTasks from './pulse/RakeTasks';

import ConversionReport from './analysis/ConversionReport';
import ConversionReportSetup from './analysis/ConversionReportSetup';
import ConversionReportShow from './analysis/ConversionReportShow';
import Dope404Page from "./ui/Dope404Page";

import DesignEditorPage from './editor/DesignEditorPage';
import GoodieDesignForm from './designs/GoodieDesignForm';
import AccountCreditLedger from "./accounts/AccountCreditLedger";
import PulseDesignGrid from './pulse/PulseDesignGrid';
import PulsePricing from "./pulse/PulsePricing";
import DesignRevisionPage from './designs/DesignRevisionPage';
import PulseDesignRevisions from './pulse/PulseDesignRevisions';
import AnalysisPage from './analysis/AnalysisPage';
import PulseReporting from './pulse/reports/PulseReporting';
import DataAxleUsage from './pulse/reports/DataAxleUsage';
import DelayedJobTable from './pulse/reports/DelayedJobTable';
import HWCFrontForm from './designs/HWCFrontForm';

import ListPull from './list_pulls/ListPull';
import ListPullSetup from './list_pulls/ListPullSetup';
import ListPullQuery from './list_pulls/ListPullQuery';

// INIT IPAAS
setGetBaseUrl(() => {
  const apiBase = process.env.REACT_APP_API_BASE_URL;
  const accountId = authApi.getCurrentAccountId();
  const integrationsProxyRoute = `/accounts/${accountId}/integrations/`;
  const baseUrl = `${apiBase}${integrationsProxyRoute}`;

  return baseUrl;
});
setGetHeaders(() => {
  const headers = {};
  headers["Authorization"] = authApi.getAuthHeaderValue();
  headers["X-Current-Account-Id"] = authApi.getCurrentAccountId();

  return headers;
});


// TODO: userType is currently set through a prop, but should be set through the authApi
// TODO got to figure out correct high level layout, heights are breaking
function ProtectedApp({ userType, showNav = true, showSideNav = true }) {
  const [expand, setExpand] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!authApi.isAuthenticated()) {
      navigate('/login');
    } else {
      authApi.fetchMe().then(() => {
        setLoadingUser(false);
        if (authApi.doesNotHaveAccount()) {
          navigate('/account_signup');
        } else if (!authApi.currentAccountSubscriptionIsActive()) {
          navigate('/subscription_signup');
        } else if (!authApi.currentAccountSubscriptionIsPaid()) {
          navigate('/accounts/' + authApi.getCurrentAccountId() + '/billing/invoices ');
        } else if (window.location.pathname === '/subscription_signup') {
          navigate('/overview');
        } else if (window.location.pathname === '/') {
          navigate('/overview');
        }
      });
    }

    const handleStorageChange = (event) => { // keep current account in sync accross tabs to prevent data issues
      if (event.key === 'currentAccount') {
        const old = JSON.parse(event.oldValue);
        const current = JSON.parse(event.newValue);
        if (old && current && old.id !== current.id) {
          window.location.href = "/";
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const noScrollPaths = ['/billing/automated_purchases', '/billing/credit_ledger', '/billing/invoices'];
    let timer;
    if (!noScrollPaths.some(path => pathname.endsWith(path))) {
      timer = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 50);
    }

    return () => clearTimeout(timer);
  }, [pathname]);

  const userTypeToNavbar = {
    admin: AdminNavbar,
    user: DopeNavBar,
    pulse: DopeNavBar,
  };

  const offsetNavClass = () => {
    if (userType === 'admin') {
      return "";
    } else if (!showNav || !showSideNav) {
      return ""
    } else if (expand && showNav) {
      return "page-content"
    } else if (!expand && showNav) {
      return "full-page-content"
    } else {
      return ""
    }
  };

  const showNavClass = () => {
    if (showNav) {
      return "show-nav"
    }

    return '';
  };

  if (loadingUser) {
    return null;
  }

  const UserNavbar = userTypeToNavbar[userType];

  return (
    <>
      {showNav && <UserNavbar userType={userType}/>}

      <div className={`${userType}-Page ${showNavClass()}`}>
        <Container>
          {userType === 'user' && showNav && showSideNav && <DopeSidebar expand={expand} setExpand={setExpand} />}
          {userType === 'pulse' && showNav && showSideNav && <DopeSidebar expand={expand} setExpand={setExpand} userType={userType} />}
          {/* toggles width with sidebar */}
          <div className={offsetNavClass()}>
            <Outlet  />
          </div>
        </Container>
      </div>

      <DopeFlashMessages />
      <HasAndBelongsToTheSea />
    </>
  );
}

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginToggleForm />
  },
  {
    path: '/signup',
    element: <SignupForm />
  },
  {
    path: '/password/edit',
    element: <ResetPassword />
  },
  {
    path: '/forgot_password',
    element: <ForgotPassword />
  },
  {
    path: '/designer',
    element: <Design />,
    children: [
      {
        path: 'design',
        element: <DesignRevisionPage viewOnly={true} />
      }
    ],
  },
  {
    path: '/pulse',
    element: <ProtectedApp userType="pulse" />,
    children: [
      {
        path: '/pulse/accounts/:id',
        element: <Account pulse={true} />,
        children: [
          {
            path: 'details',
            element: <PulseAccountDetails />
          },
          {
            path: 'subscription',
            element: <PulseSubscriptionDetails />
          }
        ]
      },
      {
        path: '/pulse/accounts',
        element: <PulseAccountTable />
      },
      {
        path: '/pulse/users',
        element: <PulseUserTable />
      },
      {
        path: '/pulse/automations',
        element: <PulseAutomationTable />
      },
      {
        path: '/pulse/batching_details',
        element: <PulseBatchingDetails />
      },
      {
        path: '/pulse/dope_team',
        element: <DopeTeam />
      },
      {
        path: '/pulse/pricing',
        element: <PulsePricing />
      },
      {
        path: '/pulse/payments_and_credits',
        element: <PaymentsAndCredits />
      },
      {
        path: '/pulse/design_templates',
        element: <PulseDesignGrid />
      },
      {
        path: '/pulse/design_revisions',
        element: <PulseDesignRevisions />
      },
      {
        path: '/pulse/reporting',
        element: <PulseReporting />,
        children: [
          {
            path: 'data_axle_usage',
            element: <DataAxleUsage />,
          },
          {
            path: 'delayed_jobs',
            element: <DelayedJobTable />,
          }
        ]
      },
      {
        path: '/pulse/design_revisions/:id',
        element: <Design />,
        children: [
          {
            path: 'revision',
            element: <DesignRevisionPage pulse={true} />
          },
        ]
      },
      {
        path: '/pulse/task_runner',
        element: <RakeTasks />,
      }
    ]
  },
  {
    path: '/admin',
    element: <ProtectedApp userType="admin" />,
    children: [
      {
        path: '/admin/accounts',
        element: <AccountManagement />
      },
      {
        path: '/admin/users',
        element: <UserManagement />
      },
      {
        path: '/admin/account_assignments',
        element: <AccountAssignmentManagement />
      },
      {
        path: '/admin/lists',
        element: <ListManagement />
      },
      {
        path: '/admin/list_generations',
        element: <ListGenerationManagement />
      },
      {
        path: '/admin/list_generation_settings',
        element: <ListGenerationSettingManagement />
      },
      {
        path: '/admin/data_axle_filters',
        element: <DataAxleFilterManagement />
      },
      {
        path: '/admin/contacts',
        element: <ContactManagement />
      },
      {
        path: '/admin/postcard_templates',
        element: <PostcardTemplateManagement />
      },
      {
        path: '/admin/campaigns',
        element: <CampaignManagement />
      },
      {
        path: '/admin/dispatches',
        element: <DispatchManagement />
      },
      {
        path: '/admin/mail_pieces',
        element: <MailPieceManagement />
      },
      {
        path: '/admin/campaign_templates',
        element: <CampaignTemplateManagement />
      },

    ]
  },
  {
    path: '/',
    element: <ProtectedApp userType="user" />,
    children: [
      {
        path: '/accounts/:id',
        element: <Account />,
        children: [
          {
            path: 'add_ons',
            element: <AddOnSelectPage />
          },
          {
            path: 'agency',
            element: <AccountAgencyTab />
          },
          {
            path: 'integrations',
            element: <AccountUserTokens />
          },
          {
            path: 'recent_activity',
            element: (
              <RecentActivity
                withHeader={true}
                withHeaderLink={false}
                withPagination={true}
                withFilters={{ user: true, type: true, order: true, expand: true }}
                headerText="All Recent Activity"
              />
            )
          },
          {
            path: 'billing',
            element: <AccountBilling />,
            children: [
              {
                path: 'invoices',
                element: <AccountPaymentsTab />
              },
              {
                path: 'credit_ledger',
                element: <AccountCreditLedger />
              },
              {
                index: true,
                path: 'automated_purchases',
                element: <AccountBatchPurchaseTable />
              },
              {
                path: "",
                element: <Navigate to="./automated_purchases" replace={true} />,
              }
            ]
          },
          {
            path: 'settings',
            element: <AccountSettingsTab/>
          },
          {
            path: 'subscriptions',
            element: <AccountSubscriptionsTab />
          },
          {
            path: 'user_access',
            element: <UserAccess />
          },
          {
            path: 'mailer_pricing',
            element: <ProductPricing />,
          }
        ]
      },
      {
        path: '/campaigns',
        element: <CampaignTable />
      },
      {
        path: '/campaigns/:id',
        element: <Campaign />,
        children: [
          {
            path: 'details',
            element: <CampaignSetup />
          },
          {
            path: 'audience',
            element: <CampaignAudience />
          },
          {
            path: 'mailers',
            element: <CampaignDispatchesPage />
          },
          {
            path: 'schedule',
            element: <CampaignReview />
          },
          {
            path: 'show',
            element: <CampaignShow />
          }
        ],
      },
      {
        path: '/automations',
        element: <AutomationsAndRuns />
      },
      {
        path: '/automations/:id',
        element: <Automation />,
        children: [
          {
            path: 'details',
            element: <AutomationSetup />
          },
          {
            path: 'trigger',
            element: <AutomationTrigger />
          },
          {
            path: 'mailers',
            element:  <AutomationCampaignTemplate />
          },
          {
            path: 'activate',
            element: <AutomationReview />
          },
          {
            path: 'show',
            element: <AutomationShow />
          }
        ],
      },
      {
        path: '/contacts/:id',
        element: <Contact />,
        children: [
          {
            path: 'details',
            element: <ContactDetailsPage />
          }
        ]
      },
      {
        path: '/lists_and_contacts',
        element: <ListsAndContactsPage/>,
      },
      {
        path: '/lists/:id',
        element: <List />,
        children: [
          {
            path: 'upload',
            element: <ListUploadContent />
          },
          {
            path: 'field_map',
            element: <CSVFieldMap />
          },
          {
            path: 'details',
            element: <ListDetailsPage />
          }
        ]
      },
      {
        path: '/overview',
        element: <Overview />
      },
      {
        path: '/mail_history',
        element: <MailHistory />
      },
      {
        path: '/conversion_reports',
        element: <AnalysisPage />,
      },
      {
        path: '/qr_codes',
        element: <AnalysisPage />,
      },
      {
        path: '/qr_codes/:id',
        element: <AnalysisPage />,
      },
      {
        path: '/qr_codes/:id/scans',
        element: <AnalysisPage />,
      },
      {
        path: '/qr_codes/:id/changelog',
        element: <AnalysisPage />,
      },
      {
        path: '/hot_leads',
        element: <AnalysisPage />,
      },
      {
        path: '/tracking_numbers',
        element: <AnalysisPage />,
      },
      {
        path: '/tracking_numbers/:id',
        element: <AnalysisPage />,
      },
      {
        path: '/tracking_numbers/:id/calls',
        element: <AnalysisPage />,
      },
      {
        path: '/conversion_reports/:id',
        element: <ConversionReport />,
        children: [
          {
            path: 'setup',
            element: <ConversionReportSetup />,
          },
          {
            path: 'show',
            element: <ConversionReportShow />,
          },
          {
            path: 'filters',
            element: <ConversionReportShow />,
          }
        ]
      },
      {
        path: '/designs/postcards',
        element: <Design />,
        children: [
          {
            path: ':id',
            element: <PostcardDesignForm />
          },
        ]
      },
      {
        path: '/designs/handwritten-fronts',
        element: <Design />,
        children: [
          {
            path: ':id',
            element: <HWCFrontForm />
          },
        ]
      },
      {
        path: '/designs/goodies',
        element: <Design />,
        children: [
          {
            path: ':id',
            element: <GoodieDesignForm />
          },
        ]
      },
      {
        path: '/designs',
        element: <DesignsPage />,
        children: [
          {
            path: ':id',
            element: <DesignsPage />
          }
        ]
      },
      {
        path: '/designs/:id',
        element: <Design />,
        children: [
          {
            path: 'revision',
            element: <DesignRevisionPage />
          },
        ]
      },
      {
        path: '/designs/eddms',
        element: <Design />,
        children: [
          {
            path: ':id',
            element: <PostcardDesignForm />
          },
        ]
      },
      {
        path: '/designs/handwritten-cards/:id',
        element: <Design />,
        children: [
          {
            path: 'setup',
            element: <HandwrittenCardSetup />,
          },
          {
            path: 'design',
            element: <HandwrittenCardDesignForm />,
          },
          {
            path: 'address',
            element: <HandwrittenCardExtras />,
          },
          {
            path: 'review',
            element: <HandwrittenCardReview />,
          }
        ]
      },
      {
        path: '/list_pulls/:id',
        element: <ListPull />,
        children: [
          {
            path: 'setup',
            element: <ListPullSetup />
          },
          {
            path: 'query',
            element: <ListPullQuery />
          }
        ]
      }
    ]
  },
  {
    path: '/',
    element: <ProtectedApp showNav={false} userType="user" />,
    children: [
      {
        path: '/account_signup',
        element: <AccountSignupForm />
      },
      {
        path: '/subscription_signup',
        element: <StripeCardAdderWrapper Children={SubscriptionForm} />
      },
      {
        path: '/auto-connect/:dataSource',
        element: <AutoConnect />
      },
    ]
  },
  {
    path: '/',
    element: <ProtectedApp showSideNav={false} userType="user" />,
    children: [
      {
        path: '/editor',
        element: <Design />,
        children: [
          {
            path: ':id',
            element: <DesignEditorPage />
          },
        ]
      },
    ]
  },
  {
    path: "*",
    element: <Dope404Page />
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
