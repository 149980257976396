import React from "react";
import { Drawer } from "rsuite";

const DopeDrawer = ({ open, onClose, header, bodyContent, children, className, subheader, ...rest }) => {
  return (
    <>
      <Drawer open={open} onClose={onClose} className={className} {...rest}>
        <Drawer.Body>
          {header && <h5>{header}</h5>}
          {subheader && <p><small>{subheader}</small></p>}
          {React.cloneElement(bodyContent || children, { onClose: onClose })  }

        </Drawer.Body>
      </Drawer>
    </>
  );
}

export default DopeDrawer;
