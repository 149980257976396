import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { orderBy } from "lodash";
import classNames from "classnames";

import { Modal, Grid, Row, Col, Divider } from "rsuite";
import { Icon } from "@rsuite/icons";
import { MdOutlineChevronRight } from "react-icons/md";

import DopeButton from "../ui/DopeButton";
import DopeTextInput from "../ui/DopeTextInput";
import DopeCheckbox from "../ui/DopeCheckbox";
import Cents from "../ui/Cents";

import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";

import "./PurchaseCredits.scss";

const accountsApi = new DopeApi("account");
const purchasesApi = new DopeApi("purchase");

const products = [
  { name: 'postcard', label: 'Postcards' },
  { name: 'handwritten_card', label: 'Handwritten Cards' },
  { name: 'goodies', label: 'Cookie Boxes' },
  { name: 'data', label: 'Data Credits' },
];

const PurchaseCredits = ({ onSuccess = () => {} }) => {
  const [productPricing, setProductPricing] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchasing, setPurchasing] = useState(false);
  const [purchaseError, setPurchaseError] = useState(null);
  const [purchase, setPurchase] = useState(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      setLoading(true);
      accountsApi.fetchMember(authApi.getCurrentAccountId(), 'product_pricing')
        .then(setProductPricing)
        .finally(() => setLoading(false));
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  const handleExited = () => {
    setQuantities({});
    setAgreedToTerms(false);
    setPurchaseError(null);
    setPurchase(null);
  };

  const handleQuantityChange = (change, value, name) => {
    let parsed = parseInt(value) || 0;
    if (parsed < 0) {
      parsed = 0;
    }
    setQuantities({ ...quantities, [name]: parsed });
  };

  const handlePurchase = () => {
    setPurchasing(true);
    setPurchaseError(null);
    setPurchase(null);

    const params = {
      quantities,
      user_id: authApi.getCurrentUserId(),
      account_id: authApi.getCurrentAccountId(),
    };

    purchasesApi.create(params)
      .then(purchase => {
        setPurchase(purchase);
        onSuccess(purchase);
        setQuantities({});
        setAgreedToTerms(false);
      })
      .catch(error => {
        console.log(error);
        setPurchaseError(error || { base: "Purchase Failed" });
      })
      .finally(() => setPurchasing(false));
  };

  let content;
  if (purchase) {
    content = (
      <div className="pad">
        <div className="header-3 margin-bottom">Purchase Successful!</div>
        <div className="margin-bottom">A charge of {purchase.total_price_str} has been applied to your card and your account has been credited. Thank you!</div>
      </div>
    );
  } else {
    const total = productPricing ? products.reduce((total, product) => {
      const pricing = productPricing[product.name];
      const quantity = quantities[product.name] || 0;

      const tiers = orderBy(pricing.tiers, 'min_quantity');

      let unitPrice = pricing.default_unit_price;
      tiers.forEach(tier => {
        if (quantity >= tier.minimum_quantity) {
          unitPrice = tier.unit_price;
        }
      });

      const subtotal = unitPrice * quantity;

      return total + subtotal;
    }, 0) : 0;

    const disabled = total <= 50 || !agreedToTerms || purchasing;

    const wrapperClasses = classNames({
      'pad': true,
      'purchase-credits': true,
      'disabled': purchasing || loading,
    });

    content = (
      <div className={wrapperClasses}>
        <div className="header-3 margin-bottom">What type of credits do you want to buy?</div>

        <Grid fluid className="purchase-credits-form">
          <Row className="margin-16-b">
            <Col xs={8} className="label text-upper">Type</Col>
            <Col xs={6} className="label text-upper">Price</Col>
            <Col xs={6} className="label text-upper">Quantity</Col>
            <Col xs={4} className="label text-upper text-right">Subtotal</Col>
          </Row>
          {
            products.map((product, index) => {
              if (!productPricing) {
                return (
                  <Row key={product.name} className="margin-bottom">
                    <Col xs={8}>{product.label}</Col>
                    <Col xs={6}>...</Col>
                    <Col xs={6}>...</Col>
                    <Col xs={4}>...</Col>
                  </Row>
                );
              }

              const pricing = productPricing[product.name];
              const quantity = quantities[product.name] || 0;

              const tiers = orderBy(pricing.tiers, 'min_quantity');

              let unitPrice = pricing.default_unit_price;
              tiers.forEach(tier => {
                if (quantity >= tier.minimum_quantity) {
                  unitPrice = tier.unit_price;
                }
              });

              const subtotal = unitPrice * quantity;

              const basePriceDisplay = (tiers.length === 0 || tiers[0].minimum_quantity > 1) && (
                <div className={classNames({ 'product-pricing-tier': true, 'active-tier': unitPrice === pricing.default_unit_price && tiers.length })}>
                  <Cents>{pricing.default_unit_price}</Cents>
                </div>
              );

              const tierPriceDisplay = tiers.map((tier, index) => {
                return [
                  !!index && <br key={tier.minimum_quantity + "br"} />,
                  (
                    <span key={tier.minimum_quantity} className={classNames({ 'product-pricing-tier': true, 'active-tier': unitPrice === tier.unit_price })}>
                      <Cents>{tier.unit_price}</Cents>
                    </span>
                  ),
                  tier.minimum_quantity > 1 && <span key={tier.minimum_quantity + "+"}> ({tier.minimum_quantity}+)</span>
                ];
              });

              const priceDisplay = <>
                {basePriceDisplay}
                {tierPriceDisplay}
              </>;

              return (
                <Row key={product.name} className="margin-bottom">
                  <Col xs={8}><b>{product.label}</b></Col>
                  <Col xs={6}>{priceDisplay}</Col>
                  <Col xs={6}>
                    <DopeTextInput
                      name={product.name}
                      value={quantity}
                      onChange={handleQuantityChange}
                      type="number"
                      placeholder="0"
                      min={0}
                    />
                  </Col>
                  <Col className="text-right" xs={4}><Cents>{subtotal}</Cents></Col>
                </Row>
              );
            })
          }
        </Grid>

        <Divider />

        <Row>
          <Col xs={14} />
          <Col xs={6}><b>Total</b></Col>
          <Col className="text-right" xs={4}><b><Cents>{total}</Cents></b></Col>
        </Row>

        <div className="margin-tb to-right">
          <DopeCheckbox
            headerText="I agree to bill this card for the total amount above"
            value={agreedToTerms}
            onChange={(update, value) => setAgreedToTerms(value)}
          />
        </div>

        <div className="to-right">
          <DopeButton className="filled" loading={purchasing} disabled={disabled} onClick={handlePurchase}>Buy Credits</DopeButton>
        </div>

        <div className="error">
          {purchaseError && purchaseError.base}
        </div>

      </div>
    );
  }

  return (
    <>
      <div className="to-center">
        <DopeButton className="filled" onClick={() => setShow(true)} props={{styles: {width: "90%"}}}>Buy More Credits</DopeButton>
      </div>

      {/*<div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "16px" }}>*/}
      {/*  <DopeButton*/}
      {/*    props={{*/}
      {/*      buttonClass: "text-link",*/}
      {/*      label: <div>View my Credit Ledger<Icon as={MdOutlineChevronRight} /></div>,*/}
      {/*      onClick: () => navigate(`/accounts/${authApi.getCurrentAccountId()}/billing/credit_ledger`)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      <Modal
        open={show}
        onClose={handleClose}
        onExited={handleExited}
      >
        <Modal.Header></Modal.Header>
        {content}
      </Modal>
    </>
  );
};

export default PurchaseCredits;
