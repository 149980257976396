import React from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeContentPage from "../ui/DopeContentPage";
import {handleFilterChange} from "../utils/fn";

const columns = [
  { type: 'stacked_text', dataKey: ['name', 'account.name'], label: 'Name', sortable: true },
  { type: 'text', dataKey: 'automation_runs_count', label: 'Sendings', sortable: true },
  { type: 'event_time', dataKey: 'last_run_at', label: 'Last Sending', sortable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Created Date', sortable: true },
  { type: 'pill', pillTypeKey: 'effective_status', dataKey: 'effective_status', label: 'Status', sortable: false },
];

const api = new DopeApi('automation');

const PulseAutomationTable = () => {
  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setFilters,
    filters,
  } = useQuery2({
    api,
    initialSearchScope: 'name'
  });

  const applyStatusFilter = (status) => {
    const filterObject = {
      filterField: 'mode',
      operator: 'in',
      filterCategory: 'Mode'
    }
    handleFilterChange(filters, setFilters, status, filterObject)
  }

  const statusOptions = [{ value: "manual", label: "Validation Mode" }, { value: "automatic", label: "Automatic" }];


  const filterInputs = [
    { title: "Automation Type", getOptions: () => statusOptions, applyFilter: applyStatusFilter }
  ];

  return (
    <DopeContentPage
      pageTitle="All Automations"
      pageContent={<DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filters={filters}
      />}
    />
  );
}

export default PulseAutomationTable;
