import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  drawerContactId: null,
};

export const contactUISlice = createSlice({
  name: "contactUI",
  initialState,
  reducers: {
    updateContactUI: (contactUI, { payload: update }) => {
      Object.assign(contactUI, update);
    },
    setDrawerContactId: (contactUI, { payload: id }) => {
      contactUI.drawerContactId = id;
    }
  }
});

export default contactUISlice.reducer;

export const {
  updateContactUI,
  setDrawerContactId,
} = contactUISlice.actions;

export const selectContactUI = (state) => state.contactUI;

export function useContactUI() {
  const dispatch = useDispatch();
  const contactUI = useSelector(selectContactUI);
  const actions = {
    update: (payload) => dispatch(updateContactUI(payload)),
    setDrawerContactId: (id) => dispatch(setDrawerContactId(id)),
  };

  return [contactUI, actions];
}
