import React from "react";

import SubscriptionSelector from "../subscriptions/SubscriptionSelector";
import DopeTabs from "../ui/DopeTabs";
import ProductAccountPricing from "../purchasing/ProductAccountPricing";
import { useAccount } from "./accountSlice";
import {Message} from "rsuite";
import {formatToLocaleDateString, unixTimestampToDateStr} from "../utils/date";

const AccountSubscriptionsTab = () => {
  const [account, actions] = useAccount();

  return (
    <>
      <div style={{ fontSize: "25px", fontWeight: 600, color: "#292B2E", marginBottom: "16px"}}>My Subscriptions</div>

      <DopeTabs>
        <DopeTabs.Tab
          key="plans"
          name="plans"
          label="My Plans"
          disabled={false}
        >
          <h5 style={{margin: "20px 0 10px", color: "#000000"}}>Active Plans</h5>
          { account?.next_stripe_plan && (
            <div className="margin-4-b margin-4-t">
              <Message type="info" showIcon>
                Your plan is scheduled to change to <strong>{account.next_stripe_plan.toUpperCase()}</strong> on { formatToLocaleDateString(unixTimestampToDateStr(account.stripe_subscription.current_period_end))}.
              </Message>
            </div>
          )}
          <SubscriptionSelector selectable={false} />
        </DopeTabs.Tab>

        <DopeTabs.Tab
          key="pricing"
          name="pricing"
          label="Current Pricing"
          disabled={false}
        >
          <ProductAccountPricing />
        </DopeTabs.Tab>
      </DopeTabs>
    </>
  );
}


export default AccountSubscriptionsTab;
