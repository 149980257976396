import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import PublicOpinionIcon from "@rsuite/icons/PublicOpinion";
import TrashIcon from "@rsuite/icons/Trash";
import FileDownloadIcon from '@rsuite/icons/FileDownload';

import { useList } from "./listSlice";
import { useListUI } from "./listUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeApi from "../services/DopeApi";
import {DopeFormGroup} from "../ui/DopeForm";

const contactListApi = new DopeApi('contact_lists');

const itemStyles = {
  cursor: "pointer",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#292B2E",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
}

const iconStyles = {
  margin: "-2px 7px 0 0",
  color: "#8793A6",
  fontSize: "18px",
}

const ListDetailsDropdown = ({ filters, setLoading }) => {
  const [list, listActions] = useList();
  const [listUI, listUIActions] = useListUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const navigate = useNavigate();

  const handleEditList = () => {
    listUIActions.setDrawerListId(list.id);
  }

  const handleDeleteList = async () => {
    try {
      await listActions.destroy(list.id);
      dopeUIActions.addFlashMessage({ header: list.name, body: "deleted successfully", type: "success" });
      navigate("/lists_and_contacts");
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: list.name, body: "could not be deleted", type: "error" });
    }
  }

  const handleFavoriteList = async () => {
    listActions.save({ favorite: !list.favorite });
  }

  const handleDownloadList = async () => {
    setLoading(true)
    try {
      await contactListApi.exportCSV({ filters }, list.name);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false)
    }
  }

  const handleAddToList = () => {
    navigate(`/lists/${list.id}/upload`, { state: { listType: 'existing', listId: list.id } });
  }

  const handleSaveTags = () => {
    try {
      listActions.save()
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditListPull = () => {
    navigate(`/list_pulls/${list.list_pull_id}/setup`);
  };

  const isFromListPull = !!list.list_pull_id;
  const listPullItem = isFromListPull ? <Dropdown.Item style={itemStyles} onSelect={handleEditListPull}><EditIcon style={iconStyles}/>Edit List Pull</Dropdown.Item> : null;

  return (
    <div style={{marginTop: "5px"}}>
      <Dropdown.Item style={itemStyles} onSelect={handleEditList}><EditIcon style={iconStyles}/>Edit List</Dropdown.Item>
      {listPullItem}
      <Dropdown.Item style={itemStyles} onSelect={handleDownloadList}><FileDownloadIcon style={iconStyles}/>Download List</Dropdown.Item>
      <Dropdown.Item style={itemStyles} onSelect={handleAddToList}><PlusRoundIcon style={iconStyles}/>Add to List</Dropdown.Item>
      {/*<Dropdown.Item style={itemStyles} onSelect={handleFavoriteList}><PublicOpinionIcon style={iconStyles}/>Add to*/}
      {/*  Favorites</Dropdown.Item>*/}
      {/*<Dropdown.Item style={itemStyles} onSelect={handleDeleteList}><TrashIcon style={iconStyles} />Delete List</Dropdown.Item>*/}

      <hr style={{height: "1px", color: "#EAECEF", margin: "16px 20px"}}/>

      <div style={{display: "flex", flexDirection: "column", padding: "0 20px"}}>
        <div className="label bold margin-4-b">Custom Tags</div>
        <DopeFormGroup
          input={{
            inputType: "tags",
            name: "taggings",
            props: {modelName: "list", hideEmptyState: true}
          }}
          values={list}
          onChange={listActions.addTagToList}
        />
      </div>
    </div>
  )
}

export default ListDetailsDropdown;
