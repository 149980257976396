import React, { useState } from "react";
import { Link } from "react-router-dom";
import RecentActivityItem from "./RecentActivityItem";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import SearchIcon from "@rsuite/icons/Search";
import { Input, InputGroup, Pagination } from "rsuite";

import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";
import { useQuery2 } from "../hooks/useQuery";
import BelongsToSelect from "../ui/BelongsToSelect";
import DopeSelect from "../ui/DopeSelect";
import DopeCheckbox from "../ui/DopeCheckbox";
import DopeLoader from "../ui/DopeLoader";

import "./Overview.scss";

const api = new DopeApi("activity_history");

const headerStyles = { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "20px", alignItems: "center" };
const linkStyles = { display: "flex", alignItems: "center", height: "fit-content", color: "#255FDF", fontSize: "14px", fontWeight: 600, cursor: "pointer" };
const resourceTypeOptions = [
  { value: 'Account', label: 'Account' },
  { value: 'Automation', label: 'Automation' },
  { value: 'Campaign', label: 'Campaign' },
  { value: 'Contact', label: 'Contact' },
  { value: 'User', label: 'User' },
];
const sortOptions = [
  { value: 'desc', label: 'Newest First' },
  { value: 'asc', label: 'Oldest First' },
];
const withDataLoaderStyles = { position: "absolute", top: 100 };

const DopeSearchInput = ({ onChange, value }) => (
  <InputGroup style={{ width: "300px" }}>
    <Input onChange={onChange} value={value} />
    <InputGroup.Addon>
      <SearchIcon/>
    </InputGroup.Addon>
  </InputGroup>
);

const paginationLocale = {
  total: 'Total: {0}'
};

const DopePagination = (props) => (
  <Pagination
    prev
    next
    first
    last
    ellipsis
    boundaryLinks
    maxButtons={5}
    size="xs"
    layout={['total', '-', 'limit', '|', 'pager', 'skip']}
    limitOptions={[10, 25, 50, 100]}
    locale={paginationLocale}
    {...props}
  />
);
const RecentActivity = ({
  withHeader = true,
  withHeaderLink = true,
  withPagination = false,
  withDetails = false,
  withFilters = { user: false, type: false, order: false, expand: false },
  initialPerPage = 25,
  initialFilters = [],
  headerText = "Recent Activity",
  style={},
}) => {

  const [showDetails, setShowDetails] = useState(withDetails);

  const {
    data,
    loading,
    filters,
    setFilters,
    sortDirection,
    setSortDirection,
    paginationProps
  } = useQuery2({
    api,
    initialPerPage,
    initialFilters,
  });

  const header = withHeader && (
    <div style={headerStyles}>
      <h4 className="header-3">{headerText}</h4>
      {withHeaderLink && (
        <Link
          to={`/accounts/${authApi.getCurrentAccountId()}/recent_activity`}
          style={linkStyles}
        >
          View All <ArrowRightLineIcon />
        </Link>
      )}
    </div>
  );

  const handleFilterChange = (filter) => {
    let nextFilters = filters.filter(f => f.field !== filter.field);
    if (filter.value) {
      nextFilters.push(filter);
    }
    setFilters(nextFilters);
  };

  const userFilter = filters.find(f => f.field === 'creator_id') || { value: null, operator: '=', field: 'creator_id' };
  const typeFilter = filters.find(f => f.field === 'historyable_type') || { value: null, operator: '=', field: 'historyable_type' };

  const someFilters = Object.values(withFilters).some(Boolean);

  const filterBar = someFilters && (
    <div className="margin-tb pad-b flex gap-10 border-bottom">
      {withFilters.user && (
        <div className="max-width-300">
          <BelongsToSelect
            name="value"
            label="User"
            placeholder="Filter By User"
            value={userFilter.value}
            onChange={update => handleFilterChange({ ...userFilter, ...update })}
            modelName="user"
            labelKey="full_name"
            cleanable={true}
            size="xs"
          />
        </div>
      )}
      {withFilters.type && (
        <div className="max-width-300">
          <DopeSelect
            name="value"
            label="Type"
            placeholder="Filter By Resource Type"
            options={resourceTypeOptions}
            value={typeFilter.value}
            onChange={update => handleFilterChange({ ...typeFilter, ...update })}
            searchable={false}
            cleanable={true}
            size="xs"
          />
        </div>
      )}
      {withFilters.order && (
        <div className="max-width-300">
          <DopeSelect
            name="value"
            label="Order"
            options={sortOptions}
            value={sortDirection}
            onChange={(update, item, value) => setSortDirection(value)}
            searchable={false}
            size="xs"
          />
        </div>
      )}
      {withFilters.expand && (
        <div className="margin-left-auto">
          <DopeCheckbox
            helpText="Show Details"
            value={showDetails}
            onChange={(update, value) => setShowDetails(value)}
            size="sm"
            inline
            useToggle
          />
        </div>
      )}
    </div>
  );

  const pagination = withPagination && (
    <div className="margin-tb pad-t border-top"><DopePagination {...paginationProps} /></div>
  );

  return (
    <div className="overview overview-section" style={style}>
      {header}
      {filterBar}
      <div className={`${loading ? "disabled" : ""} pos-relative`}>
        {data.map((activity, index) => {
          return (
            <RecentActivityItem key={index} activity={activity} withDetails={showDetails} />
          );
        })}
        {loading && (<DopeLoader style={data.length ? withDataLoaderStyles : {}} center />)}
      </div>
      {pagination}
    </div>
  );
}

export default RecentActivity;
