import React, { createRef } from "react";

import { MdOutlineFileDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import HistoryIcon from '@rsuite/icons/History';

import QRCode from "easyqrcodejs";

import { downloadFile } from "../utils/file";

const apiBase = process.env.REACT_APP_API_BASE_URL;

const styles = {
  cursor: "pointer",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#292B2E",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
};

const iconStyles = {
  marginRight: "7px",
  color: "#8793A6",
  fontSize: "18px",
};

const QRCodeDropdown = ({ rowData: currentQRCode, onClose }) => {
  let { id, code_url, name } = currentQRCode;

  const code = createRef();

  const downloadPNG = () => {
    downloadFile(code_url, `${name}.png`);
  };

  const downloadSVG = () => {
    const qrCode = new QRCode(code.current, {
      text: `${apiBase}/qr_codes/${id}/scan`,
      width: 300,
      height: 300,
      colorDark :'"#000000',
      colorLight : '#ffffff',
      correctLevel: QRCode.CorrectLevel.H,
      drawer: 'svg',
      onRenderingEnd: () => {
        qrCode.download(name);
      }
    });
  };

  return (
    <>
      <div>
        <Link to={`/qr_codes/${id}/scans`} style={styles}><SlGraph style={iconStyles} />See Scan Data</Link>
        <Link to={`/qr_codes/${id}`} style={styles}><FaEdit style={iconStyles} />Edit QR Code</Link>
        <p onClick={downloadPNG} style={styles}><MdOutlineFileDownload style={iconStyles} />Download PNG</p>
        <p onClick={downloadSVG} style={styles}><MdOutlineFileDownload style={iconStyles} />Download SVG</p>
        <Link to={`/qr_codes/${id}/changelog`} style={styles}><HistoryIcon style={iconStyles} />See Change Log</Link>
        <div ref={code} style={{ display: 'none' }}></div>
      </div>
    </>
  )
}

export default QRCodeDropdown;
