import { useDispatch, useSelector } from "react-redux";
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import DopeApi from '../services/DopeApi';

const api = new DopeApi('conversion_report');

const [conversionReportSlice, asyncActions] = createModelSlice('conversion_report');

export default conversionReportSlice.reducer;

export function useConversionReport() {
  const dispatch = useDispatch();
  const conversionReport = useSelector(state => state.conversionReport.current);
  const isSaving = useSelector(state => state.conversionReport.isSaving);

  const actions = wrapAll({
    ...conversionReportSlice.actions,
    ...asyncActions,
  }, dispatch);

  const save = actions.save;

  actions.save = () => {
    return save({
      ...conversionReport,
      filters: JSON.stringify(conversionReport.filters),
    });
  };

  return {
    conversionReport,
    isSaving,
    actions,
  };
}
