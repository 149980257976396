import React, { useEffect } from "react";

import DopeApi from "../services/DopeApi";
import { handleFilterChange } from "../utils/fn";
import DopeListPage from "../ui/DopeListPage";
import {useQuery2} from "../hooks/useQuery";

const dateStyles = {
  fontSize: '11px',
  fontWeight: 500,
  color: '#292B2E'
}

const contactApi = new DopeApi('contact');
const contactListApi = new DopeApi('contact_lists');
const taggingsApi = new DopeApi('taggings');

const getUrl = (rowData) => `/contacts/${rowData.contact_id}/details`;
const setFavorite = async (rowData) => {
  await contactApi.update({id: rowData.contact_id, favorite: !rowData.contact.favorite});
}

const ContactListTable = ({ listFilter, setListFilters }) => {
  const { paginationProps, tableProps, filters, setFilters, onSearchClear, onSearchInput, reload } =
    useQuery2({
      api: contactListApi,
      initialSearchScope: "contacts.full_name_and_address",
      initialFilters: [listFilter],
    });

  useEffect(() => {
    setListFilters(filters);
  }, [filters]);

  const columns = [
    { type: 'stacked_text', dataKey: ['contact.full_name', 'contact.company_name'], label: 'Name', flexGrow: 2, customStyles: { fontSize: '14px', fontWeight: 400, color: '#292B2E' }, getUrl, sortable: true },
    { type: 'address_text', dataKey: 'contact.full_address', label: 'Address', flexGrow: 2, sortable: true },
    { type: 'date', dataKey: 'created_at', label: 'Date Added', flexGrow: 2, customStyles: dateStyles, sortable: true },
    { type: 'date', dataKey: 'contact.most_recent_shipped_mail_piece_date', label: 'Last Mailing', flexGrow: 2, customStyles: dateStyles },
    { type: 'dropdown', dataKey: 'actions', dropDownType: 'contact_list', width: 75, reloadTable: reload }
  ];

  const contactDesignations = [{label: "Clients", value: "contacts.is_client"}, {label: "Leads", value: "contacts.is_lead"}, {label: "Prospects", value: "contacts.is_prospect"}]
  const applyContactTypeFilters = (contactTypes, optionValue, checked) => {
    if (optionValue === "contacts.is_prospect") {
      applyProspectFilters(checked);
    } else {
      const filterObject = {
        filterField: optionValue ,
        operator: "=",
        filterCategory: optionValue
      }

      handleFilterChange(filters, setFilters, checked, filterObject);
    }
  }

  const applyProspectFilters = (checked) => {
    let filterObjects = [];
    ["contacts.is_client", "contacts.is_lead"].forEach((designation) => {
      const filterObject = {
        filterField: designation,
        operator: "!=",
        filterCategory: "contacts.is_prospect",
        allowMultiples: true
      }

      filterObjects.push(handleFilterChange(filters, setFilters, checked, filterObject));
    });

    if (filterObjects.filter(object => object !== undefined).length > 0) {
      setFilters([...filters, ...filterObjects])
    }
  }

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "contact.taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const filterInputs = [
    { title: 'Tags', getOptions: () => taggingsApi.getOptions({valueKey: 'tag_id', labelKey: 'tag.name', filters: [{ field: "taggable_type", operator: "=", value: "Contact" }]}), applyFilter: applyTagFilters },
    { title: 'Contact Type', getOptions: () => contactDesignations, applyFilter: applyContactTypeFilters }
  ];

  return (
    <>
      <DopeListPage
        columns={columns}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filterInputs={filterInputs}
        filters={filters}
        paginationProps={paginationProps}
        tableProps={tableProps}
      />
    </>
  );
};

export default ContactListTable;
