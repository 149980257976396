import React, { useState } from "react";

import { useAutomation } from "./automationSlice";

import { Grid, Row, Col } from 'rsuite';
import { IconButton } from "rsuite";
import CloseIcon from '@rsuite/icons/Close';
import DopeButton from "../ui/DopeButton";
import CampaignMailerSelect from "../campaigns/CampaignMailerSelect";
import { DispatchMailer } from "../campaigns/CampaignDispatchList";
import DopeTextInput from "../ui/DopeTextInput";
import HotLeadDrawer from "../campaigns/HotLeadDrawer";

const AutomationDispatchTemplates = ({ editable = true, withHeader = false, withContactCol = false }) => {
  const { automation, actions } = useAutomation();
  const [mailersDrawerOpen, setMailersDrawerOpen] = useState(false);
  const [hotLeadDrawerOpen, setHotLeadDrawerOpen] = useState(false);
  const [dispatchTemplate, setDispatchTemplate] = useState(null);

  const dispatchTemplates = automation.campaign_template.dispatch_templates;
  const hasDispatchTemplates = dispatchTemplates.length > 0;
  const settings = automation.campaign_template.list_generation_setting;
  const audienceSize = settings.generation_type === 'blitz' ? settings.max_contacts_per_generation : 1;

  const handleMailerSelect = (mailer) => {
    setMailersDrawerOpen(false);

    const maxDaysOffset = dispatchTemplates.reduce((max, dispatchTemplate) => {
      return Math.max(max, dispatchTemplate.days_offset);
    }, 0);

    const dispatch_template = {
      days_offset: maxDaysOffset + 1,
      hot_lead_url: null,
      hot_lead_phone: null,
      ...mailer,
    };

    actions.addDispatchTemplate(dispatch_template);
  };

  const addMailerPanel = editable && (
    <>
      <div className="page-panel text-center">
        {!hasDispatchTemplates && (<p className="pad">Add the first mailing in your Automated Campaign here!</p>)}
        <DopeButton className="outlined-black" onClick={() => setMailersDrawerOpen(true)}>Add Mailers</DopeButton>
      </div>
    </>
  );

  const mailerSpan = withContactCol = false ? 14 : 19;

  const tableHeader = withHeader && (
    <Row className="header">
      <Col xs={mailerSpan}>Mailer</Col>
      {withContactCol = false && (<Col xs={5}>Contacts</Col>)}
      <Col xs={5}>Send Date</Col>
    </Row>
  );

  const dispatchRows = dispatchTemplates.map((dispatchTemplate, index) => {
    return (
      <Row key={index}>
        <Col xs={mailerSpan}>
          <DispatchMailer
            dispatch={dispatchTemplate}
            openDrawer={(index) => {
              setDispatchTemplate(index);
              setHotLeadDrawerOpen(true);
            }}
            index={index}
            actions={actions}
            dispatches={dispatchTemplates}
            editable={editable}
          />
        </Col>
        {withContactCol = false && (<Col xs={5}>{audienceSize}</Col>)}
        <Col xs={5}>
          <div className="vertical-align gap-10">
            <span><b>Day</b></span>
            <DopeTextInput
              type="number"
              name="days_offset"
              value={dispatchTemplate.days_offset}
              onChange={update => actions.updateDispatchTemplate({ index, update })}
              readOnly={!editable}
            />
            {editable && (<IconButton
              onClick={() => actions.removeDispatchTemplate(index)}
              appearance="subtle"
              icon={<CloseIcon />}
            />)}
          </div>
        </Col>
      </Row>
    );
  });

  return (
    <>
      <Grid fluid className="dispatch-list">
        {tableHeader}
        {dispatchRows}
      </Grid>

      {addMailerPanel}

      <CampaignMailerSelect
        onSelect={handleMailerSelect}
        onClose={() => setMailersDrawerOpen(false)}
        open={mailersDrawerOpen}
      />

      <HotLeadDrawer
        open={hotLeadDrawerOpen}
        onClose={() => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        onSave={() => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        index={dispatchTemplate}
        dispatches={dispatchTemplates}
        actions={actions}
      />
    </>
  )
};

export default AutomationDispatchTemplates;
