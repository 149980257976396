import React from "react";
import { useOutletContext } from "react-router-dom";

import GeoFilterInput from "./GeoFilterInput";
import ExtraMailRules from "./ExtraMailRules";

const CampaignAudience = () => {
  const { pageTabs } = useOutletContext();

  return (
    <>
      <div className="page">
        {pageTabs}
        <div className="page-header">
          <p className="header-text">Now let’s target your ideal audience</p>
          <p className="help-text">Hyper-target your ideal customer using our interactive Blitz tool by entering a starting address.</p>
        </div>

        <GeoFilterInput />
      </div>

      <div className="page">
        <div className="page-header">
          <p className="header-text">Let's refine your audience</p>
          <p className="help-text">Make sure your mail is getting in the right hands by adding and removing people.</p>
        </div>

        <ExtraMailRules />
      </div>
    </>
  );
};

export default CampaignAudience;
